import { conversationCache } from '../../../Library/Cache/conversationCache';
import { conversationListCache } from '../../../Library/Cache/conversationListCache';
import { visitorListCache } from '../../../Library/Cache/visitorListCache';
import { EVENTS_VISITOR } from '../Library/visitorEvents';

  const registerVisitorHandlers = (socket) => {

    /**
     * Visitor connected
     * @param {Object} data - websocket data 
     */
    const connected = (data) => {      
      
      //console.log(data);
      visitorListCache.addItem({...data.visitorData,conversation:data.conversationData});
      conversationListCache.editItemByVisitorID(data.visitorData.visitorID,{visitor:data.visitorData});

      const conversationVisitor      = conversationCache.getVisitor();
      if(conversationVisitor.visitorID == data.visitorData.visitorID){
        conversationCache.setVisitor(data.visitorData);
      }
    }

    /**
     * Visitor disconnected
     * @param {Object} data - websocket data 
     */
    const disconnected = (data) => {
      
      visitorListCache.removeItemByVisitorID(data.visitorData.visitorID);
      conversationListCache.editItemByVisitorID(data.visitorData.visitorID,{visitor:data.visitorData});

      const conversationVisitor      = conversationCache.getVisitor();
      if(conversationVisitor.visitorID == data.visitorData.visitorID){
        conversationCache.setVisitor(data.visitorData);
      }
    }

    /**
     * Visitor's conversation activated
     * @param {Object} data - websocket data 
     */
    const visitorPageAdded = (data) => {      
      const visitorData = conversationCache.getVisitor();
      if(visitorData.visitorID == data.visitorID){
        conversationCache.addVisitedPage(data);
      }
    } 

    /**
     * Visitor typing start/stop
     * @param {Object} data - websocket data 
     */
    const typing = (data) => {   
      const conversationData = conversationCache.get();
      if(conversationData.conversationID == data.conversationID){
        conversationCache.setVisitorTyping(data.isTyping);
      }
      conversationListCache.editItem(data.conversationID,{visitorTyping:data.isTyping});
      
    } 
  
    /**
     * Visitor data edited
     * @param {Object} data - websocket data 
     */
     const edited = (data) => {   
      const conversationData = conversationCache.get();
      if(conversationData.visitor.visitorID == data.visitorData.visitorID){
        conversationCache.setVisitor(data.visitorData);
      }
      conversationListCache.editVisitorData(data.visitorData.visitorID,data.visitorData);
      visitorListCache.editItem(data.visitorData.visitorID,data.visitorData);
    } 
    /**
     * Join websocket events with functions 
     */
    socket.on(EVENTS_VISITOR.CONNECTED,connected);
    socket.on(EVENTS_VISITOR.DISCONNECTED,disconnected);
    socket.on(EVENTS_VISITOR.VISITED_PAGE_ADDED,visitorPageAdded);
    socket.on(EVENTS_VISITOR.TYPING,typing);
    socket.on(EVENTS_VISITOR.EDIT,edited);
    

  }


export default registerVisitorHandlers;