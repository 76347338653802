/**
 *  komponenta pro štítky pro produkty
 */
import React from "react";
import { checkAdminRights, useAdminRights } from "../../../Library/helper";
import translate from "../../Translations/index";
import { operatorCache, operatorVar } from "../../../Library/Cache/operatorCache";
import { NavLink, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Account from "./Account/Account";
import MyProfile from "./MyProfile";
import Invoicing from "./Invoicing";
import Notification from "./Notification";
import ChatboxList from "./Chatbox/ChatboxList";
import ChatboxDetail from "./Chatbox/ChatboxDetail";
import Loading from "../../../GlobalComponents/Loading";
import Automatization from "./Automatization/Automatization";

const SettingsPage = (props) => {
    //zjistime jestli ma prihlaseny administrator spravna prava

    let { path, url } = useRouteMatch();
    const operatorData = operatorCache.get();
    return (
        <div className="settings-page">
            <div className="menu">
                {["1", "2"].includes(operatorData.roleID) && (
                    <NavLink to={`${url}/account`} activeClassName="selected" className="menu-item">
                        {translate.t("Account")}
                    </NavLink>
                )}
                {["1", "2", "3"].includes(operatorData.roleID) && (
                    <NavLink to={`${url}/my-profile`} activeClassName="selected" className="menu-item">
                        {translate.t("MyProfile")}
                    </NavLink>
                )}
                {["1"].includes(operatorData.roleID) && (
                    <NavLink to={`${url}/invoicing`} activeClassName="selected" className="menu-item">
                        {translate.t("Invoicing")}
                    </NavLink>
                )}
                {["1", "2"].includes(operatorData.roleID) && (
                    <NavLink to={`${url}/chatbox`} activeClassName="selected" className="menu-item">
                        {translate.t("Chatbox")}
                    </NavLink>
                )}
                {["1", "2"].includes(operatorData.roleID) && (
                    <NavLink to={`${url}/notification`} activeClassName="selected" className="menu-item">
                        {translate.t("Notification")}
                    </NavLink>
                )}

                {["1", "2", "3"].includes(operatorData.roleID) && (
                    <NavLink to={`${url}/automatization`} activeClassName="selected" className="menu-item">
                        {translate.t("Automatization")}
                    </NavLink>
                )}
            </div>

            <Switch>
                <Route path={`${url}/account`} component={Account}></Route>
                <Route path={`${url}/my-profile`} component={MyProfile}></Route>
                <Route path={`${url}/invoicing`} component={Invoicing}></Route>
                <Route exact path={`${url}/chatbox`} component={ChatboxList}></Route>
                <Route path={`${url}/chatbox/:chatboxID`} component={ChatboxDetail}></Route>
                <Route path={`${url}/notification`} component={Notification}></Route>
                <Route path={`${url}/automatization`} component={Automatization}></Route>

                <Route>
                    <Redirect to={`${url}/my-profile`} />
                </Route>
            </Switch>
        </div>
    );
};

export default SettingsPage;
