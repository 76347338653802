import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useParams, useHistory } from "react-router-dom";
import DashboardLayout from "../../../Layout/DashboardLayout";
import { OPERATOR_ACCOUNT_DATA, OPERATOR_CONTEXT, OPERATOR_LAST_SELECTED_ACCOUNT } from "../../../GlobalQueries/globalQueries";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { operatorCache } from "../../../Library/Cache/operatorCache";
import { bootstrapDashboard } from "../../../Library/dashboard";
import { getApolloClient } from "../../../Library/apolloClient";

const Account = (props) => {
    const client = useApolloClient();
    const history = useHistory();
    let { accountID } = useParams();
    const [loading, setLoading] = useState(true);

    const [getLastSelectedAccount] = useLazyQuery(OPERATOR_LAST_SELECTED_ACCOUNT, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            if (!!data.operatorLastSelectedAccount?.accountID) {
                history.push("/account/" + data.operatorLastSelectedAccount?.accountID);
            }
        },
    });
    const [getOperatorAccountData] = useLazyQuery(OPERATOR_ACCOUNT_DATA, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            async function doAsync() {
                if (!!data.operatorAccount?.accountID) {
                    operatorCache.set({ accountID: data.operatorAccount.accountID, roleID: data.operatorAccount.roleID, shortcuts: data.shortcuts });
                    await bootstrapDashboard();
                    setLoading(false);
                } else {
                    history.push("/account");
                }
            }
            doAsync();
        },
    });

    useEffect(() => {
        const oData = client.readQuery({ query: OPERATOR_CONTEXT });
        console.log(oData);
        if (!!oData.operatorContext) {
            if (!accountID) {
                getLastSelectedAccount({ variables: { operatorID: operatorCache.get().operatorID } });
            } else {
                getOperatorAccountData({ variables: { operatorID: operatorCache.get().operatorID, accountID } });
            }
        } else {
            history.push("/login");
        }
    }, [accountID]);

    return <>{!loading && <DashboardLayout />}</>;
};

export default Account;
