/*
    Formulář pro přihlášení se do adminu
*/
import React, { useState, useEffect } from "react";
import { RESET_PASSWORD } from "../Queries/changePassword";
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation, useQuery } from "@apollo/client";
import { addNotification, goToInitialPage } from "../../../Library/helper";
import translate from "../../Translations/index";
import { useApolloClient } from "@apollo/client";
import loadingIcon from "../../../Public/Images/loading_blue.svg";
import { Link } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import { operatorVar } from "../../../Library/Cache/operatorCache";
import { ReactComponent as Logo } from "../../../Public/Images/logo.svg";

const ResetPassword = (props) => {
    const client = useApolloClient();

    const operatorData = operatorVar();

    const [email, setEmail] = useState("");

    useEffect(() => {
        if (operatorData && operatorData.operatorID) {
            goToInitialPage(props.history, translate.locale);
        }
    }, []);

    const [resetPassword, { data, loading: loginLoading, error: loginError }] = useMutation(RESET_PASSWORD, {
        onCompleted(data) {
            setEmail("");
            addNotification("success", translate.t("WeHaveSendYouResetLink"), translate.t("LinkSent"));
        },
        onError(err) {
            console.log(err);
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const checkForm = () => {
        if (email !== "") {
            resetPassword({ variables: { email, lang: translate.locale } });
        } else {
            addNotification("danger", translate.t("NotFilledEmail"), translate.t("Warning"));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            checkForm();
        }
    };

    return (
        <main className="page login-page">
            <h1>{translate.t("ResetPassword")}</h1>
            <div className="panel login-panel">
                <Logo className="logo" />
                <div className="form-group">
                    <label>{translate.t("Email")}</label>
                    <div className="input-group">
                        <input
                            type="email"
                            value={email}
                            onKeyPress={handleKeyPress}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder={translate.t("EnterYourEmail")}
                        />
                    </div>
                </div>
                <button type="button" disabled={loginLoading} className="btn blue" onClick={() => checkForm()}>
                    {translate.t("ResetPwdText")}
                    {loginLoading && <img src={loadingIcon} className="loading" />}
                </button>
                <div className="footer">
                    <Link to={"/login"} className="item">
                        {translate.t("SignInbackLink")}
                    </Link>
                </div>
            </div>

            <ReactNotifications />
        </main>
    );
};

export default ResetPassword;
