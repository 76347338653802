import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import React, { useEffect, useState } from 'react';
import {Switch, Route,  Redirect, useRouteMatch } from 'react-router-dom';
import Websocket, { getWebSocket, startWebSocket } from '../../Websocket/websocket';
import ConversationsPage from '../../Conversation/Components/ConversationsPage';
import SettingsPage from '../../Settings/Components/SettingsPage';
import People from '../../People/Components/People';

const Dashboard = (props) => {

    let { path, url }                               = useRouteMatch();
    const [socketInitialized,setSocketInitialized]  = useState(false);

    useEffect(() => {
        
        startWebSocket();
        
        setSocketInitialized(true);
        return () => {
            const websocket = getWebSocket();
            websocket.close();
        }
    }, []);

    return (	
        <>
            {socketInitialized &&
                <Switch>		

                    <Route exact path={`${path}/dashboard`}>
                        <Redirect to={`${url}/dashboard/conversations`} />
                    </Route>				
                    <Route path={`${path}/dashboard/conversations`} component={ConversationsPage}></Route>
                    <Route path={`${path}/dashboard/people`} component={People}></Route>
                    <Route path={`${path}/dashboard/settings`} component={SettingsPage}></Route>

                    <Route>
                        <Redirect to={`${url}/dashboard`} />
                    </Route>
                </Switch>
            }
        </>
    );
}

export default Dashboard;
