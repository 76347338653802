import React from 'react';
import translate from '../../Translations/index';


const Invoicing = (props) => {

    return(
        <div className="">
        </div>
    );

}

export default Invoicing;