import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import translate from '../Modules/Translations/index';
import Modal from 'react-modal';

function UploadFilesDropzone(props,ref) {

  const windowRef                                         = useRef(null);
  const isDragging                                        = useRef(false);
  const [dropzoneVisible,setDropzoneVisible]              = useState(false);
  const [showRejectedListModal,setShowRejectedListModal]  = useState(false);
  const [rejectedFileList,setRejectedFileList]            = useState([]);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({    
    maxSize:4000000, //bytes (4Mb)
    onDrop: (acceptedFiles) => {
      props.onDrop && props.onDrop(acceptedFiles);
    },
    onDropRejected: (fileRejections,event) => {
      setRejectedFileList(fileRejections);
      setShowRejectedListModal(true);
    },
    maxFiles:0,
    noClick:true,
    accept: '.pdf, application/pdf, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/gif, image/jpg, image/jpeg, image/png, image/bmp'   
  });

  useEffect(() => {
      document.addEventListener("dragenter", dragEnter);
      document.addEventListener("dragleave", dragLeave);
      document.addEventListener("drop", drop);
      return () => {
        document.removeEventListener("dragenter", dragEnter);
        document.removeEventListener("dragleave", dragLeave);
        document.removeEventListener("drop", drop);
      };
  }, []);

  const dragEnter = (event) => {
      windowRef.current   = event.target;
      isDragging.current  = true;
      setDropzoneVisible(true);
  }
  const dragLeave = (event) => {
    if (windowRef.current == event.target){   
      setDropzoneVisible(false);  
      isDragging.current  = false;
    }
  }
  const drop = () => {
    setDropzoneVisible(false);  
  }

  const openFileSelector = ()=>{
    open()    
  }
    
  useImperativeHandle(ref, () => ({
    openFileSelector:openFileSelector
  }));
    

  return (
    <>
      <div className={"dropzone " + (dropzoneVisible ? "visible " : "") + (isDragAccept ? "dragAccepted " : "") + (isDragReject ? "dragRejected " : "")} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='drop-files-here'>
          <i className="las la-download icon"></i>
          <div className='text'>{translate.t("DropFilesHere")}</div>
        </div>
      </div>
      <Modal
	        className="modal"
	        closeTimeoutMS={10}
	        isOpen={showRejectedListModal}
	        onRequestClose={() => setShowRejectedListModal(false)}
	    >
	        <div className="modal-content">
            <button type="button" className="close" onClick={() => setShowRejectedListModal(false)}>
              <i className="las la-times icon"></i>
            </button>
	          <div className="modal-header">
				      <h4 className="modal-title">{translate.t("RejectedFiles")}</h4>
	          </div>
	          <div className="modal-body">
              {rejectedFileList.map(({ file, errors }) => (
                <li key={file.path}>
                  {file.path} - {file.size} bytes
                  <ul>
                    {errors.map(e => (
                      <li key={e.code}>{e.message}</li>
                    ))}
                  </ul>
                </li>
              ))}	                    
	          </div>
            <div className="modal-footer center"> 
              <button type="button" className="btn blue" onClick={() => setShowRejectedListModal(false)}>{translate.t("Close")}</button>
            </div>
	        </div>
	    </Modal>
    </>
  );

  
}

export default forwardRef(UploadFilesDropzone);