import React, { useState } from 'react';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import translate from '../../Translations/index';
import EditOperator from './EditOperator';


const MyProfile = (props) => {

    const operatorData  = operatorCache.get();
    return(
        <>
            <div className='page'>
                <div className='settings-title'>
                    <h1>{translate.t("MyProfile")}</h1>
                </div>
                <EditOperator operatorID={operatorData.operatorID} />
            </div>
        </>
    );

}

export default MyProfile;