import { makeVar } from '@apollo/client';
  
export const dashboardVar = makeVar({
    mobileMenuTabSelected:1  
});

export const dashboardCache = {
    get: () => {
        return dashboardVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.mobileMenuTabSelected 		&& { mobileMenuTabSelected: data.mobileMenuTabSelected })
        }
        dashboardVar({...dashboardVar(),...newData});
    }
}