import { makeVar } from '@apollo/client';
import { GET_CONVERSATIONS } from '../../Modules/Conversation/Queries/conversation';
import { getApolloClient } from '../apolloClient';
import { conversationFiltersCache } from './conversationFiltersCache';
import { operatorCache } from './operatorCache';
import moment from 'moment';
import { addNotification, transformStatusToInt } from '../helper';
import translate from '../../Modules/Translations/index';
  
export const conversationListVar = makeVar({
    conversations:[]
});

export const conversationListCache = {
    loadActiveConversations: () => {        
        return new Promise(async (resolve,rejected) => {
            try{
                const client                = getApolloClient();
                const operatorData          = operatorCache.get();
                const order                 = conversationFiltersCache.getOrderBy();

                const data          = await client.query({
                    query: GET_CONVERSATIONS,
                    errorPolicy:"all",
                    fetchPolicy:"network-only",
                    notifyOnNetworkStatusChange: true,
                    variables:{
                        accountID:operatorData.accountID,
                        filters:{conversationStateID:2,searchQuery:"",operatorID:""},               
                        offset: 0,
                        limit: 10000,
                        operatorID:operatorData.operatorID,
                        order
                    }
                });       


                conversationListCache.setConversations(JSON.parse(JSON.stringify(data.data.conversations)));

                resolve(data);

			} catch (error) {
				return rejected(error);
			}			
		});
    },
    moveOldConversationsToPostponed: (conversationIDs) => {  
        //console.log("aaaa");      
        let allItems  = [...conversationListVar().conversations];

        for(let item of allItems){
            if(conversationIDs.includes(item.conversationID)){
                item.conversationStateID = 3;
            }
        }
        conversationListCache.setConversations(allItems);
    },
    get: () => {
        return conversationListVar().conversations;
    },
    getItem: (conversationID) => {        
        const allItems  = conversationListVar().conversations;

        for(let item of allItems){
            if(item.conversationID == conversationID){
                return item;
            }
        }
        return null;
    },
    setConversations: (data) => {
        let newData = [];
        for(const item of data){
            newData.push({
                conversationID:item.conversationID,
                visitorID:item.visitorID,
                show:true,
                isInternalConversation:item.isInternalConversation,
                conversationStateID:item.conversationStateID,
                visitor:{
                    name:item.visitor.name,
                    shortcut:item.visitor.shortcut,
                    status:item.visitor.status,
                    color:item.visitor.color,
                },                
                operators:item.operators,
                unreadMessageCount:item.unreadMessageCount ? item.unreadMessageCount : 0,
                lastMessage:item.lastMessage,
                waitingForResponseFrom:item.waitingForResponseFrom,
                visitorTyping:!!item.visitorTyping ? true : false    
            })
        }
        let conversations   = conversationListCache.filterArray(newData);
        conversations       = conversationListCache.orderArray(conversations);
        conversationListVar({...conversationListVar(), conversations});
    },
    addItem: (data) => {        
        const conversations = [...conversationListVar().conversations,{
            conversationID:data.conversationID,
            visitorID:data.visitorID,
            isInternalConversation:data.isInternalConversation,
            visitor:{
                name:data.visitor.name,
                shortcut:data.visitor.shortcut,
                status:data.visitor.status,
                color:data.visitor.color,
            },        
            lastMessage:data.lastMessage,
            operators:data.operators,
            unreadMessageCount:data.unreadMessageCount ? data.unreadMessageCount : 0,
            waitingForResponseFrom:data.waitingForResponseFrom,
            visitorTyping:!!data.visitorTyping ? true : false
        }]
        
        conversationListCache.setConversations(conversations);
    },
    addItemIfNotExist: (data) => {     
        
        const allItems  = conversationListVar().conversations;
        
        const itemExist = allItems.filter(function (value, index) {
            return (value.conversationID == data.conversationID);
        });

        if(itemExist.length == 0){
            const conversations = [...conversationListVar().conversations,{
                conversationID:data.conversationID,
                visitorID:data.visitorID,
                isInternalConversation:data.isInternalConversation,
                conversationStateID:data.conversationStateID,
                visitor:{
                    name:data.visitor.name,
                    shortcut:data.visitor.shortcut,
                    status:data.visitor.status,
                    color:data.visitor.color,
                },      
                lastMessage:data.lastMessage,
                operators:data.operators,
                unreadMessageCount:data.unreadMessageCount ? data.unreadMessageCount : 0,
                waitingForResponseFrom:data.waitingForResponseFrom,
                visitorTyping:!!data.visitorTyping ? true : false
            }]
            
            conversationListCache.setConversations(conversations);
        }

    },
    addOperatorItemIfNotExist: (conversationID,operatorData) => {     
        
        const allItems  = conversationListVar().conversations;
        let itemAdded   = false;

        for(let convItem of allItems){
            if(convItem.conversationID == conversationID){

                const operatorExist = convItem.operators.filter(function (value, index) {
                    return (value.operatorID == operatorData.operatorID);
                });
                if(operatorExist.length == 0){
                    itemAdded = true;
                    convItem.operators.push({
                        operatorID:operatorData.operatorID,
                        name:operatorData.name,
                        surname:operatorData.surname,
                        nameInChatbox:operatorData.nameInChatbox,
                        img:operatorData.img,
                        color:operatorData.color,
                        shortcut:operatorData.shortcut,
                        status:operatorData.status
                    });
                }
            }
        }
        if(itemAdded){
            conversationListVar({...conversationListVar(), conversations:[...allItems]});
        }
    },
    editOperator: (operatorID,data) => {     
        
        const allItems  = conversationListVar().conversations;
        let itemAdded   = false;

        for(let convItem of allItems){
            for(const key in convItem.operators){
                if(convItem.operators[key].operatorID == operatorID){
                    convItem.operators[key] = {
                        ...convItem.operators[key],
                        ...(data.name 		    && { name: data.name }),
                        ...(data.surname 		&& { surname: data.surname }),
                        ...(data.nameInChatbox 	&& { nameInChatbox: data.nameInChatbox }),                        
                        ...(data.shortcut 		&& { shortcut: data.shortcut }),
                        ...(data.img 		    && { img: data.img }),
                        ...(data.color 	        && { color: data.color }),
                        ...(data.status 	    && { status: data.status }),
                    }
                }
            }            
        }
        if(itemAdded){
            conversationListVar({...conversationListVar(), conversations:[...allItems]});
        }
    },
    getOperators: (conversationID) => {     
        
        const allItems  = conversationListVar().conversations;
        let itemAdded   = false;

        
        for(let convItem of allItems){
            if(convItem.conversationID == conversationID){
                return convItem.operators;
            }
        }
        return [];
    },
    operatorExist: (conversationID,operatorID) => {     
        
        const allItems  = conversationListVar().conversations;

        for(let convItem of allItems){
            if(convItem.conversationID == conversationID){

                const operatorExist = convItem.operators.filter(function (value, index) {
                    return (value.operatorID == operatorID);
                });
                
                return operatorExist.length > 0;
            }
        }
        return false;
    },
    removeOperatorItem: (conversationID,operatorID) => {   
        const allItems  = conversationListVar().conversations;
        let itemAdded   = false;

        for(let convItem of allItems){
            if(convItem.conversationID == conversationID){

                const restOperators = convItem.operators.filter(function (value, index) {
                    return (value.operatorID != operatorID);
                });
                
                convItem.operators = [...restOperators];
            }
        }
        conversationListVar({...conversationListVar(), conversations:[...allItems]});
        
    },
    editItem: (conversationID,data) => {  
        let items = conversationListVar().conversations;
        
        for(const key in items){
            if(items[key].conversationID == conversationID){
                items[key] = {
                    ...items[key],
                    ...(data.conversationID 		&& { conversationID: data.conversationID }),
                    ...(data.conversationStateID 	&& { conversationStateID: data.conversationStateID }),
                    ...(data.visitor 		        && {
                                                            visitor:{
                                                                name:data.visitor.name,
                                                                shortcut:data.visitor.shortcut,
                                                                status:data.visitor.status,
                                                                color:data.visitor.color,
                                                            } 
                                                        }),
                    ...(data.lastMessage 	        && { lastMessage: data.lastMessage }),
                    ...(data.operators 	            && { operators: data.operators }),
                    ...(typeof data.waitingForResponseFrom != "undefined" && { waitingForResponseFrom: data.waitingForResponseFrom }),                    
                    ...((data.unreadMessageCount || data.unreadMessageCount == 0)	&& { unreadMessageCount: data.unreadMessageCount }),         
                    ...(typeof data.visitorTyping != "undefined" && { visitorTyping: data.visitorTyping })
                }    
            }
        }        
        conversationListCache.setConversations([...items]);
    },
    editItemByVisitorID: (visitorID,data) => {  
        let items = conversationListVar().conversations;
        
        for(const key in items){
            if(items[key].visitorID == visitorID){
                items[key] = {
                    ...items[key],
                    ...(data.conversationID 		&& { conversationID: data.conversationID }),
                    ...(data.conversationStateID 	&& { conversationStateID: data.conversationStateID }),
                    ...(data.visitor 		        && {
                                                            visitor:{
                                                                name:data.visitor.name,
                                                                shortcut:data.visitor.shortcut,
                                                                status:data.visitor.status,
                                                                color:data.visitor.color,
                                                            } 
                                                        }),
                    ...(data.lastMessage 	        && { lastMessage: data.lastMessage }),
                    ...(data.operators 	            && { operators: data.operators }),
                    ...(typeof data.waitingForResponseFrom != "undefined" && { waitingForResponseFrom: data.waitingForResponseFrom }),       
                    ...((data.unreadMessageCount || data.unreadMessageCount == 0)	&& { unreadMessageCount: data.unreadMessageCount }),         
                    ...(typeof data.visitorTyping != "undefined" && { visitorTyping: data.visitorTyping })
                }    
            }
        }  
        conversationListCache.setConversations([...items]);
    },
    editVisitorData: (visitorID,data) => {  
        let items = conversationListVar().conversations;
        
        for(const key in items){
            if(items[key].visitorID == visitorID){
                items[key] = {
                    ...items[key],                    
                    visitor:{
                        name:data.name,
                        shortcut:data.shortcut,
                        status:data.status,
                        color:data.color,
                    }
                }    
            }
        }  
        conversationListCache.setConversations([...items]);
    },
    removeItemByConversationID: (conversationID) => {        
        
        const allItems = conversationListVar().conversations;
        const newItems = allItems.filter(function (value, index) {
            return (value.conversationID != conversationID);
        });
        
        conversationListCache.setConversations([...newItems]);
    },
    getUnreadMessagesCountSum: () => {
        let unreadMessagesCountSum = 0;
        let items               = conversationListVar().conversations;

        for(const item of items){
            unreadMessagesCountSum += item.unreadMessageCount;
        }

        return unreadMessagesCountSum;
    },
    getUnreadConversationCountSum: () => {
        let unreadConversationCountSum = 0;
        let items               = conversationListVar().conversations;

        for(const item of items){
            if(item.unreadMessageCount > 0){
                unreadConversationCountSum ++;
            }
        }

        return unreadConversationCountSum;
    },
    getUnreadNotMyConversationCountSum: () => {
        const operatorData              = operatorCache.get();
        let unreadConversationCountSum  = 0;
        let items                       = conversationListVar().conversations;

        for(const item of items){
            if(item.unreadMessageCount > 0 && item.operators.filter((operator) => operator.operatorID == operatorData.operatorID).length == 0){
                unreadConversationCountSum ++;
            }
        }

        return unreadConversationCountSum;
    },
    filter: () => {      
        const filters           = conversationFiltersCache.getFilters();
        let conversationList    = conversationListCache.get();
        conversationList        = conversationListCache.filterArray(conversationList);
        
        conversationListVar({...conversationListVar(), conversations:JSON.parse(JSON.stringify(conversationList))});
    },
    filterArray: (conversationList) => {      
        const filters           = conversationFiltersCache.getFilters();

        for(let item of conversationList){
            item.show = true;
        }

        for(let item of conversationList){

            //condition to check if conversation is assigned to specific operator
            if(filters.operatorID != ""){
                let isOperatorIn = false;
                for(let operator of item.operators){
                    if(operator.operatorID == filters.operatorID){
                        isOperatorIn = true;
                        break;
                    }
                }
                if(!isOperatorIn){
                    item.show = false;
                }
            }

            //condition to check if conversation is assigned to specific state
            if(filters.conversationStateID != item.conversationStateID){
                item.show = false;
            }

        }
        return conversationList;
    },
    order: () => {      
        const order             = conversationFiltersCache.getOrderBy();
        let conversationList    = conversationListCache.get();
        conversationList        = conversationListCache.orderArray(conversationList,order.by);        

        conversationListVar({...conversationListVar(), conversations:JSON.parse(JSON.stringify(conversationList))});
    },
    orderArray: (array,orderBy = null) => {
        
        if(!orderBy){
            const order     = conversationFiltersCache.getOrderBy();
            orderBy         = order.by;
        }
        switch (orderBy) {
            case "default":
                array.sort(conversationListCache.orderByLongesWaitingTime);
                break;
            case "status":
                array.sort(conversationListCache.orderByStatus);
                break;
        
            default:
                array.sort(conversationListCache.orderByLongesWaitingTime);
                break;
        }
        
        return array;
    },
    orderByLongesWaitingTime: (a,b) => {
        if(!!a.waitingForResponseFrom && !!b.waitingForResponseFrom){
            if(transformStatusToInt(a.visitor.status) < transformStatusToInt(b.visitor.status)){
                return conversationListCache.getOrderInt("<");
            }else if(transformStatusToInt(a.visitor.status) > transformStatusToInt(b.visitor.status)){
                return conversationListCache.getOrderInt(">");
            }else{
                if(moment(a.waitingForResponseFrom) < moment(b.waitingForResponseFrom)){
                    return conversationListCache.getOrderInt("<");
                }else{
                    return conversationListCache.getOrderInt(">");
                }
            }
        }else if(!!a.waitingForResponseFrom){
            return conversationListCache.getOrderInt("<");
        }else if(!!b.waitingForResponseFrom){
            return conversationListCache.getOrderInt(">");
        }else{
            return conversationListCache.getOrderInt(">");
        }
    },
    orderByStatus: (a,b) => {        

        if(!!a.visitor && !!b.visitor){
            if(transformStatusToInt(a.visitor.status) < transformStatusToInt(b.visitor.status)){
                return conversationListCache.getOrderInt("<");
            }else{
                return conversationListCache.getOrderInt(">");
            }
        }else if(!!a.visitor){
            return conversationListCache.getOrderInt("<");
        }else if(!!b.visitor){
            return conversationListCache.getOrderInt(">");
        }else{
            return  conversationListCache.getOrderInt();
        }
    },
    getOrderInt(compareDirection = "<"){
        const direction                 = conversationFiltersCache.getOrderBy().direction;
        if(compareDirection == "<"){
            return direction == "ASC" ? -1 : 1;
        }else if(compareDirection == ">"){
            return direction == "ASC" ? 1 : -1;
        }else{
            return 0;
        }
    }
}