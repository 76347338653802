import React from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Loading from '../../../../GlobalComponents/Loading';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import { useAdminRights } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import General from './General';
import OperatorCount from './OperatorCount';
import Security from './Security';


const Account = (props) => {

    let { path, url }                   = useRouteMatch();

	const operatorData                  = operatorCache.get();
    const {loading:roleCheckLoading}    = useAdminRights(operatorData?.roleID,["1","2"],"/account");
        
    return(
        <>
        
            {!roleCheckLoading ?
                <>
                    <div className='page'>
                        <div className='settings-title'>
                            <h1>{translate.t("YourAccountAndOperatorCount")}</h1>
                        </div>
                        <div className='menu'>
                            <NavLink to={`${url}/general`} activeClassName="selected" className="menu-item">
                                {translate.t("General")}
                            </NavLink>
                            <NavLink to={`${url}/security`} activeClassName="selected" className="menu-item">
                                {translate.t("Security")}
                            </NavLink>
                            <NavLink to={`${url}/operator-count`} activeClassName="selected" className="menu-item">
                                {translate.t("OperatorCount")}
                            </NavLink>
                        </div>
                        
                        <Switch>                
                            <Route exact path={`${path}`}>
                                <Redirect to={`${url}/general`} />
                            </Route>				
                            <Route path={`${url}/general`}><General /></Route>
                            <Route path={`${url}/security`}><Security /></Route>
                            <Route path={`${url}/operator-count`}><OperatorCount /></Route>
                        </Switch>     
                    </div>
                </>
                :
                <Loading displayText={false} />                
            }
        </>
    );

}

export default Account;