import { getApolloClient } from '../../../Library/apolloClient';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { EVENTS_NOTE } from '../Library/noteEvents';

  const noteHandlers = (socket) => {

    const client = getApolloClient();


    /**
     * Note added
     * @param {Object} data - websocket data 
     */
     const noteAdded = (data) => {    
      const conversationData = conversationCache.get();  
      if(conversationData.conversationID == data.note.conversationID){
        conversationCache.addNote(data.note);
      }
    } 

    /**
     * Note edited
     * @param {Object} data - websocket data 
     */
     const noteEdited = (data) => {    
      const conversationData = conversationCache.get();  
      if(conversationData.conversationID == data.note.conversationID){
        conversationCache.editNote(data.note.noteID,data.note);
      }
    } 

    /**
     * Note deleted
     * @param {Object} data - websocket data 
     */
     const noteDeleted = (data) => {    
      const conversationData = conversationCache.get();  
      if(conversationData.conversationID == data.note.conversationID){
        conversationCache.removeNote(data.note.noteID);
      }
    } 
  
    /**
     * Join websocket events with functions 
     */
    socket.on(EVENTS_NOTE.ADDED,noteAdded);
    socket.on(EVENTS_NOTE.EDITED,noteEdited);
    socket.on(EVENTS_NOTE.DELETED,noteDeleted);
    

  }


export default noteHandlers;