import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { addNotification } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { EDIT_ACCOUNT_DATA, GET_ACCOUNT_OPERATOR_COUNT_DATA } from '../../Queries/settings';
import {ReactComponent as Spinner} from '../../../../Public/Images/spinner.svg';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import Loading from '../../../../GlobalComponents/Loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';


const OperatorCount = (props) => {
    
    const [showSaveConfirmModal,setShowSaveConfirmModal] = useState(false);
    const [formData,setFormData]            = useState({
        operatorCount:15,
        pricePerOperator:0,
        price:0,
        currency:"Kč",
    })

    const { loading } = useQuery(GET_ACCOUNT_OPERATOR_COUNT_DATA,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            accountID: operatorCache.get().accountID
        },
        onCompleted(data){   
            if(!!data.account){        
                setFormData({...formData,
                    operatorCount: data.account.operatorCount,
                    pricePerOperator: data.account.pricePerOperator,
                    price: data.account.pricePerOperator * data.account.operatorCount,
                    currency: data.account.currency.title
                });
            }  
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });

    const [saveAccount,{loading:saveLoading}] = useMutation(EDIT_ACCOUNT_DATA,{
        onCompleted(data){            
			addNotification("success",translate.t("SuccessfullySaved"),translate.t("Success"));
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const saveOperatorCount = (status) => {
        
        if(status){
            saveAccount({
                variables:{
                    editAccountData:{
                        accountID: operatorCache.get().accountID,
                        operatorCount:formData.operatorCount
                    }
                }
            });
        }
        setShowSaveConfirmModal(false);        
    }

    const addRemoveOperator = (countToAdd) => {
        const newOperatorCount = formData.operatorCount + countToAdd;
        const newPrice = (99 * newOperatorCount);
        if(newOperatorCount > 0){
            setFormData({...formData,operatorCount: newOperatorCount, price: newPrice});
        }
    }
         
    return(
        <div className="account operator-count">
            <>
                {!loading ? 
                    <>
                        <div className='content'> 
                            <div className='shadow-box'>
                                <div className='title'>
                                    <i className="las la-user-astronaut icon"></i>
                                    <div className='text'>{translate.t("OperatorCountTitle")}</div>
                                </div>
                                <div className='price-block'>                                
                                    <button className='btn blue' onClick={() => addRemoveOperator(-1)}><i className="las la-minus icon"></i></button>
                                    <div className='count-number'>{formData.operatorCount}</div>
                                    <button className='btn blue' onClick={() => addRemoveOperator(1)}><i className="las la-plus icon"></i></button>
                                    <div className='price'>{formData.price} {formData.currency}</div>
                                    <button className='btn blue' disabled={saveLoading} onClick={() => setShowSaveConfirmModal(true)}>{!saveLoading ? translate.t("SaveChanges") : <Spinner />}</button>
                                </div>
                                <div className='info-block'>
                                    <div className='info'>
                                        <span dangerouslySetInnerHTML={{__html: translate.t("OperatorCountInfo1",{price:formData.price,currency:formData.currency})}}></span>
                                        <span>{translate.t("OperatorCountInfo2")}</span>
                                        <Link className='link' to=''>{translate.t("OperatorCountInfo3")}</Link>.
                                    </div>
                                    <div className='info'>
                                        <span>{translate.t("OperatorCountInfo4")}</span>
                                        <span>{translate.t("OperatorCountInfo5")}</span>
                                        <Link className='link' to=''>{translate.t("OperatorCountInfo6")}</Link>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Loading displayText={false} marginTop="14rem" />
                }
            </>
            
            {showSaveConfirmModal &&
                <ModalNotification 
                    yesNo={true} 
                    content={
                        <div>
                            <div>{translate.t("NewOperatorCountConfirmText1")}</div>
                            <div dangerouslySetInnerHTML={{__html: translate.t("NewOperatorCountConfirmText2",{price:formData.price,currency:formData.currency})}}></div>
                        </div>
                    } 
                    title={translate.t("SaveNewOperatorCount")} 
                    rejectBtnAsLink={true} 
                    confirmBtnText={translate.t("Yes")} 
                    rejectBtnText={translate.t("CancelAction")} 
                    callback={(status) => saveOperatorCount(status)} 
                />
            }
        </div>
    );

}

export default OperatorCount;