
import React,{ Component } from 'react';

const CustomTooltip = (props) => {	


    return (
    	<div className={`custom-tooltip-wrapper ${props.className ? props.className : ""}`} >	 
            <div className='custom-tooltip' style={{top: (!!props.top ? props.top : "0rem"),right: (!!props.right ? props.right : "auto"),bottom: (!!props.bottom ? props.bottom : "auto"),left: (!!props.left ? props.left : "0rem"),}}>
                <div className="custom-tooltip-content">{props.content}</div>          
                <div className='arrow'style={{top: (!!props.arrowTop ? props.arrowTop : "0.5rem"),right: (!!props.arrowRight ? props.arrowRight : "auto"),bottom: (!!props.arrowBottom ? props.arrowBottom : "auto"),left: (!!props.arrowLeft ? props.arrowLeft : "50%"),}}></div>
            </div>
            {props.children}
        </div>
    ); 
  
}


export default CustomTooltip;
