import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import moment from "moment";
import { floor, transpose } from "mathjs";
import translate from "../Modules/Translations/index";
import { settingsCache } from "./Cache/settingsCache";
import ShortUniqueId from "short-unique-id";
import { useHistory } from "react-router-dom";

export function getApolloErrorText(error) {
    error = JSON.stringify(error);
    error = JSON.parse(error);
    try {
        if (error.graphQLErrors[0]) return error.graphQLErrors[0].message;
        if (error.networkError && error.networkError.result.errors) {
            return error.networkError.result.errors[0].message;
        }
    } catch (e) {
        error.toString();
    }
}

export function validateEmail(email) {
    var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export function validateIPaddress(ipaddress) {
    if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|\*)$/.test(
            ipaddress
        )
    ) {
        return true;
    }
    return false;
}

export function goToInitialPage(history) {
    history.push("/account");
}

export function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function addNotification(type, content, title = null, duration = 7000) {
    const getTypeIcon = (type) => {
        if (type == "danger") {
            return <i className="las la-exclamation-triangle icon danger"></i>;
        } else if (type == "success") {
            return <i className="las la-thumbs-up icon success"></i>;
        } else if (type == "message-plus") {
            return <i className="las la-comment-medical icon message-plus"></i>;
        }
    };

    const uid = new ShortUniqueId({ length: 16 });
    const notificationID = uid.rnd();

    Store.addNotification({
        id: notificationID,
        title: title,
        //message: text,
        content: () => (
            <div className="toast-message">
                {getTypeIcon(type)}
                <div className="content">{content}</div>
                <i className="las la-times icon-remove" onClick={() => Store.removeNotification(notificationID)}></i>
            </div>
        ),
        type: type,
        showIcon: true,
        width: 356,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration,
            click: false,
            touch: false,
            //onScreen true
        },
    });
}

export function calculateOnlineTime(visitorData) {
    const today = moment();
    const lastVisit = moment.utc(visitorData.lastVisit);

    const duration = moment.duration(today.diff(lastVisit));
    const minutes = floor(duration.asMinutes(), 0);
    const hours = floor(minutes / 60);
    const restMinutes = floor(minutes - hours * 60);

    const timieString = (hours > 0 ? hours + " h " : "") + restMinutes + " m";

    return timieString;
}

export function getOperatingSystemIcon(osName, osVersion) {
    if (osName == "Windows") {
        return <i className="lab la-windows icon" data-for="os-device-tooltip" data-tip={osName + " " + osVersion}></i>;
    } else if (osName == "Mac OS") {
        return <i className="lab la-apple icon" data-for="os-device-tooltip" data-tip={osName + " " + osVersion}></i>;
    } else if (osName == "Android") {
        return <i className="lab la-android icon" data-for="os-device-tooltip" data-tip={osName + " " + osVersion}></i>;
    }
}
export function getDeviceIcon(isMobile, isTablet) {
    return isMobile || isTablet ? (
        <i className="las la-mobile icon" data-for="os-device-tooltip" data-tip={isMobile ? translate.t("Mobile") : translate.t("Tablet")}></i>
    ) : (
        <i className="las la-desktop icon" data-for="os-device-tooltip" data-tip={translate.t("Computer")}></i>
    );
}
export function getBrowserIcon() {}

export function playSound(type, volume = 1) {
    let mp3 = null;

    switch (type) {
        case "incoming-message":
            mp3 = require("../Public/Sounds/incoming_message.mp3").default;
            break;
        case "incoming-message-visible":
            mp3 = require("../Public/Sounds/incoming_message_visible.mp3").default;
            break;

        default:
            break;
    }
    if (mp3 != null) {
        var audio = new Audio(mp3);
        audio.volume = volume;
        audio.play();
    }
}

export function getMessageTimeString(dateTime, showNow = false) {
    const nowDate = moment();
    const messageDate = moment.utc(dateTime);

    const nowTimestamp = nowDate.unix();
    const messageTimestamp = messageDate.unix();

    const diffDuration = nowTimestamp - messageTimestamp;

    if (diffDuration < 60) {
        return showNow ? translate.t("Now") : "";
    } else if (diffDuration < 3600) {
        const minuteCount = floor(diffDuration / 60);
        return translate.t("BeforeMinute", { beforeMinute: minuteCount });
    } else if (diffDuration < 86400) {
        const hourCount = floor(diffDuration / 60 / 60);
        return translate.t("BeforeHour", { beforeHour: hourCount });
    } else if (diffDuration < 172800) {
        return translate.t("Yesterday") + " " + messageDate.local().format("HH:mm");
    } else {
        return messageDate.format("L HH:mm");
    }
}

export function middleEllipsis(str, startCharCount, endCharCount) {
    if (str.length > startCharCount + endCharCount) {
        return str.substr(0, startCharCount) + "..." + str.substr(str.length - endCharCount, str.length);
    }
    return str;
}

export function getNewMessageCountString(messageCount) {
    if (messageCount == 0) {
        return "";
    } else if (messageCount < 2) {
        return translate.t("MessageCountString1", { messageCount });
    } else if (messageCount < 5) {
        return translate.t("MessageCountString2to4", { messageCount });
    } else if (messageCount > 4) {
        return translate.t("MessageCountString5toMore", { messageCount });
    }
}
export function getConversationStatusString(conversation) {
    if (!conversation.visitorTyping) {
        if (conversation.conversationStateID == 2) {
            if (!!conversation.waitingForResponseFrom) {
                const nowDate = moment();
                const messageDate = moment.utc(conversation.waitingForResponseFrom);

                const nowTimestamp = nowDate.unix();
                const messageTimestamp = messageDate.unix();

                const diffDuration = nowTimestamp - messageTimestamp;
                /*
                if(diffDuration < 10){
                    return <div>{translate.t("WaitingMoreThanSeconds",{time:5})}</div>;
                }else if(diffDuration < 20){
                    return <div>{translate.t("WaitingMoreThanSeconds",{time:10})}</div>;
                }else if(diffDuration < 30){
                    return <div>{translate.t("WaitingMoreThanSeconds",{time:20})}</div>;
                }else if(diffDuration < 40){
                    return <div className='orange'>{translate.t("WaitingMoreThanSeconds",{time:30})}</div>;
                }else if(diffDuration < 50){
                    return <div className='orange'>{translate.t("WaitingMoreThanSeconds",{time:40})}</div>;
                }else if(diffDuration < 60){
                    return <div className='orange'>{translate.t("WaitingMoreThanSeconds",{time:50})}</div>;
                }else{
                    return <div className='red'>{translate.t("WaitingMoreThanMinutes",{time:floor(diffDuration / 60)})}</div>;
                }
                */

                if (diffDuration < 60) {
                    return (
                        <div className={`status-content ${diffDuration > 30 ? "orange" : ""}`}>
                            {translate.t("WaitingMoreThanSeconds", { time: diffDuration })}
                            <i className="las la-stopwatch icon"></i>
                        </div>
                    );
                } else if (floor(diffDuration / 60) < 60) {
                    return (
                        <div className="status-content red">
                            {translate.t("WaitingMoreThanMinutes", { time: floor(diffDuration / 60) })}
                            <i className="las la-stopwatch icon"></i>
                        </div>
                    );
                } else {
                    return (
                        <div className="status-content red">
                            {translate.t("WaitingMoreThanHours", { hours: floor(diffDuration / 3600), minutes: floor((diffDuration % 3600) / 60) })}
                            <i className="las la-stopwatch icon"></i>
                        </div>
                    );
                }
            }
            return (
                <div className="status-content">
                    {translate.t("ActiveStatus")}
                    <i className="las la-comments icon"></i>
                </div>
            );
        } else if (conversation.conversationStateID == 3) {
            return (
                <div className="status-content">
                    {translate.t("DefferedStatus")}
                    <i className="las la-stopwatch icon"></i>
                </div>
            );
        } else if (conversation.conversationStateID == 4) {
            return (
                <div className="status-content solved">
                    {translate.t("SolvedStatus")}
                    <i className="las la-check-circle icon"></i>
                </div>
            );
        }
    } else {
        return (
            <div className="status-content typing">
                {translate.t("ConversationListVisitorTyping")}...<i className="las la-pencil-alt icon"></i>
            </div>
        );
    }
}

export function transformStatusToInt(status) {
    if (status == "online") {
        return 1;
    } else if (status == "offline") {
        return 2;
    }
    return 2;
}

export function getFileIconByName(fileName) {
    const splittedFile = fileName.split(".");
    const extension = splittedFile[splittedFile.length - 1].toLowerCase();

    if (extension == "pdf") {
        return <i className="las la-file-pdf icon"></i>;
    } else if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "gif" || extension == "bmp") {
        return <i className="las la-file-image icon"></i>;
    } else if (extension == "xls" || extension == "xlsx") {
        return <i className="las la-file-excel icon"></i>;
    } else if (extension == "doc" || extension == "docx") {
        return <i className="las la-file-word icon"></i>;
    } else {
        return <i className="las la-file icon"></i>;
    }
}
export function isFileImage(fileName) {
    const splittedFile = fileName.split(".");
    const extension = splittedFile[splittedFile.length - 1].toLowerCase();

    if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "gif" || extension == "bmp") {
        return true;
    } else {
        return false;
    }
}

export function getLastHashtagFromText(text) {
    const shortcutSing = settingsCache.get().shortcutSign;
    var regEx = new RegExp("(" + shortcutSing + "[a-zd-]*)", "ig");

    const splittedText = text.split(" ");
    const lastSplittedText = splittedText[splittedText.length - 1];
    const shortcutsInText = lastSplittedText.match(regEx);
    if (!!shortcutsInText) {
        return shortcutsInText[shortcutsInText.length - 1].slice(1);
    } else {
        return null;
    }
}

export const useForceUpdate = () => {
    const [, setState] = useState();
    return () => setState({});
};

export const getMessagesDateSeparator = (lastMessageDate, messageDate) => {
    if (!moment(lastMessageDate).isSame(messageDate, "day")) {
        let displayDate = "";
        const nowDate = moment();
        const mesgDate = moment.utc(messageDate);

        if (nowDate.isSame(mesgDate, "day")) {
            displayDate = translate.t("DateSeparatorToday");
        } else if (nowDate.subtract(1, "day").isSame(mesgDate, "day")) {
            displayDate = translate.t("DateSeparatorYesterday");
        } else {
            displayDate = mesgDate.format("L");
        }

        return (
            <div className="messages-date-separator">
                <div className="separator-date">{displayDate}</div>
            </div>
        );
    } else {
        return null;
    }
};
export const getMessageGroupedInfo = (messageList, index) => {
    let groupedInfo = {
        isGrouped: false,
        isFirst: false,
        isLast: false,
    };

    if (messageList[index].messageTypeID == 1) {
        if (messageList[index].fromOperator) {
            if (
                !!messageList[index + 1] &&
                messageList[index + 1].messageTypeID == 1 &&
                messageList[index + 1].operatorID == messageList[index].operatorID &&
                moment(messageList[index + 1].createdDate)
                    .subtract(1, "minutes")
                    .unix() <= moment(messageList[index].createdDate).unix()
            ) {
                groupedInfo.isGrouped = true;
                if (
                    !messageList[index - 1] ||
                    messageList[index - 1].messageTypeID != 1 ||
                    messageList[index - 1].operatorID != messageList[index].operatorID ||
                    moment(messageList[index - 1].createdDate)
                        .add(1, "minutes")
                        .unix() <= moment(messageList[index].createdDate).unix()
                ) {
                    groupedInfo.isFirst = true;
                }
            } else if (
                !!messageList[index - 1] &&
                messageList[index - 1].messageTypeID == 1 &&
                messageList[index - 1].operatorID == messageList[index].operatorID &&
                moment(messageList[index - 1].createdDate)
                    .add(1, "minutes")
                    .unix() >= moment(messageList[index].createdDate).unix()
            ) {
                groupedInfo.isGrouped = true;
                if (
                    !messageList[index + 1] ||
                    messageList[index + 1].messageTypeID != 1 ||
                    messageList[index + 1].operatorID != messageList[index].operatorID ||
                    moment(messageList[index + 1].createdDate)
                        .subtract(1, "minutes")
                        .unix() >= moment(messageList[index].createdDate).unix()
                ) {
                    groupedInfo.isLast = true;
                }
            }
        } else {
            if (
                !!messageList[index + 1] &&
                messageList[index + 1].messageTypeID == 1 &&
                messageList[index + 1].visitorID == messageList[index].visitorID &&
                moment(messageList[index + 1].createdDate)
                    .subtract(1, "minutes")
                    .unix() <= moment(messageList[index].createdDate).unix()
            ) {
                groupedInfo.isGrouped = true;
                if (
                    !messageList[index - 1] ||
                    messageList[index - 1].messageTypeID != 1 ||
                    messageList[index - 1].visitorID != messageList[index].visitorID ||
                    moment(messageList[index - 1].createdDate)
                        .add(1, "minutes")
                        .unix() <= moment(messageList[index].createdDate).unix()
                ) {
                    groupedInfo.isFirst = true;
                }
            } else if (
                !!messageList[index - 1] &&
                messageList[index - 1].messageTypeID == 1 &&
                messageList[index - 1].visitorID == messageList[index].visitorID &&
                moment(messageList[index - 1].createdDate)
                    .add(1, "minutes")
                    .unix() >= moment(messageList[index].createdDate).unix()
            ) {
                groupedInfo.isGrouped = true;
                if (
                    !messageList[index + 1] ||
                    messageList[index + 1].messageTypeID != 1 ||
                    messageList[index + 1].visitorID != messageList[index].visitorID ||
                    moment(messageList[index + 1].createdDate)
                        .subtract(1, "minutes")
                        .unix() >= moment(messageList[index].createdDate).unix()
                ) {
                    groupedInfo.isLast = true;
                }
            }
        }
    }

    return groupedInfo;
};

export function checkAdminRights(oData, roleIDRestrictionArray, props) {
    if (!(oData && roleIDRestrictionArray.includes(oData.roleID))) {
        goToInitialPage(props.history);
    }
    return oData;
}

export function useAdminRights(roleToCheck, roleIDRestrictionArray, redirectTo) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (roleIDRestrictionArray.includes(roleToCheck)) {
            setLoading(false);
        } else {
            history.push(redirectTo);
        }
    }, [redirectTo]);

    return { loading };
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () {
            console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
            console.error("Async: Could not copy text: ", err);
            throw new Error("Unable to copy text");
        }
    );
}
