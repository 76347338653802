import React,{ Component } from 'react';
import { ReactComponent as OkIcon } from '../Public/Images/ok.svg';

const Checkbox = (props) => {

    return (   
      <div className={`checkbox-block ${!!props.disabled  ? "disabled" : ""}`}>
          <div className={`checkbox ${!!props.checked ? "checked" : ""}`}>
            {!!props.checked &&
              <OkIcon className="icon" />
            }
          </div>
          {!!props.label &&
            <div className={`label ${!!props.checked && !!props.lineThroughWhenChecked ? "line-through" : ""}`}>{props.label}</div>
          }
      </div>
    );
  
}


export default Checkbox;
