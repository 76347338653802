import { getApolloClient } from '../../../Library/apolloClient';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { conversationListCache } from '../../../Library/Cache/conversationListCache';
import { dashboardCache } from '../../../Library/Cache/dashboardCache';
import { visitorListCache } from '../../../Library/Cache/visitorListCache';
import { EVENTS_CONVERSATION } from '../Library/conversationEvents';
import { EVENTS_VISITOR } from '../Library/visitorEvents';

  const registerConversationHandlers = (socket) => {

    const client = getApolloClient();


    /**
     * Visitor's conversation activated
     * @param {Object} data - websocket data 
     */
     const conversationStarted = (data) => {    
        conversationListCache.addItemIfNotExist(data.conversation);
        conversationListCache.editItem(data.conversation.conversationID,data.conversation);

        if(conversationCache.get().conversationID == data.conversation.conversationID){
          conversationCache.set({conversationStateID:data.conversation.conversationStateID});
        }
    } 

    /**
     * Visitor's conversation paused
     * @param {Object} data - websocket data 
     */
    const conversationPaused = (data) => {    
      const selectedConversationListType = dashboardCache.get().selectedConversationListType;  
      /*
      if(selectedConversationListType == 1){
        conversationListCache.removeItemByConversationID(data.conversation.conversationID);
      }else if(selectedConversationListType == 2){
        conversationListCache.addItemIfNotExist(data.conversation);
      }
      */
      if(conversationCache.get().conversationID == data.conversation.conversationID){
        conversationCache.set({conversationStateID:data.conversation.conversationStateID});
      }
    } 

    /**
     * Visitor's conversation closed
     * @param {Object} data - websocket data 
     */
    const conversationClosed = (data) => {    
        conversationListCache.removeItemByConversationID(data.conversation.conversationID);
        if(conversationCache.get().conversationID == data.conversation.conversationID){
          conversationCache.set({conversationStateID:data.conversation.conversationStateID});
        }
    } 

    /**
     * Conversation changed
     * @param {Object} data - websocket data 
     */
    const conversationChanged = (data) => {   
      conversationListCache.editItem(data.conversationID,data.conversationData);
    } 


    /**
     * Conversation changed
     * @param {Object} data - websocket data 
     */
     const moveOldConversationsToPostponed = (data) => {   
      conversationListCache.moveOldConversationsToPostponed(data.conversationIDs);
    } 
    
  
    /**
     * Join websocket events with functions 
     */
    socket.on(EVENTS_CONVERSATION.CONVERSATION_STARTED,conversationStarted);
    socket.on(EVENTS_CONVERSATION.CONVERSATION_PAUSED,conversationPaused);
    socket.on(EVENTS_CONVERSATION.CONVERSATION_CLOSED,conversationClosed);
    socket.on(EVENTS_CONVERSATION.CONVERSATION_CHANGED,conversationChanged);
    socket.on(EVENTS_CONVERSATION.CONVERSATION_MOVE_TO_POSTPONED,moveOldConversationsToPostponed);
    

  }


export default registerConversationHandlers;