import { useEffect, useRef, useState } from "react";
import { ACCOUNT_IMAGE_PATH, OPERATOR_IMAGE_URL } from "../../../../Config/urls";
import translate from '../../../Translations/index';
import operator1 from "../../../../Public/Images/avatar_preview_1.jpg";
import operator2 from "../../../../Public/Images/avatar_preview_2.jpg";
import { ReactComponent as Logo } from '../../../../Public/Images/logo_no_title.svg';
import { operatorCache } from "../../../../Library/Cache/operatorCache";
import Checkbox from "../../../../GlobalComponents/Checkbox";

const LivePreview = (props) => {
    const [empty,setEmpty]              = useState("");
    const [top,setTop]                  = useState(0);
    const [moveClass,setMoveClass]      = useState("");
    const [state,setState]              = useState("online");
    const previewRef                    = useRef();

    useEffect(() => {
        function handleScroll() {
            if(!!previewRef.current){
                const offsetTop = previewRef.current.getBoundingClientRect().top;
                console.log(document.documentElement.scrollTop);
                console.log(offsetTop);
                if(offsetTop < 0){
                    setTop(Math.abs(offsetTop));
                    setMoveClass("move");
                }else{
                    setMoveClass("");
                }
            }
        }
        handleScroll();


        document.addEventListener('scroll', handleScroll);                
        return () => document.removeEventListener("scroll", handleScroll);
    },[])

    const prevLocale = translate.locale;

    const {previewData,type} = props;

    return(
        <div className={`live-preview-wrapper`} ref={previewRef}>
            <div className={`live-preview ${moveClass}`} style={{top:top}}>
                {function (){translate.locale = previewData.lang;}()}
                <>
                    {type == "appearance" &&
                        <>
                            <div className="state">
                                <div className="desc">{translate.t("LivePreviewStateDesctiption")}</div>
                                <div className="radio-items">
                                    <div className={`radio-item inline`} onClick={() => setState("online")} >									
                                        <div className={'radio-circle ' + (state == "online" ? "selected" : "")}></div>
                                        <div className='radio-item-text'>{translate.t("Online")}</div>
                                    </div>
                                    <div className={`radio-item inline`} onClick={() => setState("offline")} >									
                                        <div className={'radio-circle ' + (state == "offline" ? "selected" : "")}></div>
                                        <div className='radio-item-text'>{translate.t("Offline")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-opened">
                                <div className="content">
                                    <div className="header" style={{backgroundColor:previewData.mainColor}}>
                                        <div className='operator-list'>
                                        <div className="avatar  ">
                                            <div className="avatar-content">
                                                <div className="colored-avatar" >
                                                    <img src={operator1} alt="operator_avater" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="avatar  ">
                                            <div className="avatar-content">
                                                <div className="colored-avatar" >
                                                    <img src={operator2} alt="operator_avater" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='avatar plus'  style={{backgroundColor:previewData.mainColor}}><div className='avatar-content'>+3</div></div>
                                            
                                        </div>
                                        <div className="text-block">
                                            <div className='main-title'>{state == "online" ? previewData.chatboxOnlineTitle : previewData.chatboxOfflineTitle}</div>
                                            <div className='subtitle'><span className={`status ${state}`}></span> {state == "online" ? previewData.chatboxOnlineStatusTitle : previewData.chatboxOfflineStatusTitle}</div>
                                        </div>
                                        <div className='menu'>
                                            <i className="las la-bars icon"></i>
                                            <i className="las la-arrow-down icon"></i>
                                            <i className="las la-times icon"></i>
                                        </div>
                                    </div>		
                                    <div className="message-list">
                                        <div className="message-list-content">
                                            
                                            <div className="message-item sent">
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <div className="name">{translate.t("LivePreviewYou")}</div>
                                                        <div className="date-time">{translate.t("LivePreviewBeforeYesterday")}</div>
                                                    </div>
                                                    <div className="message" >{translate.t("LivePreviewMessage1")}</div>
                                                    <div className="read-by">{translate.t("LivePreviewReadYeserday")} <i className="las la-check read-by-icon"></i></div>
                                                </div>
                                            </div>
                                            
                                            <div className="message-item received ">
                                                <div className="message-avatar">
                                                    <div className="avatar  ">
                                                        <div className="avatar-content">
                                                            <div className="colored-avatar">
                                                                <img src={operator1} alt="operator_avater" />
                                                            </div>
                                                        </div>
                                                        <div className="status online border white-border"></div>
                                                    </div>
                                                </div>
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <div className="name">{translate.t("LivePreviewTomSupportman")}</div>
                                                        <div className="date-time">{translate.t("LivePreviewBefore4Minutes")}</div>
                                                    </div>
                                                    <div className="message">{translate.t("LivePreviewMessage2")}</div>
                                                </div>
                                            </div> 
                                        </div>					
                                    </div>
                                    <div className="respond-panel">
                                        <div className="text-block">
                                            <div className="textarea-content">
                                                <textarea className="w-100" placeholder={state == "online" ? previewData.textOnlinePlaceholder : previewData.textOfflinePlaceholder} value={empty} onChange={(e) => {}} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="chat-closed" style={{backgroundColor: previewData.mainColor}}>
                                <div className="content">
                                    <Logo className="logo" />
                                    <div className="text">{translate.t("Support")}</div>
                                </div>
                                <div className={`status ${state}`}></div>
                            </div>    
                        </>
                    }   
                    {type == "introduction" &&                          
                        <div className="chat-opened">
                            <div className="content">
                                <div className="header" style={{backgroundColor:previewData.mainColor}}>
                                    <div className='operator-list'>
                                        <div className="avatar  ">
                                            <div className="avatar-content">
                                                <div className="colored-avatar" >
                                                    <img src={operator1} alt="operator_avater" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="avatar  ">
                                            <div className="avatar-content">
                                                <div className="colored-avatar" >
                                                    <img src={operator2} alt="operator_avater" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='avatar plus'  style={{backgroundColor:previewData.mainColor}}><div className='avatar-content'>+3</div></div>
                                        
                                    </div>
                                    <div className="text-block">
                                        <div className='main-title'>{previewData.chatboxOnlineTitle}</div>
                                        <div className='subtitle'><span className={`status online`}></span> {previewData.chatboxOnlineStatusTitle}</div>
                                    </div>
                                    <div className='menu'>
                                        <i className="las la-bars icon"></i>
                                        <i className="las la-arrow-down icon"></i>
                                        <i className="las la-times icon"></i>
                                    </div>
                                </div>		
                                <div className="message-list">
                                    <div className="message-list-content">                                        
                                        
                                        <div className="message-item received ">
                                            <div className="message-avatar">
                                                <div className="avatar  ">
                                                    <div className="avatar-content">
                                                        <div className="colored-avatar">
                                                            <img src={previewData.systemOperatorPhoto ? process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_IMAGE_PATH + "/" + operatorCache.get().accountID + "/generalOperator/small_" + previewData.systemOperatorPhoto : process.env.REACT_APP_SERVER_URL + "/" + OPERATOR_IMAGE_URL + "/default_operator.jpg"} alt="operator_avater" />
                                                        </div>
                                                    </div>
                                                    <div className="status online border white-border"></div>
                                                </div>
                                            </div>
                                            <div className="message-content">
                                                <div className="message-header">
                                                    <div className="name">{previewData.systemOperatorName}</div>
                                                    <div className="date-time">{translate.t("LivePreviewBefore4Minutes")}</div>
                                                </div>
                                                <div className="message">{previewData.introductionText}</div>
                                            </div>
                                        </div> 
                                    </div>					
                                </div>
                                {previewData.introductionEnabled ? 
                                    <div className="introduction-form">    
                                        <div className="form">    
                                            {!!previewData.introductionGetName &&                              
                                                <div className='form-group'>
                                                    <label>{!!previewData.introductionNameRequired && "*"} {translate.t("YourName")}</label>
                                                    <div className='input-group'>
                                                        <input type="text" value={empty} onChange={(e) => {}} />
                                                    </div>
                                                </div>
                                            }        
                                            {!!previewData.introductionGetEmail &&                          
                                                <div className='form-group'>
                                                    <label>{!!previewData.introductionEmailRequired && "*"} {translate.t("Email")}</label>
                                                    <div className='input-group'>
                                                        <input type="text" value={empty} onChange={(e) => {}} />
                                                    </div>
                                                </div>   
                                            }      
                                            {!!previewData.introductionGetPhone &&                                 
                                                <div className='form-group'>
                                                    <label>{translate.t("Phone")}</label>
                                                    <div className='input-group'>
                                                        <input type="text" value={empty} onChange={(e) => {}} />
                                                    </div>
                                                </div>  
                                            }   
                                            <div className="send-block">
                                                {!!previewData.introductionShowGdpr &&  
                                                    <div className="gdpr">   
                                                        {!!previewData.introductionShowGdprCheckbox ?    
                                                            <>                 
                                                                <Checkbox label="" checked={true} />
                                                                <div className="label">{translate.t("AgreeWith")}<span className="link">{translate.t("GDPR")}</span></div>    
                                                            </>    
                                                            :
                                                            <span className="link">{translate.t("GDPR")}</span>
                                                        }
                                                    </div>
                                                }   
                                                <button className='btn blue' >{translate.t("Save")}</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="respond-panel">
                                        <div className="text-block">
                                            <div className="textarea-content">
                                                <textarea className="w-100" placeholder={state == "online" ? previewData.textOnlinePlaceholder : previewData.textOfflinePlaceholder} value={empty} onChange={(e) => {}} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div> 
                    }   
                    {type == "rating" &&                          
                        <div className="chat-opened">
                            <div className="content">
                                <div className="header" style={{backgroundColor:previewData.mainColor}}>
                                    <div className='operator-list'>
                                        <div className="avatar  ">
                                            <div className="avatar-content">
                                                <div className="colored-avatar" >
                                                    <img src={operator1} alt="operator_avater" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="avatar  ">
                                            <div className="avatar-content">
                                                <div className="colored-avatar" >
                                                    <img src={operator2} alt="operator_avater" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='avatar plus'  style={{backgroundColor:previewData.mainColor}}><div className='avatar-content'>+3</div></div>
                                        
                                    </div>
                                    <div className="text-block">
                                        <div className='main-title'>{previewData.chatboxOnlineTitle}</div>
                                        <div className='subtitle'><span className={`status online`}></span> {previewData.chatboxOnlineStatusTitle}</div>
                                    </div>
                                    <div className='menu'>
                                        <i className="las la-bars icon"></i>
                                        <i className="las la-arrow-down icon"></i>
                                        <i className="las la-times icon"></i>
                                    </div>
                                </div>		
                                <div className="message-list">
                                    <div className="message-list-content">                                        
                                        <div className="message-system-info"><div className="message"><span className="strong">{translate.t("LivePreviewTomSupportman")}</span> {translate.t("ChangedConversationStateToSolved")} </div></div>
                                        {!!previewData.ratingEnabled &&
                                            <div className="message-item received ">
                                                <div className="message-avatar">
                                                    <div className="avatar  ">
                                                        <div className="avatar-content">
                                                            <div className="colored-avatar">
                                                                <img src={previewData.systemOperatorPhoto ? process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_IMAGE_PATH + "/" + operatorCache.get().accountID + "/generalOperator/small_" + previewData.systemOperatorPhoto : process.env.REACT_APP_SERVER_URL + "/" + OPERATOR_IMAGE_URL + "/default_operator.jpg"} alt="operator_avater" />
                                                            </div>
                                                        </div>
                                                        <div className="status online border white-border"></div>
                                                    </div>
                                                </div>
                                                <div className="message-content">
                                                    <div className="message-header">
                                                        <div className="name">{previewData.systemOperatorName}</div>
                                                        <div className="date-time">{translate.t("LivePreviewBefore4Minutes")}</div>
                                                    </div>
                                                    <div className="message">{previewData.ratingText}</div>
                                                    <div className="rating">
                                                        <div className="rating-item">
                                                            <i className="las la-grin-hearts icon"></i>
                                                            <div className="text">{translate.t("RatingValueGreat")}</div>
                                                        </div>
                                                        <div className="rating-item">
                                                            <i className="las la-thumbs-up icon"></i>
                                                            <div className="text">{translate.t("RatingValueNeutral")}</div>
                                                        </div>
                                                        <div className="rating-item">
                                                            <i className="las la-poop icon"></i>
                                                            <div className="text">{translate.t("RatingValueBad")}</div>
                                                        </div>
                                                    </div>
                                                </div>                                            
                                            </div>   
                                        }                                      
                                    </div>					
                                </div>
                                <div className="respond-panel">
                                    <div className="text-block">
                                        <div className="textarea-content">
                                            <textarea className="w-100" placeholder={previewData.textOnlinePlaceholder} value={empty} onChange={(e) => {}} ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    } 
                </>   
                {function (){translate.locale = prevLocale;}()}
            </div>
        </div>
    );

}

export default LivePreview;