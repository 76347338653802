import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import AvatarPicker from "../../../GlobalComponents/AvatarPicker";
import { addNotification } from "../../../Library/helper";
import translate from "../../Translations/index";
import { ReactComponent as Spinner } from "../../../Public/Images/spinner.svg";
import { operatorCache } from "../../../Library/Cache/operatorCache";
import { OPERATOR_IMAGE_URL } from "../../../Config/urls";
import Loading from "../../../GlobalComponents/Loading";
import operatorEmiters from "../../Websocket/Emiters/operatorEmiters";
import {
    CHANGE_OPERATOR_PHOTO,
    EDIT_OPERATOR_ACCOUNT_DATA,
    CHANGE_OPERATOR_PASSWORD,
    GET_OPERATOR_ACCOUNT,
    DELETE_MY_OPERATOR_ACCOUNT,
} from "../../Operator/Queries/operator";
import ModalNotification from "../../../GlobalComponents/ModalNotification";

const EditOperator = (props) => {
    const client = useApolloClient();
    const operatorData = operatorCache.get();

    const [maxCharCounts, setMaxCharCounts] = useState({
        name: 30,
        surname: 30,
        nameInChatbox: 30,
        email: 30,
    });
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [initialOperatorStatus, setInitialOperatorStatus] = useState();
    const [operatorPhoto, setOperatorPhoto] = useState();
    const [formData, setFormData] = useState({
        operatorID: props.operatorID,
        name: "",
        surname: "",
        nameInChatbox: "",
        email: "",
        status: null,
        roleID: null,
    });
    const [formDataPassword, setFormDataPassword] = useState({
        operatorID: props.operatorID,
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
    });

    const { data, loading, refetch } = useQuery(GET_OPERATOR_ACCOUNT, {
        fetchPolicy: "network-only",
        variables: {
            operatorID: props.operatorID,
            accountID: operatorData.accountID,
        },
        onCompleted(data) {
            if (!!data.operatorAccount) {
                let setData = {
                    ...formData,
                    name: data.operatorAccount.operator.name,
                    surname: data.operatorAccount.operator.surname,
                    nameInChatbox: data.operatorAccount.operator.nameInChatbox,
                    email: data.operatorAccount.operator.email,
                };
                if (parseInt(operatorData.roleID) >= parseInt(data.operatorAccount.roleID) && operatorData.operatorID != props.operatorID) {
                    setData.status = data.operatorAccount.status;
                    setData.roleID = data.operatorAccount.roleID;
                }
                setFormData(setData);

                setOperatorPhoto(data.operatorAccount.operator.img);
                setInitialOperatorStatus(data.operatorAccount.status);
            }
        },
        onError(err) {
            console.log(err);
            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });

    const [editOperator, { loading: saveLoading }] = useMutation(EDIT_OPERATOR_ACCOUNT_DATA, {
        onCompleted(data) {
            addNotification("success", translate.t("SuccessfullySaved"), translate.t("Success"));
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });
    const [changePassword, { loading: changePasswordLoading }] = useMutation(CHANGE_OPERATOR_PASSWORD, {
        onCompleted(data) {
            setFormDataPassword({
                ...formDataPassword,
                oldPassword: "",
                newPassword: "",
                newPasswordConfirm: "",
            });
            addNotification("success", translate.t("PasswordSuccessfullyChanged"), translate.t("Success"));
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });
    const [deleteMyOperatorAccount, { loading: deleteOperatorAccountLoading }] = useMutation(DELETE_MY_OPERATOR_ACCOUNT, {
        onCompleted(data) {
            addNotification("success", translate.t("AccountSuccessfullyDeleted"), translate.t("Success"));
            setTimeout(() => {
                logout();
            }, 3000);
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });
    const [saveOperatorPhoto, { loading: savePhotoLoading }] = useMutation(CHANGE_OPERATOR_PHOTO, {
        context: {
            headers: {
                "Apollo-Require-Preflight": true,
            },
        },
        onCompleted(data) {
            console.log("aaa");
            addNotification("success", translate.t("PhotoSuccessfullySaved"), translate.t("Success"));
            setOperatorPhoto(data.changeOperatorPhoto.img);
        },
        onError(err) {
            console.log("bbb");
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const checkMaxCount = (text, paramToCheck) => {
        return text.length <= maxCharCounts[paramToCheck] ? true : false;
    };

    const saveData = () => {
        editOperator({
            variables: {
                accountID: operatorData.accountID,
                operatorID: formData.operatorID,
                editOperatorAccountData: {
                    name: formData.name,
                    surname: formData.surname,
                    nameInChatbox: formData.nameInChatbox,
                    email: formData.email,
                    roleID: formData.roleID,
                    status: formData.status,
                },
            },
        });

        if (initialOperatorStatus != formData.status) {
            operatorEmiters.changeStatus(props.operatorID, operatorData.accountID, formData.status);
            setInitialOperatorStatus(formData.status);
        }
    };
    const saveNewOperatorPhoto = (photo) => {
        saveOperatorPhoto({ variables: { operatorID: formData.operatorID, photo } });
    };

    const saveNewPassword = () => {
        if (!formDataPassword.newPassword || !formDataPassword.newPasswordConfirm || !formDataPassword.oldPassword) {
            addNotification("danger", translate.t("YouHaveNotFilledRequiredFields"), translate.t("Error"));
            return;
        }
        if (formDataPassword.newPassword != formDataPassword.newPasswordConfirm) {
            addNotification("danger", translate.t("NewPasswordsNotEqual"), translate.t("Error"));
            return;
        }

        changePassword({
            variables: {
                operatorID: formDataPassword.operatorID,
                newPassword: formDataPassword.newPassword,
                oldPassword: formDataPassword.oldPassword,
                lang: translate.locale,
            },
        });
    };

    const deleteAccConfirmation = (status) => {
        if (status) {
            deleteMyOperatorAccount({
                variables: {
                    operatorID: operatorData.operatorID,
                    accountID: operatorData.accountID,
                },
            });
        }
        setShowDeleteAccountModal(false);
    };

    const logout = () => {
        client.resetStore();
        localStorage.removeItem("token");
        window.location = "/login";
    };
    return (
        <>
            {!loading ? (
                <div className="edit-operator">
                    <div className="left-column">
                        <div className="group-title">{translate.t("PersonalData")}</div>
                        <div className="form-group">
                            <label>
                                {translate.t("Name")}
                                <span className="description right">
                                    {formData.name.length}/{maxCharCounts.name}
                                </span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => checkMaxCount(e.target.value, "name") && setFormData({ ...formData, name: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>
                                {translate.t("Surname")}
                                <span className="description right">
                                    {formData.surname.length}/{maxCharCounts.surname}
                                </span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formData.surname}
                                    onChange={(e) => checkMaxCount(e.target.value, "surname") && setFormData({ ...formData, surname: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>
                                {translate.t("NameInChatbox")}
                                <span className="description right">
                                    {formData.nameInChatbox.length}/{maxCharCounts.nameInChatbox}
                                </span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formData.nameInChatbox}
                                    onChange={(e) =>
                                        checkMaxCount(e.target.value, "nameInChatbox") && setFormData({ ...formData, nameInChatbox: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>
                                {translate.t("Email")}
                                <span className="description right">
                                    {formData.email.length}/{maxCharCounts.email}
                                </span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formData.email}
                                    onChange={(e) => checkMaxCount(e.target.value, "email") && setFormData({ ...formData, email: e.target.value })}
                                />
                            </div>
                        </div>
                        {parseInt(operatorData.roleID) >= parseInt(data.operatorAccount.roleID) && operatorData.operatorID != props.operatorID && (
                            <>
                                <div className="group-title">{translate.t("Status")}</div>
                                <div className="form-group">
                                    <label>{translate.t("ChangeStatus")}</label>
                                    <div className="input-group">
                                        <select
                                            className="form-select language"
                                            value={formData.status}
                                            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                                        >
                                            <option value="online">{translate.t("Online")}</option>
                                            <option value="paused">{translate.t("Paused")}</option>
                                            <option value="offline">{translate.t("Offline")}</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                        {parseInt(operatorData.roleID) >= parseInt(data.operatorAccount.roleID) && operatorData.operatorID != props.operatorID && (
                            <>
                                <div className="group-title">{translate.t("Role")}</div>
                                <div className="form-group">
                                    <label>{translate.t("ChangeRole")}</label>
                                    <div className="input-group">
                                        <select
                                            className="form-select language"
                                            value={formData.roleID}
                                            onChange={(e) => setFormData({ ...formData, roleID: e.target.value })}
                                        >
                                            <option value="1">{translate.t("Owner")}</option>
                                            <option value="2">{translate.t("Admin")}</option>
                                            <option value="3">{translate.t("Operator")}</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                        <button className="btn blue" disabled={saveLoading} onClick={() => saveData()}>
                            {!saveLoading ? translate.t("SaveChanges") : <Spinner />}
                        </button>

                        <div className="group-title">{translate.t("ChangePassword")}</div>
                        <div className="form-group">
                            <label>*{translate.t("OldPassword")}</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formDataPassword.oldPassword}
                                    onChange={(e) => setFormDataPassword({ ...formDataPassword, oldPassword: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>*{translate.t("NewPassword")}</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formDataPassword.newPassword}
                                    onChange={(e) => setFormDataPassword({ ...formDataPassword, newPassword: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>*{translate.t("NewPasswordConfirm")}</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={formDataPassword.newPasswordConfirm}
                                    onChange={(e) => setFormDataPassword({ ...formDataPassword, newPasswordConfirm: e.target.value })}
                                />
                            </div>
                        </div>
                        <button className="btn blue" disabled={changePasswordLoading} onClick={() => saveNewPassword()}>
                            {!changePasswordLoading ? translate.t("ChangePassword") : <Spinner />}
                        </button>
                        {operatorData.operatorID == props.operatorID && (
                            <p className="remove-account-paragraph">
                                {translate.t("YouAreAboutTo")}{" "}
                                <span className="link" onClick={() => setShowDeleteAccountModal(true)}>
                                    {translate.t("DeleteAccount")}?
                                </span>{" "}
                                {translate.t("ItIsPossible")}
                            </p>
                        )}
                    </div>
                    <div className="right-column">
                        <AvatarPicker
                            photo={
                                !!operatorPhoto
                                    ? process.env.REACT_APP_SERVER_URL + "/" + OPERATOR_IMAGE_URL + "/" + props.operatorID + "/small_" + operatorPhoto
                                    : null
                            }
                            loading={savePhotoLoading}
                            modalTitle={translate.t("ChangeProfilePhoto")}
                            onSelected={(photo) => saveNewOperatorPhoto(photo)}
                        />
                    </div>
                    {showDeleteAccountModal && (
                        <ModalNotification
                            confirmBtnColor="red"
                            overlayClassName="red"
                            yesNo={true}
                            title={translate.t("DeleteAccModalTitle")}
                            content={translate.t("DeleteAccModalText")}
                            rejectBtnAsLink={true}
                            confirmBtnText={translate.t("Delete")}
                            rejectBtnText={translate.t("CancelAction")}
                            callback={(status) => deleteAccConfirmation(status)}
                        />
                    )}
                </div>
            ) : (
                <Loading displayText={false} marginTop="14rem" />
            )}
        </>
    );
};

export default EditOperator;
