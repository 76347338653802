import React, { useState } from "react";
import translate from "../../Translations/index";
import Loading from "../../../GlobalComponents/Loading";
import { GET_OPERATORS_FOR_SETTING } from "../Queries/operator";
import { useQuery } from "@apollo/client";
import { addNotification } from "../../../Library/helper";
import { operatorCache } from "../../../Library/Cache/operatorCache";
import AvatarOperator from "../../../GlobalComponents/AvatarOperator";
import Dropdown from "../../../GlobalComponents/Dropdown";

const Operators = (props) => {
    const { loading, data } = useQuery(GET_OPERATORS_FOR_SETTING, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            accountID: operatorCache.get().accountID,
        },
        onCompleted(data) {},
        onError(err) {
            console.log(err);
            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });
    return (
        <div className="page">
            <div className="people-title">
                <h1>{translate.t("Operators")}</h1>
            </div>
            {!loading ? (
                <>
                    <div className="operator-subtitle">
                        <h2>{translate.t("ListOfAllOperators")}</h2>
                    </div>
                    <table className="full-width hoverable visitor-table online-list">
                        <thead>
                            <tr>
                                <th className="left">{translate.t("Name")}</th>
                                <th className="left">{translate.t("Role")}</th>
                                <th className="right"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.allOperators.map((item, index) => (
                                <tr key={index}>
                                    <td className="left">
                                        <div className="d-flex flex-row align-items-center">
                                            <AvatarOperator operatorData={item} statusClass="border white-border" />
                                            <div className="avatar-name">
                                                {item.name} {item.surname}
                                            </div>
                                        </div>
                                    </td>

                                    <td className="left">{item?.role?.name}</td>
                                    <td className="list-menu">
                                        <Dropdown
                                            content={<i className="las la-ellipsis-v icon"></i>}
                                            arrowLeft="auto"
                                            arrowRight="1.1rem"
                                            contentWidth="21rem"
                                            dropdownContent={
                                                <div className="dropdown-item" onClick={(e) => {}}>
                                                    <div className="text">{translate.t("OpenConversation")}</div>
                                                </div>
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <Loading displayText={false} />
            )}
        </div>
    );
};

export default Operators;
