/**
 *  Component for render messages
 */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import translate from '../../Translations/index';
import AvatarOperator from '../../../GlobalComponents/AvatarOperator';
import AvatarVisitor from '../../../GlobalComponents/AvatarVisitor';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { operatorListCache } from '../../../Library/Cache/operatorListCache';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import { getMessageTimeString } from '../../../Library/helper';
import moment from 'moment';
import ReadBy from './ReadBy';
import FileDisplay from './FileDisplay';
import AvatarSystem from '../../../GlobalComponents/AvatarSystem';



const Message = (props) => {
   
    const {message}     = props;
    const operatorData  = operatorCache.get();
    const visitorData   = conversationCache.getVisitor();
    let otherOperatorData = null;
    let chatboxData = null;
    
    return(
        <> 
            {/**
             * text message from visitor or other operator
             */}
            {message.messageTypeID == 1 && ((!message.fromOperator && !!message.visitorID) || (message.operatorID != operatorData.operatorID && !!message.operatorID)) &&                                          
                <div className={`message-item received ${props.groupedInfo.isGrouped ? "grouped" : ""} ${props.groupedInfo.isFirst ? "grouped-first" : ""} ${props.groupedInfo.isLast ? "grouped-last" : ""}`}>
                    {function(){  
                        otherOperatorData = message.fromOperator ? operatorListCache.getItemByID(message.operatorID) : null;            
                    }()}  

                    
                    <div className='message-avatar'>
                        
                        {!message.fromOperator ?
                            <AvatarVisitor showStatus={false} visitorData={conversationCache.getVisitor()} statusClass="border white-border"/>
                            :
                            <AvatarOperator operatorData={otherOperatorData} statusClass="border white-border"/>
                        }
                    </div>

                    <div className='message-content'>
                        
                        <div className='message-header'>
                            <div className='name'>
                                {!message.fromOperator ?
                                    <>{!!visitorData.name ? visitorData.name : visitorData.visitorID}</>
                                    :
                                    <>{!!otherOperatorData.nameInChatbox ? otherOperatorData.nameInChatbox : otherOperatorData.name + " " + otherOperatorData.surname}</>
                                }
                            </div>
                            <div className='date-time' title={moment.utc(message.createdDate).local().format("L LT")}>
                                {getMessageTimeString(message.createdDate,true)}
                            </div>
                        </div>                        
                        <div className={`message`} data-tip-disable={!props.groupedInfo.isGrouped || props.groupedInfo.isFirst} data-tip={getMessageTimeString(message.createdDate,true)} data-for="message-time-tooltip" data-delay-show="800">
                            {message.files.length > 0 &&
                                <div className='files'>
                                    {message.files.map((file,fileIndex) => (
                                        <FileDisplay key={fileIndex} file={file} />
                                    ))}
                                </div>
                            }
                            {message.text}
                        </div>
                    </div>
                    
                </div>
            }

            {/**
             * text message from me (operator)
             */}
            {message.messageTypeID == 1 && message.operatorID == operatorData.operatorID &&                                                
                <div className={`message-item sent ${props.groupedInfo.isGrouped ? "grouped" : ""} ${props.groupedInfo.isFirst ? "grouped-first" : ""} ${props.groupedInfo.isLast ? "grouped-last" : ""}`}>

                    
                    {(!props.groupedInfo.isGrouped || (props.groupedInfo.isGrouped && props.groupedInfo.isFirst)) &&
                        <div className='message-avatar'>
                            <AvatarOperator operatorData={operatorData} statusClass="border white-border" />
                        </div>
                    }
                    <div className='message-content'>
                        
                        <div className='message-header'>
                            <div className='name'>
                                {!!operatorData.nameInChatbox ? operatorData.nameInChatbox : operatorData.name + " " + operatorData.surname}
                            </div>
                            <div className='date-time' title={moment.utc(message.createdDate).local().format("L LT")}>
                                {getMessageTimeString(message.createdDate,true)}
                            </div>
                        </div>
                        <div className="message" data-tip-disable={!props.groupedInfo.isGrouped || props.groupedInfo.isFirst} data-tip={getMessageTimeString(message.createdDate,true)} data-for="message-time-tooltip" data-delay-show="800">
                            {message.files.length > 0 &&
                                <div className='files'>
                                    {message.files.map((file,fileIndex) => (
                                        <FileDisplay key={fileIndex} file={file} />
                                    ))}
                                </div>
                            }
                            {message.text}
                        </div>
                        
                        {message.readBy.length > 0 && message.operatorID == operatorData.operatorID &&
                            <ReadBy message={message} />                            
                        }
                    </div>

                </div>
            }
            
 
             {/**
              * text message from system
              */}
             {(message.messageTypeID == 1 || message.messageTypeID == 7) && !message.visitorID && !message.operatorID &&                                              
                 <div className={`message-item received `}>
    
                    {function(){  
                        chatboxData = conversationCache.getChatbox();            
                    }()}  

                    <div className='message-avatar'>                         
                        <AvatarSystem chatboxData={chatboxData} statusClass="border white-border"/>                         
                    </div>
                    <div className='message-content'>
                        
                        <div className='message-header'>
                            <div className='name'>
                                <>{chatboxData?.systemOperatorName}</>
                            </div>
                            <div className='date-time' title={moment.utc(message.createdDate).local().format("L LT")}>
                                {getMessageTimeString(message.createdDate,true)}
                            </div>
                        </div>
                        <div className="message" data-tip-disable={!props.groupedInfo.isGrouped || props.groupedInfo.isFirst} data-tip={getMessageTimeString(message.createdDate,true)} data-for="message-time-tooltip-right" data-delay-show="800">
                            {message.text}
                        </div>
                        {message.messageTypeID == 7 && !message.ratingSelected &&
                            <div className="rating">
                                <div className="rating-item no-hover">
                                    <i className="las la-grin-hearts icon"></i>
                                    <div className="text">{translate.t("RatingValueGreat")}</div>
                                </div>
                                <div className="rating-item no-hover">
                                    <i className="las la-thumbs-up icon"></i>
                                    <div className="text">{translate.t("RatingValueNeutral")}</div>
                                </div>
                                <div className="rating-item no-hover">
                                    <i className="las la-poop icon"></i>
                                    <div className="text">{translate.t("RatingValueBad")}</div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </div>
             }

            {/**
             * Operator joined message
             */}
            {message.messageTypeID == 2 &&                                                
                <div className="message-system-info">
                    <div className="message"><span className='strong'>{!!operatorListCache.getItemByID(message.operatorID)?.nameInChatbox ? operatorListCache.getItemByID(message.operatorID)?.nameInChatbox : operatorListCache.getItemByID(message.operatorID)?.name + " " + operatorListCache.getItemByID(message.operatorID)?.surname}</span> {translate.t("JoinedChat")} {getMessageTimeString(message.createdDate)}</div>
                </div>
            }

            {/**
             * Operator leaved message
             */}
            {message.messageTypeID == 3 &&                                                
                <div className="message-system-info">
                    <div className="message"><span className='strong'>{!!operatorListCache.getItemByID(message.operatorID)?.nameInChatbox ? operatorListCache.getItemByID(message.operatorID)?.nameInChatbox : operatorListCache.getItemByID(message.operatorID)?.name + " " + operatorListCache.getItemByID(message.operatorID)?.surname}</span> {translate.t("LeavedChat")} {getMessageTimeString(message.createdDate)}</div>
                </div>
            }

            {/**
             * Conversation started message
             */}
            {message.messageTypeID == 4 &&                                                
                <div className="message-system-info">
                    <div className="message">
                        {!message.operatorID && !message.visitorID &&
                            <span className='strong'>
                                {translate.t("System")}
                            </span> 
                        }
                        {!!message.operatorID && 
                            <span className='strong'>
                                {!!operatorListCache.getItemByID(message.operatorID)?.nameInChatbox ? operatorListCache.getItemByID(message.operatorID)?.nameInChatbox : operatorListCache.getItemByID(message.operatorID)?.name + " " + operatorListCache.getItemByID(message.operatorID)?.surname}
                            </span> 
                        }
                        {!!message.visitorID && 
                            <span className='strong'>
                                {(!!conversationCache.getVisitor().name ? conversationCache.getVisitor().name : conversationCache.getVisitor().visitorID)}
                            </span> 
                        }
                        {" " + translate.t("ChangedConversationStateToActive")} {getMessageTimeString(message.createdDate)}
                    </div>
                </div>
            }

            {/**
             * Conversation closed message
             */}
            {message.messageTypeID == 5 &&                                                
                <div className="message-system-info">
                    <div className="message">
                        {!message.operatorID && !message.visitorID &&
                            <span className='strong'>
                                {translate.t("System")}
                            </span> 
                        }
                        {!!message.operatorID && 
                            <span className='strong'>
                                {!!operatorListCache.getItemByID(message.operatorID)?.nameInChatbox ? operatorListCache.getItemByID(message.operatorID)?.nameInChatbox : operatorListCache.getItemByID(message.operatorID)?.name + " " + operatorListCache.getItemByID(message.operatorID)?.surname}
                            </span> 
                        }
                        {!!message.visitorID && 
                            <span className='strong'>
                                {(!!conversationCache.getVisitor().name ? conversationCache.getVisitor().name : conversationCache.getVisitor().visitorID)}
                            </span> 
                        }
                        {" " + translate.t("ChangedConversationStateToSolved")} {getMessageTimeString(message.createdDate)}
                    </div>
                </div>
            }

            {/**
             * Conversation paused message
             */}
            {message.messageTypeID == 6 &&                                                
                <div className="message-system-info">
                    <div className="message">   
                        {!message.operatorID && !message.visitorID &&
                            <span className='strong'>
                                {translate.t("System")}
                            </span> 
                        }                     
                        {!!message.operatorID && 
                            <span className='strong'>
                                {!!operatorListCache.getItemByID(message.operatorID)?.nameInChatbox ? operatorListCache.getItemByID(message.operatorID)?.nameInChatbox : operatorListCache.getItemByID(message.operatorID)?.name + " " + operatorListCache.getItemByID(message.operatorID)?.surname}
                            </span> 
                        }
                        {!!message.visitorID && 
                            <span className='strong'>
                                {(!!conversationCache.getVisitor().name ? conversationCache.getVisitor().name : conversationCache.getVisitor().visitorID)}
                            </span> 
                        }
                        {" " + translate.t("ChangedConversationStateToDeffered")} {getMessageTimeString(message.createdDate)}
                    </div>
                </div>
            }

            
 
             {/**
              * rating message
              */}
             {message.messageTypeID == 8 &&                                             
                 <div className={`message-item received`}> 
                    
                    <div className='message-avatar'>
                        <AvatarVisitor showStatus={false} visitorData={conversationCache.getVisitor()} statusClass="border white-border"/>                        
                    </div>

                     <div className='message-content'>                         
                         <div className='message-header'>
                             <div className='name'>
                                {!!visitorData.name ? visitorData.name : visitorData.visitorID}
                             </div>
                             <div className='date-time' title={moment.utc(message.createdDate).local().format("L LT")}>
                                 {getMessageTimeString(message.createdDate,true)}
                             </div>
                         </div>
                         <div className="rating">
                                {message.ratedValue == 1 &&
                                    <div className="rating-item no-hover">
                                        <i className="las la-grin-hearts icon"></i>
                                        <div className="text">{translate.t("RatingValueGreat")}</div>
                                    </div>
                                }
                                {message.ratedValue == 2 &&
                                    <div className="rating-item no-hover">
                                        <i className="las la-thumbs-up icon"></i>
                                        <div className="text">{translate.t("RatingValueNeutral")}</div>
                                    </div>
                                }
                                {message.ratedValue == 3 &&
                                    <div className="rating-item no-hover">
                                        <i className="las la-poop icon"></i>
                                        <div className="text">{translate.t("RatingValueBad")}</div>
                                    </div>
                                }
                            </div>
                         
                     </div>
 
                 </div>
                 
             }
                 
        </>
    );

}

export default Message;