
const prefix = 'conversation';
export const EVENTS_CONVERSATION = {
  CONVERSATION_STARTED: `${prefix}.conversation_started`,
  CONVERSATION_CLOSED: `${prefix}.conversation_closed`,
  CONVERSATION_PAUSED: `${prefix}.conversation_paused`,
  CONVERSATION_CHANGED: `${prefix}.conversation_changed`,
  CONVERSATION_MOVE_TO_POSTPONED: `${prefix}.move_to_postponed`,
  
}; 
