import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import translate from "../Modules/Translations/index";
import useOnClickOutside from "use-onclickoutside";
import Dashboard from "../Modules/Dashboard/Components/Dashboard";
import { useHistory } from "react-router-dom";
import { operatorCache, operatorVar } from "../Library/Cache/operatorCache";
import operatorEmiters from "../Modules/Websocket/Emiters/operatorEmiters";
import AvatarOperator from "../GlobalComponents/AvatarOperator";
import { conversationListCache, conversationListVar } from "../Library/Cache/conversationListCache";

const DashboardLayout = (props) => {
    const client = useApolloClient();
    const history = useHistory();
    let { path, url } = useRouteMatch();

    const operatorData = useReactiveVar(operatorVar);
    const [showSideBar, setShowSideBar] = useState(true);

    const conversationList = useReactiveVar(conversationListVar);
    const [unreadConversationSum, setUnreadConversationSum] = useState(0);

    if (!operatorData || !operatorData.operatorID) {
        history.push("/login");
    }

    const statusSelectionRef = useRef();
    const [statusSelectionOpen, setStatusSelectionOpen] = useState(false);
    useOnClickOutside(statusSelectionRef, () => setStatusSelectionOpen(false));

    const accountMenuRef = useRef();
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    useOnClickOutside(accountMenuRef, () => setAccountMenuOpen(false));

    const mainMenuRef = useRef();
    const [mainMenuOpen, setMainMenuOpen] = useState(false);
    useOnClickOutside(mainMenuRef, () => setMainMenuOpen(false));

    useEffect(() => {
        setUnreadConversationSum(conversationListCache.getUnreadConversationCountSum());
    }, [conversationList.conversations]);

    const logout = () => {
        client.resetStore();
        localStorage.removeItem("token");
        window.location = "/login";
    };

    const statusSelected = (status) => {
        operatorCache.set({ ...operatorCache.get(), status });
        operatorEmiters.changeStatus(operatorData.operatorID, operatorData.accountID, status);
        setStatusSelectionOpen(false);
    };

    return (
        <>
            {!!operatorData && !!operatorData.operatorID && (
                <div className="d-flex flex-col h100p">
                    <div className="top-panel">
                        <img src="/logo.svg" alt="widchat" className="logo" />
                        <img src="/logo_symbols.svg" alt="widchat" className="logo symbols" />
                        <div className="menu-wrapper">
                            <div
                                className="mobile-menu-link"
                                onClick={() => {
                                    console.log("aaa");
                                    setMainMenuOpen(true);
                                }}
                            >
                                <div className="text">{translate.t("Menu")}</div>
                                <i className="icon las la-angle-down"></i>
                            </div>
                            <div className={`main-menu ${mainMenuOpen ? "show" : ""}`} ref={mainMenuRef}>
                                <div className="arrow"></div>
                                <div className="menu">
                                    <NavLink to={`${url}/dashboard/people`} activeClassName="selected" className="menu-item">
                                        <i className="las la-users icon"></i>
                                        {translate.t("People")}
                                    </NavLink>
                                    <NavLink to={`${url}/dashboard/conversations`} activeClassName="selected" className="menu-item">
                                        <i className="las la-comments icon"></i>
                                        {translate.t("Conversations")}
                                        {unreadConversationSum > 0 && <span className="badge">{unreadConversationSum}</span>}
                                    </NavLink>
                                    <NavLink to={`${url}/dashboard/tasks`} activeClassName="selected" className="menu-item">
                                        <i className="las la-list icon"></i>
                                        {translate.t("Tasks")}
                                    </NavLink>
                                    <NavLink to={`${url}/dashboard/overview`} activeClassName="selected" className="menu-item">
                                        <i className="las la-tachometer-alt icon"></i>
                                        {translate.t("Overview")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown notifications">
                            <div className="circle blue">
                                <div className="text">47</div>
                            </div>
                            <div className="text blue">{translate.t("Notifications")}</div>
                        </div>
                        <div className="dropdown account" onClick={() => setAccountMenuOpen(true)} ref={accountMenuRef}>
                            <div className={"circle status " + operatorData.status}>
                                {operatorData.status == "online" && <i className="las la-play-circle icon"></i>}
                                {operatorData.status == "offline" && <i className="las la-stop-circle icon"></i>}
                                {operatorData.status == "paused" && <i className="las la-pause-circle icon"></i>}
                            </div>
                            <div className={"circle "}>
                                <AvatarOperator operatorData={operatorData} statusClass="border white-border" />
                            </div>
                            <div className="text">
                                {operatorData.name} <span className="surname">{operatorData.surname}</span>
                            </div>
                            <i className="icon las la-angle-down"></i>
                            <div className={"dropdown-content " + (accountMenuOpen ? "show" : "")}>
                                <div className="arrow"></div>
                                <NavLink to={`${url}/dashboard/settings/my-profile`} className="dropdown-item">
                                    {translate.t("Settings")}
                                </NavLink>
                                <div className="dropdown-item" onClick={(e) => logout()}>
                                    {translate.t("LogOut")}
                                </div>

                                <div className="status-block">
                                    <div className="title">{translate.t("Status")}</div>
                                    <div className="status-list">
                                        <div className="status-item" onClick={() => statusSelected("online")}>
                                            <div className={"status-circle online " + (operatorData.status == "online" ? "selected" : "")}></div>
                                            <div className="status-item-text">{translate.t("IamOnline")}</div>
                                        </div>
                                        <div className="status-item" onClick={() => statusSelected("paused")}>
                                            <div className={"status-circle paused " + (operatorData.status == "paused" ? "selected" : "")}></div>
                                            <div className="status-item-text">{translate.t("IamPaused")}</div>
                                        </div>
                                        <div className="status-item" onClick={() => statusSelected("offline")}>
                                            <div className={"status-circle offline " + (operatorData.status == "offline" ? "selected" : "")}></div>
                                            <div className="status-item-text">{translate.t("IamOffline")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-content">
                        <Dashboard />
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardLayout;
