/**
 *  komponenta pro štítky pro produkty
 */
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ConversationActiveList from './ConversationActiveList';
import translate from '../../Translations/index';
import { conversationFiltersCache, conversationFiltersVar } from '../../../Library/Cache/conversationFiltersCache';
import ConversationSearchList from './ConversationSearchList';
import { useReactiveVar } from '@apollo/client';
import SearchInput from '../../../GlobalComponents/SearchInput';


const ConversationLists = (props) => {
        
    const conversationFilters      = useReactiveVar(conversationFiltersVar);

    
    return(
        <div className='conversation-list-panel'> 
            {conversationFilters.filters.conversationStateID == 2 && conversationFilters.filters.searchQuery == "" ?
                <ConversationActiveList />  
                :
                <ConversationSearchList />
            }          
        </div> 
    );

}

export default ConversationLists;