import React, { useEffect, useState } from "react";

const Switcher = (props) => {
    const [active, setActive] = useState(!!props.active ? !!props.active : false);

    useEffect(() => {
        setActive(!!props.active ? !!props.active : false);
    }, [props.active]);

    const switchOnOff = () => {
        if (!props.waitForChange) {
            setActive(!active);
        }
        props.onChange && props.onChange(!active);
    };

    return (
        <div className={`switcher ${!!props.className ? props.className : ""} ${!!active ? "active" : ""}`} onClick={() => switchOnOff()}>
            <div className="circle"></div>
        </div>
    );
};

export default Switcher;
