import React,{ Component,useState,useRef,useCallback } from 'react';
import translate from '../Modules/Translations/index';
import getCroppedImg from '../Library/cropImage';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop'
import {ReactComponent as Spinner} from '../Public/Images/spinner.svg';



const AvatarPicker = (props) => {
  const fileInputRef                  = useRef(null);
  const [modalOpen,setModalOpen]      = useState(false);
  const [newPhoto,setNewPhoto]        = useState(null);
  const [croppedPhoto,setCroppedPhoto]= useState();

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewPhoto(event.target.files[0]);
    }
  }

  const openFileBrowser = () => {
    fileInputRef.current.click();
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])


  const savePhoto = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        newPhoto,
        croppedAreaPixels
      )
      
      setNewPhoto(null);
      setModalOpen(false);

      props.onSelected && props.onSelected(croppedImage);

    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])
  
  const cancelAction = () => { 
    setNewPhoto(null);   
    setModalOpen(false);
  }

  return (   
    <>
      <div className={`avatar-picker ${!!props.className ? props.className : ""}`} onClick={() => !props.loading && setModalOpen(true)}>
        <div className='cover'></div>

        {!props.loading ?
          <>      
          {!!props.photo ?
            <img src={props.photo} className="photo" alt="preview new image" />
            :
            <i className="las la-user-alt default-photo"></i>
          }
          </>
          :
          <Spinner />
        }

      </div>
      <Modal
          className="avatar-picker-modal modal center"
          closeTimeoutMS={10}
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
            >
              <div className="modal-content">
                <button type="button" className="close" onClick={() => setModalOpen(false)}>
                  <i className="las la-times icon"></i>
                </button>
                {!!props.modalTitle &&
                  <div className="modal-header">
                    <h4 className="modal-title">{props.modalTitle}</h4>
                  </div>
                }

                <div className="modal-body">
                  <div className='photo-block'>
                    {!!newPhoto ?
                      <div className='cropper-block'>
                        <Cropper
                          image={URL.createObjectURL(newPhoto)}
                          crop={crop}
                          zoom={zoom}
                          aspect={1 / 1}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                          cropShape="round"
                          showGrid={false}
                        />
                      </div>
                      :
                      <>          
                        {!!props.photo ?
                          <img src={props.photo} className="photo" alt="preview new image"   onClick={() => openFileBrowser()}/>
                          :
                          <div className='default-photo'  onClick={() => openFileBrowser()}>
                            <i className="las la-user-alt icon"></i>
                          </div>
                        }
                      </>
                    }
                  </div>
                  <p className='file-description'><span className='link' onClick={() => openFileBrowser()}>{translate.t("ChoosePhoto")}</span> {translate.t("OrChangePhotoByClickingOnImage")}</p>
                  <p className='file-description'>{translate.t("AvatarPickerFIleFormatDesc")}</p>
                </div>

                <div className="modal-footer"> 
                  <button type="button" className="btn blue" disabled={!newPhoto} onClick={() => savePhoto()}>{translate.t("Save")}</button>
                    <div className='link ml-auto' onClick={() => cancelAction()}>{translate.t("CancelAction")}</div>                    
                </div>
                
                <input type="file" hidden onChange={onImageChange} className="avatar-file-input" ref={fileInputRef} />
              </div>
      </Modal>
    </>
  );
  
}


export default AvatarPicker;
