import React,{ Component } from 'react';
import { OPERATOR_IMAGE_URL } from '../Config/urls';

const AvatarOperator = (props) => {

    const style = {      
      ...(props.width 	      && { width: props.width }),
      ...(props.height 	      && { height: props.height }),
    }
    
    return (
      <>
        {props.operatorData ?
          <div className={`avatar ${(!!props.size ? props.size : "")} ${(!!props.className ? props.className : "")}`} style={style}>
            <div className='avatar-content'>
                <div className='colored-avatar' style={{backgroundColor:props.operatorData.color}}>
                  {props.operatorData.img ?
                    <img src={process.env.REACT_APP_SERVER_URL + "/" + OPERATOR_IMAGE_URL + "/" + props.operatorData.operatorID + "/small_" + props.operatorData.img} alt="operator_avater" />
                    :
                    <div className='text' style={{...(props.fontSize 	&& { fontSize: props.fontSize })}}>{props.operatorData.shortcut}</div>
                  }
                </div>
            </div>
            {props.showStatus !== false &&
              <div className={`status ${props.operatorData.status} ${!!props.statusClass ? props.statusClass : ""}`}></div>
            }
          </div>
          :
          null
        }
      </>
    );
  
}


export default AvatarOperator;
