import { EVENTS_OPERATOR } from "../Library/operatorEvents";
import { getApolloClient } from "../../../Library/apolloClient";
import { operatorCache, operatorVar } from "../../../Library/Cache/operatorCache";
import { conversationCache } from "../../../Library/Cache/conversationCache";
import { conversationListCache } from "../../../Library/Cache/conversationListCache";
import { setConversationMessagesRead } from "../../../Library/dashboard";
import { operatorListCache } from "../../../Library/Cache/operatorListCache";

  const registerOperatorHandlers = (socket) => {

      const client = getApolloClient();

      const connected = () => {          
        const operatorData 									= operatorVar();
        
        socket.emit(EVENTS_OPERATOR.CONNECTED,{
          operatorID:operatorData.operatorID,
          accountID:operatorData.accountID,
          status:operatorData.status
        });

      }     
  
    /**
     * Operator joined conversation
     * @param {Object} data - websocket data 
     */
     const conversationJoined = (data) => {      
      const conversationData  = conversationCache.get();
      const operatorData      = operatorCache.get();
      if(conversationData.conversationID == data.conversationID){
        conversationCache.addOperatorItemIfNotExist(data.operatorData);

        //if message is from same operator who joined the conversation, mark messages as read
        if(operatorData.operatorID == data.operatorData.operatorID){
          setConversationMessagesRead(data.conversationID);
        }
      }
      conversationListCache.addOperatorItemIfNotExist(data.conversationID,data.operatorData);
    } 

    /**
     * Operator leaved conversation
     * @param {Object} data - websocket data 
     */
    const conversationLeaved = (data) => {      
      const conversationData = conversationCache.get();
      if(conversationData.conversationID == data.conversationID){
        conversationCache.removeOperatorItem(data.operatorID);
      }
      conversationListCache.removeOperatorItem(data.conversationID,data.operatorID);
    }   

    /**
     * Operator typing start/stop
     * @param {Object} data - websocket data 
     */
     const typing = (data) => {   
      const conversationData = conversationCache.get();
      if(conversationData.conversationID == data.conversationID){
        if(data.isTyping){
          conversationCache.addTypingOperator(data.operatorID);
        }else{
          conversationCache.removeTypingOperator(data.operatorID);
        }
      }
      
    } 

    /**
     * Operator changed status
     * @param {Object} data - websocket data 
     */
    const statusChanged = (data) => {   
      //TO-DO - zrušit setTimeout až (pokud) se udělá odložené odhlášení operátora stejně jako u visitora
      const operatorData      = operatorCache.get();
      setTimeout(() => {       
        if(data.operatorID == operatorData.operatorID){ 
          operatorCache.set({...operatorCache.get(),status:data.status});  
        }
        operatorListCache.editItem(data.operatorID,{status:data.status});     
        conversationListCache.editOperator(data.operatorID,{status:data.status});
        conversationCache.editOperator(data.operatorID,{status:data.status});
      }, 100);
    } 
    
  
    socket.on("connect", connected);
    socket.on(EVENTS_OPERATOR.CONVERSATION_JOINED,conversationJoined);
    socket.on(EVENTS_OPERATOR.CONVERSATION_LEAVED,conversationLeaved);
    socket.on(EVENTS_OPERATOR.TYPING,typing);
    socket.on(EVENTS_OPERATOR.STATUS_CHANGED,statusChanged);

  }

export default registerOperatorHandlers;