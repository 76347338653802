import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Checkbox from '../../../GlobalComponents/Checkbox';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import { operatorListCache } from '../../../Library/Cache/operatorListCache';

import translate from '../../Translations/index';
import noteEmiters from '../../Websocket/Emiters/noteEmiters';
import TextareaAutosize from 'react-textarea-autosize';


const NoteItem = (props) => {
    const {note,add}                            = props;
    const [edit,setEdit]                        = useState(false);
    const [showDeleteModal,setShowDeleteModal]  = useState(false);
    const [inputVal,setInputVal]                = useState(note.text);
    const inputRef                              = useRef();
    
    const editClicked = () => {
        setEdit(true);
    }

    useEffect(() => {
        if(edit || add){
            inputRef.current.focus();
        }
    },[edit,add]);

    const storno = () => {
        setInputVal(note.text);
        setEdit(false);
        
        add && !!props.onStorno && props.onStorno()
    }
    const save = () => {
        if(add){
            noteEmiters.add({
                accountID:operatorCache.get().accountID,
                operatorAdd:operatorCache.get().operatorID,
                conversationID:props.conversationID,
                text:inputVal
            });
            !!props.onSaved && props.onSaved();
        }else{
            noteEmiters.edit({
                noteID:note.noteID,
                text:inputVal
            });
            //setInputVal(note.text);
            setEdit(false);
        }
    }

    const deleteNote = (status) => {
        if(status){            
            noteEmiters.delete(note.noteID);
        }
        setShowDeleteModal(false);
    }
	const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            if (!e.shiftKey) {         
                save();
            }
        }
	}

    const operatorAdded = operatorListCache.getItemByID(note.operatorAdd);
    return(    
        <div className='note-item'>  
            {!add && !edit &&
                <>         
                    <div className='note-content' data-tip-disable={!operatorAdded} data-tip={operatorAdded ? translate.t("AddedBy") + ": " + operatorAdded.name + " " + operatorAdded.surname + " (" + moment.utc(note.dateAdd).local().format("L LT") + ")" : ""} data-for="left-solid-tooltip"><i className="las la-sticky-note icon"></i>{note.text}</div>
                    {!props.disabled &&
                        <div className='controls'>
                            <i className="las la-pencil-alt icon edit" onClick={() => editClicked()}></i>
                            <i className="las la-trash-alt icon delete" onClick={() => setShowDeleteModal(true)}></i>
                        </div>
                    }
                </>
            }
            {(!!add || !!edit) &&
                <div className='add-edit-block'>
                    <div className='form-group'>
                        <label>{!!add ? translate.t("NewNote") : translate.t("EditNote")}</label>
                        <div className='input-group'>                            
                            <TextareaAutosize  
                                minRows={1}  
                                maxRows={4}
                                ref={inputRef} 
                                value={inputVal} 
                                onChange={(e) => setInputVal(e.target.value)}  
                                onKeyPress={(e) => onKeyPress(e)}
                                className="textarea"
                            />
                        </div>
                    </div>
                    <div className='save'>
                        <div className='btn blue small' onClick={() => save()}>{translate.t("Save")}</div>
                        <div className='link' onClick={() => storno()}>{translate.t("Storno")}</div>
                    </div>
                </div>
            }
            {showDeleteModal &&
                <ModalNotification 
                    yesNo={true} 
                    content={translate.t("AreYouSureYouWantToDeleteNote",{noteName:note.text})} 
                    title={translate.t("DeleteNote")} 
                    rejectBtnAsLink={true} 
                    confirmBtnText={translate.t("Delete")} 
                    rejectBtnText={translate.t("CancelAction")} 
                    callback={(status) => deleteNote(status)} 
                />
            }

       </div>
    );

}

export default NoteItem;