/**
 *  komponenta pro štítky pro produkty
 */
import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import { conversationFiltersVar } from "../../../Library/Cache/conversationFiltersCache";
import ConversationListItem from "./ConversationListItem";
import { conversationSearchListCache, conversationSearchListVar } from "../../../Library/Cache/conversationSearchListCache";
import { Scrollbars } from "react-custom-scrollbars-2";
import translate from "../../../Modules/Translations/index";

const ConversationSearchList = (props) => {
    const [loading, setLoading] = useState(true);
    const conversationSearchList = useReactiveVar(conversationSearchListVar);
    const conversationFilters = useReactiveVar(conversationFiltersVar);

    useEffect(() => {
        setLoading(true);
        conversationSearchListCache.loadConversations().finally(() => {
            setLoading(false);
        });
    }, [conversationFilters.filters, conversationFilters.order]);

    const onScroll = (event) => {
        if (event.scrollTop === event.scrollHeight - event.clientHeight) {
            conversationSearchListCache.fetchMore();
        }
    };
    return (
        <div className="list-content">
            <Scrollbars
                renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
                renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden", marginBottom: "0px" }} />}
                onScrollFrame={(event) => onScroll(event)}
            >
                {!loading ? (
                    <>
                        {!!conversationSearchList && !!conversationSearchList.conversations && !!conversationSearchList.conversations.length > 0 ? (
                            <>
                                {conversationSearchList.conversations.map((item, index) => (
                                    <ConversationListItem conversationItem={item} key={index} />
                                ))}
                            </>
                        ) : (
                            <div className="info">
                                <i className="las la-comment-slash icon"></i>
                                {conversationFilters.filters.conversationStateID == 3
                                    ? translate.t("YouDontHaveAnyPostponedConversations")
                                    : translate.t("YouDontHaveAnyResolvedConversations")}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="loading-content">
                        <Loading displayText={false} />
                    </div>
                )}
            </Scrollbars>
        </div>
    );
};

export default ConversationSearchList;
