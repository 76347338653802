import React, { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";

// routování url adres dsf dsfg dfg dg df gdfg dfg df gdfg dfg rfasdf asdf asdf asd fassad
import Routes from "./Routes/index.js";

//resolvery a schema pro lokalní uložení
import WithTranslation from "./Modules/Translations/withTranslation";
import { OPERATOR_CONTEXT } from "./GlobalQueries/globalQueries";
import Loading from "./GlobalComponents/Loading";
import { BrowserRouter } from "react-router-dom";
import { getApolloClient } from "./Library/apolloClient.js";
import { ReactNotifications } from "react-notifications-component";
import Lightbox from "./GlobalComponents/Lightbox/Lightbox.js";
import { lightboxRef } from "./GlobalComponents/Lightbox/LightboxService.js";
import { operatorCache } from "./Library/Cache/operatorCache.js";

const client = getApolloClient();
const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInitialData();
    }, []);

    const getInitialData = async () => {
        const data = await client.query({
            query: OPERATOR_CONTEXT,
            errorPolicy: "all",
            fetchPolicy: "network-only",
        });
        if (!data || !data.data || !data.data.operatorContext) {
            localStorage.removeItem("token");
        } else {
            operatorCache.set({ ...data.data.operatorContext, status: data.data.operatorContext.initialStatus });
        }

        //loads all fonts before display app content to prevent FOUT (Flash of Unstyled Text)
        document.fonts.load("12px ABC Normal Medium").then(() => {
            document.fonts.load("600 12px ABC Normal").then(() => {
                document.fonts.load("12px ABC Normal").then(() => {
                    document.fonts.load("900 12px Line Awesome Free").then(() => {
                        setLoading(false);
                    });
                });
            });
        });
    };

    return (
        <WithTranslation>
            {!loading ? (
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </ApolloProvider>
            ) : (
                <div className="d-flex align-items-center h-100">
                    <Loading displayText={false} />
                </div>
            )}
            <ReactNotifications />
            <Lightbox ref={lightboxRef} />
        </WithTranslation>
    );
};

export default App;
