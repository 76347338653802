import gql from 'graphql-tag';

export const GET_OPERATOR = gql`
    query GetOperator($operatorID:ID){
        operatorByHashID(operatorID:$operatorID){
            operatorID
            accountID
            name
            surname
            nameInChatbox
            email
            initialStatus
            img
            shortcut
            color
            status
            roleID
        }
    }
`;
export const GET_OPERATOR_ACCOUNT = gql`
    query OperatorAccount($operatorID:ID,$accountID:ID){
        operatorAccount(operatorID:$operatorID,accountID:$accountID){
            accountID
            operatorID
            status
            email
            name
            surname
            company
            roleID
            lastSelected
            operator{
                name
                surname
                nameInChatbox
                email
                initialStatus
                img
                shortcut
                color
                roleID
            }
        }
    }
`;

export const EDIT_OPERATOR_DATA = gql`
    mutation EditOperatorData($editOperatorData:EditOperatorData) {
        editOperator(editOperatorData:$editOperatorData){
            operatorID
        }
    }
`; 
export const DELETE_MY_OPERATOR_ACCOUNT = gql`
    mutation DeleteMyOperatorAccount($operatorID:ID,$accountID:ID) {
        deleteMyOperatorAccount(operatorID:$operatorID,accountID:$accountID)
    }
`; 
export const CHANGE_OPERATOR_PASSWORD = gql`
    mutation ChangeOperatorPassword($operatorID:ID,$oldPassword:String, $newPassword:String, $lang:String) {
        changeOperatorPassword(operatorID:$operatorID,oldPassword:$oldPassword,newPassword:$newPassword,lang:$lang)
    }
`; 
export const EDIT_OPERATOR_ACCOUNT_DATA = gql`
    mutation EditOperatorAccountData($accountID:ID,$operatorID:ID,$editOperatorAccountData:EditOperatorAccountData) {
        editOperatorAccount(accountID:$accountID,operatorID:$operatorID,editOperatorAccountData:$editOperatorAccountData){
            operatorID
        }
    }
`; 

export const CHANGE_OPERATOR_PHOTO = gql`
    mutation ChangeOperatorPhoto($operatorID:ID, $photo: Upload) {
        changeOperatorPhoto(operatorID:$operatorID, photo:$photo){
            img
        }
    }
`; 
