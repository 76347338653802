
import React,{ Component, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {lightboxRef} from './LightboxService';
import translate from '../../Modules/Translations/index';

const Lightbox = (props,ref) => {


  const [showLightBox,setShowLightbox]  = useState(false);
  const [images,setImages]              = useState([]);
  const [index,setIndex]                = useState(0);
  
    useImperativeHandle(ref, () => ({
      showLightbox,
      hideLightbox
    }));
    
    const showLightbox = (files) => {
      setImages(files);
      setShowLightbox(true);
    }
    const hideLightbox = () => {
      setImages([]);
      setShowLightbox(false);
    }

    useEffect(() => {
      
      function onKeyUp(e) {
          if (e.key === "Escape") {
            setShowLightbox(false);
          }
      }

      document.addEventListener('keyup', onKeyUp);                
      return () => document.removeEventListener("keyup", onKeyUp);

    },[]);
    
    return (   
      <>
        {showLightBox &&
          <div className={`lightbox `}>
              <div className={`lightbox-box `}>    
                <div className='close' onClick={() => setShowLightbox(false)}><i className="las la-times icon"></i></div>            
                <div className={`lightbox-content `}>
                  {images.length > 0 &&
                    <img className="lightbox-img" src={!!images[index] && images[index]}></img>
                  }
                </div>
                <div className='close-info'><span className='bold'>{translate.t("CloseInfoText1")}</span> {translate.t("CloseInfoText2")}</div>
              </div>
          </div>
        }
      </>
    );
  
}


export default forwardRef(Lightbox);
