/*
    Formulář pro přihlášení se do adminu
*/
import React, { useState } from "react";
import { CHANGE_OPERATOR_PASSWORD_BY_RESET_HASH, RESET_OPERATOR_PASSWORD_HASH_DATA } from "../Queries/changePassword";
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation, useQuery } from "@apollo/client";
import { addNotification } from "../../../Library/helper";
import translate from "../../Translations/index";
import loadingIcon from "../../../Public/Images/loading_blue.svg";
import { Link, useParams } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import Loading from "../../../GlobalComponents/Loading";

const SetNewPassword = (props) => {
    let { resetPasswordHash } = useParams();

    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const { data, loading } = useQuery(RESET_OPERATOR_PASSWORD_HASH_DATA, {
        variables: {
            resetPasswordHash,
        },
        onCompleted(data) {},
        onError(err) {
            console.log(err);
            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });

    const [resetPassword, { loading: resetPwdLoading }] = useMutation(CHANGE_OPERATOR_PASSWORD_BY_RESET_HASH, {
        onCompleted(data) {
            setNewPassword("");
            setNewPassword2("");
            addNotification("success", translate.t("PasswordSuccessfullyChanged"), translate.t("Message"));
        },
        onError(err) {
            console.log(err);
            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });

    const send = () => {
        if (newPassword !== "" && newPassword2 !== "") {
            if (newPassword === newPassword2) {
                resetPassword({
                    variables: {
                        resetPasswordHash: resetPasswordHash,
                        newPassword,
                        lang: translate.locale,
                    },
                });
            } else {
                addNotification("danger", translate.t("PasswordsNotEqual"), translate.t("Warning"));
            }
        } else {
            addNotification("danger", translate.t("YouHaveNotFilledRequiredFields"), translate.t("Warning"));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            send();
        }
    };

    return (
        <main className="d-flex w-100 h-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2">{translate.t("SetNewPassword")}</h1>
                                <p className="lead">{translate.t("EnterYourNewPassword")}</p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-4">
                                        {!loading ? (
                                            <>
                                                {!!data.resetOperatorPasswordHashData ? (
                                                    <form>
                                                        <div className="mb-3">
                                                            <label className="form-label">{translate.t("Password")}</label>
                                                            <input
                                                                onKeyPress={handleKeyPress}
                                                                onChange={(event) => setNewPassword(event.target.value)}
                                                                value={newPassword}
                                                                type="password"
                                                                className="form-control form-control-lg"
                                                                placeholder={translate.t("EnterNewPassword")}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">{translate.t("RepeatPassword")}</label>
                                                            <input
                                                                onKeyPress={handleKeyPress}
                                                                onChange={(event) => setNewPassword2(event.target.value)}
                                                                value={newPassword2}
                                                                type="password"
                                                                className="form-control form-control-lg"
                                                                placeholder={translate.t("RepeatPassword")}
                                                            />
                                                        </div>
                                                        <div className="text-center mt-3">
                                                            <button
                                                                type="button"
                                                                disabled={resetPwdLoading}
                                                                className="btn btn-lg btn-primary"
                                                                onClick={() => send()}
                                                            >
                                                                {translate.t("Send")}
                                                                {resetPwdLoading && <img alt="loading icon" src={loadingIcon} className="loading" />}
                                                            </button>
                                                        </div>
                                                    </form>
                                                ) : (
                                                    <div className="text-center">{translate.t("PasswordResetHashNotValid")}</div>
                                                )}
                                            </>
                                        ) : (
                                            <Loading displayText={false} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                {translate.t("BackTo")} <Link to={"/login"}>{translate.t("SignInbackLink")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactNotifications />
        </main>
    );
};

export default SetNewPassword;
