import React from "react";
import { NavLink, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Loading from "../../../../GlobalComponents/Loading";
import { operatorCache } from "../../../../Library/Cache/operatorCache";
import { useAdminRights } from "../../../../Library/helper";
import translate from "../../../Translations/index";
import Shortcuts from "./Shortcuts";

const Automatization = (props) => {
    let { path, url } = useRouteMatch();

    const operatorData = operatorCache.get();
    const { loading: roleCheckLoading } = useAdminRights(operatorData?.roleID, ["1", "2"], "/account");

    return (
        <>
            {!roleCheckLoading ? (
                <>
                    <div className="page">
                        <div className="settings-title">
                            <h1>{translate.t("AutomatizationTitle")}</h1>
                        </div>
                        <div className="menu">
                            <NavLink to={`${url}/shortcuts`} activeClassName="selected" className="menu-item">
                                {translate.t("Shortcuts")}
                            </NavLink>
                        </div>

                        <Switch>
                            <Route exact path={`${path}`}>
                                <Redirect to={`${url}/shortcuts`} />
                            </Route>
                            <Route path={`${url}/shortcuts`}>
                                <Shortcuts />
                            </Route>
                        </Switch>
                    </div>
                </>
            ) : (
                <Loading displayText={false} />
            )}
        </>
    );
};

export default Automatization;
