
import translate from '../Modules/Translations/index';
import React,{ Component } from 'react';

class Loading extends Component {
	
  render() {

    let {displayText} = this.props;

    if(typeof displayText == "undefined"){
      displayText = true;
    }

    return (
    	<div className="whole-loading" style={{marginTop: !!this.props.marginTop ? this.props.marginTop : null}}>	
        <div className="loading"><div className="spinner"><div className="rect1"></div><div className="rect2"></div><div className="rect3"></div><div className="rect4"></div><div className="rect5"></div></div>{displayText && <div className="text">{translate.t("LoadingData")}</div>}</div>
    	</div>
    );
  }
  
}


export default Loading;
