import gql from "graphql-tag";

export const OPERATOR_CONTEXT = gql`
    {
        operatorContext {
            operatorID
            name
            surname
            nameInChatbox
            email
            img
            shortcut
            color
            initialStatus
        }
    }
`;
export const OPERATOR_LAST_SELECTED_ACCOUNT = gql`
    query OperatorLastSelectedAccount($operatorID: ID) {
        operatorLastSelectedAccount(operatorID: $operatorID) {
            accountID
            name
            surname
            company
        }
    }
`;
export const OPERATOR_ACCOUNT_DATA = gql`
    query OperatorAccountData($operatorID: ID, $accountID: ID) {
        operatorAccount(operatorID: $operatorID, accountID: $accountID) {
            accountID
            name
            surname
            company
            roleID
        }
        shortcuts: allOperatorShortcuts(accountID: $accountID, operatorID: $operatorID, filters: { active: 1 }) {
            shortcutID
            shortcut
            text
            active
        }
    }
`;

export const GET_INITIAL_DATA = gql`
    query AllOperators($accountID: ID) {
        allOperators(accountID: $accountID, withDeleted: true) {
            operatorID
            name
            surname
            nameInChatbox
            shortcut
            img
            color
        }
    }
`;
