import { makeVar } from '@apollo/client';
  
export const visitorListVar = makeVar([]);

export const visitorListCache = {
    get: () => {
        return visitorListVar();
    },
    set: (data) => {
        let newData = [];
        for(const item of data){
            newData.push({
                visitorID:item.visitorID,
                accountID:item.accountID,
                name:item.name,
                shortcut:item.shortcut,
                email:item.email,
                status:item.status,
                color:item.color,
                visitCount:item.visitCount,
                lastVisit:item.lastVisit,
                lastVisitEnd:item.lastVisitEnd,
                city:item.city,          
                countryCode:item.countryCode,
                countryName:item.countryName,
                continentCode:item.continentCode,
                continentName:item.continentName,
                latitude:item.latitude,
                longitude:item.longitude,
                pageTitle:item.pageTitle,
                url:item.url,
                referrer:item.referrer,
                osName:item.osName,
                osVersion:item.osVersion,
                browserName:item.browserName,
                browserVersion:item.browserVersion,
                isMobile:item.isMobile,
                isTablet:item.isTablet,
                ip:item.ip,
                visitedPages:item.visitedPages,
                conversation:item.conversation
            })
        }
        visitorListVar(newData);
    },
    addItem: (data) => {        
        visitorListVar([...visitorListVar(),{
            visitorID:data.visitorID,
            accountID:data.accountID,
            name:data.name,
            shortcut:data.shortcut,
            email:data.email,
            status:data.status,
            color:data.color,
            visitCount:data.visitCount,
            lastVisit:data.lastVisit,
            lastVisitEnd:data.lastVisitEnd,
            city:data.city,          
            countryCode:data.countryCode,
            countryName:data.countryName,
            continentCode:data.continentCode,
            continentName:data.continentName,
            latitude:data.latitude,
            longitude:data.longitude,
            pageTitle:data.pageTitle,
            url:data.url,
            referrer:data.referrer,
            osName:data.osName,
            osVersion:data.osVersion,
            browserName:data.browserName,
            browserVersion:data.browserVersion,
            isMobile:data.isMobile,
            isTablet:data.isTablet,
            ip:data.ip,
            visitedPages:data.visitedPages,
            conversation:data.conversation
        }]);
    },
    editItem: (visitorID,data) => {   
        let items = visitorListVar();
        for(const key in items){
            if(items[key].visitorID == visitorID){
                items[key] = { 
                    ...items[key],
                    ...(data.visitorID 		&& { visitorID: data.visitorID }),
                    ...(data.accountID 		&& { accountID: data.accountID }),
                    ...(data.name 		    && { name: data.name }),
                    ...(data.shortcut 		&& { shortcut: data.shortcut }),
                    ...(data.email 		    && { email: data.email }),
                    ...(data.status 		&& { status: data.status }),
                    ...(data.color 		    && { color: data.color }),
                    ...(data.visitCount 	&& { visitCount: data.visitCount }),
                    ...(data.lastVisit 		&& { lastVisit: data.lastVisit }),
                    ...(data.lastVisitEnd   && { lastVisitEnd: data.lastVisitEnd }),
                    ...(data.city 		    && { city: data.city }),
                    ...(data.countryCode 	&& { countryCode: data.countryCode }),
                    ...(data.countryName 	&& { countryName: data.countryName }),
                    ...(data.continentCode 	&& { continentCode: data.continentCode }),
                    ...(data.continentName 	&& { continentName: data.continentName }),
                    ...(data.latitude 		&& { latitude: data.latitude }),
                    ...(data.longitude 		&& { longitude: data.longitude }),
                    ...(data.pageTitle 		&& { pageTitle: data.pageTitle }),
                    ...(data.url 		    && { url: data.url }),                    
                    ...(data.referrer 		&& { referrer: data.referrer }),
                    ...(data.osName 		&& { osName: data.osName }),
                    ...(data.osVersion 		&& { osVersion: data.osVersion }),
                    ...(data.browserName 	&& { browserName: data.browserName }),
                    ...(data.browserVersion && { browserVersion: data.browserVersion }),
                    ...(data.isMobile 	    && { isMobile: data.isMobile }),
                    ...(data.isTablet 	    && { isTablet: data.isTablet }),
                    ...(data.ip             && { ip: data.ip }),
                    ...(data.visitedPages   && { visitedPages:data.visitedPages}),
                    ...(data.conversation   && { conversation:data.conversation})                    
                }
            }
        }     
        visitorListVar([...items]);
    },
    removeItemByVisitorID: (visitorID) => {        
        
        const allItems = visitorListVar();
        const newItems = allItems.filter(function (value, index) {
            return (value.visitorID != visitorID);
        });
        
        visitorListVar(newItems);
    }
}