import { getWebSocket } from "../websocket";
import { EVENTS_CONVERSATION } from "../Library/conversationEvents";
import { EVENTS_NOTE } from "../Library/noteEvents";

export default {

  add:(data) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        socket.emit(EVENTS_NOTE.ADDED,data,(data) => {
          console.log(data);
        });  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },
  edit:(data) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        socket.emit(EVENTS_NOTE.EDITED,data,(data) => {
          console.log(data);
        });  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },
  delete:(noteID) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let data = {
          noteID
        }
        socket.emit(EVENTS_NOTE.DELETED,data,(data) => {
          console.log(data);
        });  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },  
  
}
