import React,{ useState } from 'react';
import { useMutation} from '@apollo/client';
import translate from '../../../Translations/index';
import Modal from 'react-modal';
import { ADD_EDIT_CHATBOX } from '../../Queries/settings';
import { addNotification } from '../../../../Library/helper';
import { operatorCache } from '../../../../Library/Cache/operatorCache';


const AddChatbox = (props) => {
    const operatorData = operatorCache.get();

    const [formData,setFormData] = useState({   
        accountID: operatorData.accountID,       
        name:"",
        lang:translate.locale
    });
    
    const [addChatbox,{data:addOrderData,loading:addLoading,error:addError}] = useMutation(ADD_EDIT_CHATBOX,{
        onCompleted(data){            
			addNotification("success",translate.t("ChatboxSuccessfullyCreated"),translate.t("Success"));
            !!props.callback && props.callback(data.addEditChatbox);
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const addOrder = () => {
        if(formData.name != ""){
                addChatbox({
                    variables:{
                        addChatboxData:formData
                    }
                })
            
        }else{
			addNotification("danger",translate.t("YouHaveNotFilledName"),translate.t("Warning"));
        }
        
    }

    
    
    return(

        <Modal
            className="modal center md"
            closeTimeoutMS={10}
            isOpen={true}
            onRequestClose={() => props.callback(false)}
	    >
                <div className="modal-content">
                    <button type="button" className="close" onClick={() => props.callback(false)}>
                        <i className="las la-times icon"></i>
                    </button>
                <div className="modal-header">
                    <h4 className="modal-title">{translate.t("AddChatbox")}</h4>
                </div>
                <div className="modal-body">
                    
                    <div className='form-group'>
                        <label>{translate.t("ChatboxName")}</label>
                        <div className='input-group'>
                            <input type="text" value={formData.name} onChange={(e) => setFormData({...formData,name:e.target.value})} />
                        </div>
                    </div>
                </div>
                    <div className="modal-footer"> 
                        <button type="button" className="btn blue" onClick={() => addOrder()}>{translate.t("AddChatbox")}</button>
                        <div className='link ml-auto' onClick={() => props.callback(false)}>{translate.t("Cancel")}</div>                            
                    </div>
                </div>
	    </Modal>
    )

}

export default AddChatbox;