import React, { useEffect, useState } from 'react';
import {useQuery, useReactiveVar } from '@apollo/client';
import { calculateOnlineTime, checkAdminRights, getDeviceIcon, getOperatingSystemIcon } from '../../../Library/helper';
import Loading from '../../../GlobalComponents/Loading';
import { GET_VISITORS } from '../Queries/visitor';
import translate from '../../Translations/index';
import { visitorListCache, visitorListVar } from '../../../Library/Cache/visitorListCache';
import {operatorVar } from '../../../Library/Cache/operatorCache';
import AvatarVisitor from '../../../GlobalComponents/AvatarVisitor';
import ReactTooltip from 'react-tooltip';
import AvatarOperator from '../../../GlobalComponents/AvatarOperator';
import CustomTooltip from '../../../GlobalComponents/CustomTooltip';
import {useHistory} from 'react-router-dom';
import Dropdown from '../../../GlobalComponents/Dropdown';
import SearchInput from '../../../GlobalComponents/SearchInput';
import moment from 'moment';


const VisitorsActive = (props) => {
    
	const operatorData         = operatorVar();
    checkAdminRights(operatorData,["1","2","3"],props);
    const history               = useHistory();	

    const [seconds, setSeconds] = useState(0);
    const allVisitors           = useReactiveVar(visitorListVar);
    const [filters,setFilters]  = useState({
        searchQuery:"",
        orderBy:"lastOnline"
    });

    const {loading} = useQuery(GET_VISITORS,{
        fetchPolicy:'network-only',
        notifyOnNetworkStatusChange: true,
        variables:{
            filterData:{
                accountID:operatorData.accountID,
                status:'online',
            } 
        },
        onCompleted(data){
            visitorListCache.set(JSON.parse(JSON.stringify(data.visitors)));
        }
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 5);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [allVisitors])

    const pageCountTranslation = (pageCount) => {
        if(pageCount == 0){
            return translate.t('ActivityPageCount0');
        }else if(pageCount == 1){
            return translate.t('ActivityPageCount1');
        }else if(pageCount > 1 && pageCount < 5){
            return translate.t('ActivityPageCount2To4');
        }else if(pageCount >= 5){
            return translate.t('ActivityPageCount5');
        }
    }

    const openConversation = (e,conversationID) => {
        e.preventDefault();    
        history.push(`/account/${operatorData.accountID}/dashboard/conversations/${conversationID}`);        
    }

    const filterVisitors = (item) => {
        console.log(filters.searchQuery);
        console.log(item.name.includes(filters.searchQuery));

        if(filters.searchQuery == "") return true;
        if(item.name.toLowerCase().includes(filters.searchQuery.toLowerCase())) return true;
        if(item.visitorID.toLowerCase().includes(filters.searchQuery.toLowerCase())) return true;
        if(item.url.toLowerCase().includes(filters.searchQuery.toLowerCase()) ) return true;
        if(item.pageTitle.toLowerCase().includes(filters.searchQuery.toLowerCase())) return true;
        if(item.referrer.toLowerCase().includes(filters.searchQuery.toLowerCase())) return true;

        return false;
    }

    const sortVisitors = (a,b) => {
        if(filters.orderBy == "lastOnline"){
            return moment(a.lastVisit) < moment(b.lastVisit) ? 1 : -1;
        }
        if(filters.orderBy == "name"){
            return !!a.name ? a.name.localeCompare(!!b.name ? b.name : b.visitorID) : a.visitorID.localeCompare(!!b.name ? b.name : b.visitorID);
        }
    }

    return(
        <div>
            {!loading ?    
                <>   
                    {allVisitors?.length > 0 ?   
                        <>
                            <div className='controls'>                       
                                <div className='form-group search'>
                                    <label>{translate.t("SearchVisitor")}</label>
                                    <div className="input-group has-addon-left has-addon-right search-input">
                                        <span className='addon left'><i className="las la-search icon"></i></span>
                                        <SearchInput value={filters.searchQuery} onChange={(text) => {setFilters({...filters,searchQuery:text})} }/>
                                    </div>
                                </div>                 
                                <div className='form-group sort'>
                                    <label>{translate.t("SortVisitors")}</label>                            
                                    <Dropdown 
                                        className="filled"
                                        content={
                                            <>
                                                <i className="las la-sort icon"></i>
                                                {filters.orderBy == "lastOnline" && translate.t("SortByLastOnline")}
                                                {filters.orderBy == "name" && translate.t("SortByName")}
                                            </>
                                        } 			
                                        arrowLeft="auto"	
                                        arrowRight="1.1rem"	
                                        contentWidth="21rem"
                                        showDropdown={true}
                                        dropdownContent={
                                            <>
                                                <div className='dropdown-item' onClick={(e) => setFilters({...filters,orderBy:'lastOnline'})}>									
                                                    <div className='text'>{translate.t("SortByLastOnline")}</div>
                                                </div>
                                                <div className='dropdown-item' onClick={(e) => setFilters({...filters,orderBy:'name'})}>									
                                                    <div className='text'>{translate.t("SortByName")}</div>
                                                </div>
                                            </>
                                        }
                                    /> 
                                </div>
                            </div>     
                            <table className='full-width hoverable visitor-table online-list'>
                                <thead>
                                    <tr>
                                        <th className='left'>{translate.t('Visitor')}</th>
                                        <th className='left'>{translate.t('ActualUrl')}</th>
                                        <th className='text-center'>{translate.t('DeviceInfo')}</th>
                                        <th className='text-center'>{translate.t('Online')}</th>
                                        <th className='text-center'>{translate.t('Activity')}</th>
                                        <th className='text-center'>{translate.t('ConversationState')}</th>
                                        <th className='left'>{translate.t('Operators')}</th>
                                        <th className='right'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allVisitors.filter(filterVisitors).sort(sortVisitors).map((item,index) => (
                                        <tr key={index}>
                                            <td className='left'>
                                                <div className='d-flex flex-row align-items-center'>
                                                    <AvatarVisitor showStatus={false} visitorData={item} statusClass='border white-border'/>
                                                    <div className='avatar-name'>{item.name ? item.name : item.visitorID}</div>
                                                </div>
                                            </td>
                                            <td className='left'>
                                                <div className='actual-link'>
                                                    <CustomTooltip top='2rem' arrowLeft='2rem' content={
                                                        <>
                                                            <div className='title'>{translate.t('Activity')}</div>
                                                            <div className='row'>
                                                                <div className='label'>{translate.t('PageTitle')}:</div>
                                                                <div className='value'>{item.pageTitle}</div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='label'>{translate.t('Url')}:</div>
                                                                <div className='value'><a className='link' href={item.url} target='_blank'>{item.url}</a></div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='label'>{translate.t('CameFrom')}:</div>
                                                                <div className='value'><a className='link' href={item.referrer} target='_blank'>{item.referrer}</a></div>
                                                            </div>
                                                        </>
                                                    }>
                                                        <a href={item.url} target='_blank'>{item.pageTitle}</a>
                                                    </CustomTooltip>
                                                </div>
                                            </td>
                                            <td className='os-device'>
                                                {getDeviceIcon(item.isMobile,item.isTablet)} {getOperatingSystemIcon(item.osName,item.osVersion)}
                                            </td>
                                            <td>{calculateOnlineTime(item)}</td>
                                            <td>{!!item.visitedPages?.length ? item.visitedPages.length + ' ' + pageCountTranslation(item.visitedPages.length) : '0 ' + pageCountTranslation(item.visitedPages.length)}</td>
                                            <td className='conversation-state'>
                                                {item.conversation.conversationStateID != 1 &&
                                                    <div>
                                                        <div className={`state-content ${item.conversation.conversationStateID == 2 ? "active" : ""} ${item.conversation.conversationStateID == 3 ? "deffered" : ""} ${item.conversation.conversationStateID == 4 ? "solved" : ""} `}>
                                                            {item.conversation.conversationStateID == 2 && translate.t("ActiveConvState")}
                                                            {item.conversation.conversationStateID == 3 && translate.t("DefferedConvState")}
                                                            {item.conversation.conversationStateID == 4 && translate.t("SolvedConvState")}         
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                            <td className='left operators'>
                                                {item.conversation?.operators?.length > 0 ? 
                                                    <div className='operator-list'>
                                                        {item.conversation.operators.slice(0, 2).map((operatorItem,operatorIndex) => (
                                                            <AvatarOperator key={operatorIndex} showStatus={false} operatorData={operatorItem} statusClass="border white-border"/>							
                                                        ))}
                                                        {item.conversation.operators.length > 2 &&
                                                            <div className='avatar plus'><div className='avatar-content'>+{item.conversation.operators.length - 2}</div></div>
                                                        }
                                                    </div>
                                                    :
                                                    <span className='link' onClick={(e) => openConversation(e,item.conversation.conversationID)}>{translate.t("OpenConversation")}</span>
                                                }
                                            </td>
                                            <td className='list-menu'>
                                                <Dropdown 
                                                    content={<i className="las la-ellipsis-v icon"></i>} 			
                                                    arrowLeft="auto"	
                                                    arrowRight="1.1rem"	
                                                    contentWidth="21rem"
                                                    dropdownContent={
                                                        <div className='dropdown-item' onClick={(e) => openConversation(e,item.conversation.conversationID)}>									
                                                            <div className='text'>{translate.t("OpenConversation")}</div>
                                                        </div>
                                                    }
                                                />  
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                        :
                        <div className='no-visitor'>
                            <i className="las la-bone icon"></i>
                            <div className='title'>{translate.t('ItIsEmptyHere')}</div>     
                            <div className='title'>{translate.t('NobodyIsOnlineYet')}</div>                              
                        </div>
                    }
                </>
                :
                <Loading displayText={false} />
            }
            
            <ReactTooltip id="link-tooltip" class='tooltip-visitor-link' effect="solid" place="top"/>
            <ReactTooltip id="os-device-tooltip" effect="solid" place="top"/>
        </div>
    );

}

export default VisitorsActive;