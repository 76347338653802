import { getWebSocket } from "../websocket";
import { EVENTS_OPERATOR } from "../Library/operatorEvents";
import translate from '../../Translations/index';
import { addNotification } from "../../../Library/helper";

export default {

  changeStatus:(operatorID,accountID,status) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let data = {
          operatorID,
          accountID,
          status,
        }
        socket.emit(EVENTS_OPERATOR.STATUS_CHANGED,data,(data) => {
          console.log(data);
        });  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },
  
  /**
   * Joins operator to selected conversation
   * 
   * @param {String} conversationID 
   * @param {String} operatorData 
   * @returns 
   */
   joinConversation:(conversationID,operatorData) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let data = {
          conversationID,
          operatorData,
          lang:translate.locale
        }
        socket.emit(EVENTS_OPERATOR.CONVERSATION_JOINED,data,(response) => {
          console.log(response);
          if(response.status == "error"){
            addNotification("danger",response.message,translate.t("Error"));
          }
        });  


        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },

  /**
   * Leave operator from selected conversation
   * 
   * @param {String} conversationID 
   * @param {String} operatorID 
   * @returns 
   */
   leaveConversation:(conversationID,operatorID) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let data = {
          conversationID,
          operatorID,
        }
        socket.emit(EVENTS_OPERATOR.CONVERSATION_LEAVED,data);  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },  

  /**
   * Send info about operator typing 
   * 
   * @param {String} visitorID 
   * @param {String} operatorID 
   * @param {Boolean} isTyping 
   * @returns 
   */
  typing:(conversationID,operatorID,isTyping) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let typingData = {
          conversationID,
          operatorID,
          isTyping,
        }
        
        socket.emit(EVENTS_OPERATOR.TYPING,typingData,(data) => {});  

        resolve();
          
      } catch (error) {
        reject(error);
      }

    });   
  },
}
