import React from "react";
import ReactDOM from "react-dom/client";

import "./Public/Css/znackarnaFW.css";
import "./Public/Css/main.css";
import "./Public/Css/xl.css";
import "./Public/Css/lg.css";
import "./Public/Css/md.css";
import "./Public/Css/sm.css";
import "./Public/Css/xs.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
