import gql from "graphql-tag";

export const GET_OPERATORS_FOR_SETTING = gql`
    query OperatorsForSettings($accountID: ID) {
        allOperators(accountID: $accountID) {
            operatorID
            accountID
            name
            surname
            nameInChatbox
            email
            initialStatus
            img
            shortcut
            color
            status
            roleID
            role {
                name
            }
        }
    }
`;
