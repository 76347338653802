import React,{ Component } from 'react';
import { OPERATOR_IMAGE_URL } from '../Config/urls';

const AvatarVisitor = (props) => {

  const style = {      
    ...(props.width 	      && { width: props.width }),
    ...(props.height 	      && { height: props.height }),
    ...(props.flex 	        && { flex: props.flex }),
    ...(props.showBorder 	  && { showBorder: props.showBorder }),
    ...(props.borderColor 	&& { borderColor: props.borderColor }),
  }
    return (   
      <> 
        {!!props.visitorData ?
          <div className={"avatar " + (!!props.size ? props.size : "")} style={{...style}}>
              <div className='avatar-content'>
                <div className='colored-avatar' style={{backgroundColor:props.visitorData.color}}>
                  <div className='text'>{props.visitorData.shortcut}</div>
                </div>
              </div>
              <div className={`status ${props.visitorData.status} ${!!props.statusClass ? props.statusClass : ""} `}></div>
          </div>
          :
          null
        }
      </>
    );
  
}


export default AvatarVisitor;
