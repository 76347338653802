/**
 *  Respond panel component
 */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import translate from "../../Translations/index";
import EmoteIconPicker from "../../../GlobalComponents/EmoteIconPicker";
import useOnClickOutside from "use-onclickoutside";
import messageEmiters from "../../Websocket/Emiters/messageEmiters";
import { operatorCache, operatorVar } from "../../../Library/Cache/operatorCache";
import { useMutation, useReactiveVar } from "@apollo/client";
import operatorEmiters from "../../Websocket/Emiters/operatorEmiters";
import { DELETE_FILE, UPLOAD_FILES } from "../Queries/conversation";
import { addNotification, getFileIconByName, getLastHashtagFromText, isFileImage, middleEllipsis } from "../../../Library/helper";
import loadingCircleIcon from "../../../Public/Images/loading_circle.svg";
import { ReactComponent as LoadingIcon } from "../../../Public/Images/loading_circle.svg";
import ShortUniqueId from "short-unique-id";
import { ACCOUNT_FILE_URL } from "../../../Config/urls";
import { conversationCache } from "../../../Library/Cache/conversationCache";
import { settingsCache } from "../../../Library/Cache/settingsCache";
import { Scrollbars } from "react-custom-scrollbars-2";

const RespondPanel = (props, ref) => {
    const { conversationID } = props;
    const operatorData = useReactiveVar(operatorVar);
    const textareaRef = useRef(null);
    const emoteIconsRef = useRef(null);
    const componentRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const [typingTimeoutID, setTypingTimeoutID] = useState(null);
    const [emoteIconPickerOpen, setEmoteIconPickerOpen] = useState(false);
    const [lastCaretPosition, setLastCaretPosition] = useState(0);
    const [message, setMessage] = useState("");
    const [messageFiles, setMessageFiles] = useState([]);
    const [shortcut, setShortcut] = useState(null);
    const [forceCloseShortcutList, setForceCloseShortcutList] = useState(false);

    useOnClickOutside(emoteIconsRef, () => setEmoteIconPickerOpen(false));
    useOnClickOutside(componentRef, () => setForceCloseShortcutList(true));

    useImperativeHandle(ref, () => ({
        uploadFiles: uploadFiles,
    }));

    const [uploadMessageFiles] = useMutation(UPLOAD_FILES, {
        context: { headers: { "apollo-require-preflight": true } },
        onCompleted(data) {
            let files = [...messageFiles];

            for (let file of files) {
                for (let uploadedFile of data.uploadFiles) {
                    if (file.tmpID == uploadedFile.tmpID) {
                        file.uploading = false;
                        file.fileID = uploadedFile.fileID;
                    }
                }
            }
            setMessageFiles(files);
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });
    const [deleteMessageFile] = useMutation(DELETE_FILE, {
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                setForceCloseShortcutList(true);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (shortcut == "" && forceCloseShortcutList) {
            setForceCloseShortcutList(false);
        }
    }, [shortcut]);

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            if (!e.shiftKey) {
                e.preventDefault();
                sendMessage();

                !!typingTimeoutID && clearTimeout(typingTimeoutID);
                disableTyping();
            }
        } else {
            if (!isTyping) {
                setIsTyping(true);
                operatorEmiters.typing(conversationID, operatorData.operatorID, true);
            }

            !!typingTimeoutID && clearTimeout(typingTimeoutID);
            const timeoutID = setTimeout(disableTyping, 2000);
            setTypingTimeoutID(timeoutID);
        }
    };

    const uploadFiles = (files) => {
        if (files.length > 0) {
            const uid = new ShortUniqueId({ length: 16 });
            let newMessages = [...messageFiles];
            for (const file of files) {
                const tmpID = uid.rnd();
                newMessages.push({
                    fileID: null,
                    tmpID,
                    uploading: true,
                    name: file.name,
                    size: files.size,
                    ico: "",
                });

                uploadMessageFiles({
                    variables: {
                        addFilesData: {
                            accountID: operatorData.accountID,
                            conversationID,
                            files: [
                                {
                                    tmpID,
                                    file,
                                },
                            ],
                        },
                    },
                });
            }
            console.log(newMessages);
            setMessageFiles(newMessages);
        }
    };
    const removeUploadedFile = (file) => {
        console.log(file);
        for (let messageFile of messageFiles) {
            if (messageFile.tmpID == file.tmpID) {
                if (!!file.fileID) {
                    deleteMessageFile({ variables: { fileID: file.fileID } });
                }
                setMessageFiles(messageFiles.filter((item, index) => item.tmpID != file.tmpID));
                return;
            }
        }
    };

    const disableTyping = () => {
        setIsTyping(false);
        operatorEmiters.typing(conversationID, operatorData.operatorID, false);
    };

    const emoteIconPicked = (icon) => {
        const newMessage = message.slice(0, lastCaretPosition) + icon + message.slice(lastCaretPosition);
        setNewMessage(newMessage);
        setTimeout(() => {
            textareaRef.current.setSelectionRange(lastCaretPosition + icon.length, lastCaretPosition + icon.length);
            textareaRef.current.focus();
        }, 100);
        setEmoteIconPickerOpen(false);
    };
    const shortcutIconClicked = () => {
        const newMessage = message.slice(0, lastCaretPosition) + settingsCache.get().shortcutSign + message.slice(lastCaretPosition);
        setNewMessage(newMessage);
        setTimeout(() => {
            textareaRef.current.setSelectionRange(
                lastCaretPosition + settingsCache.get().shortcutSign.length,
                lastCaretPosition + settingsCache.get().shortcutSign.length
            );
            textareaRef.current.focus();
        }, 100);
    };

    const setNewMessage = (message) => {
        message = message.replace(":)", "🙂");
        message = message.replace(":-)", "🙂");
        message = message.replace(":D", "😀");
        message = message.replace(":-D", "😀");
        message = message.replace(":*", "😘");
        message = message.replace(":-*", "😘");
        message = message.replace(":/", "😕");
        message = message.replace(":-/", "😕");
        message = message.replace("XD", "🤣");
        message = message.replace("X-D", "🤣");
        message = message.replace(":O", "😮");
        message = message.replace(":‑O", "😮");
        message = message.replace(":P", "😋");
        message = message.replace(":‑P", "😋");

        const shortcut = getLastHashtagFromText(message);

        setShortcut(shortcut);

        setMessage(message);
    };

    const openFileSelector = () => {
        !!props.onFileSelectorOpen && props.onFileSelectorOpen();
    };

    const sendMessage = () => {
        if (!sendDisabled()) {
            const fileIDs = messageFiles.filter((item, index) => !!item.fileID).map((item, index) => item.fileID);
            messageEmiters.create(conversationID, operatorData, message, fileIDs);
            setMessage("");
            setMessageFiles([]);
        }
    };

    const sendDisabled = () => {
        if (message.trim().length == 0 && messageFiles.length == 0) {
            return true;
        } else {
            for (const file of messageFiles) {
                if (!!file.uploading) {
                    return true;
                }
            }
        }
        return false;
    };
    const selectHashTag = (selectedHashTagItem) => {
        const newMessage = message.replace(settingsCache.get().shortcutSign + shortcut, selectedHashTagItem.text);
        setNewMessage(newMessage);
        textareaRef.current.focus();
    };

    return (
        <div className="respond-panel" ref={componentRef}>
            <div className="text-block">
                {shortcut != null && !forceCloseShortcutList && (
                    <div className="shortcut-list">
                        <div className="scroll-corrector">
                            <table className="hoverable titles full-width ">
                                <thead>
                                    <tr>
                                        <th className="shortcut left">{translate.t("Shortcut")}</th>
                                        <th className="message left">{translate.t("ShortcutMessage")}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="content">
                            <Scrollbars
                                renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
                                renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden", marginBottom: "0px" }} />}
                            >
                                <div className="scroll-corrector">
                                    <table className="hoverable full-width tr-pointer">
                                        <tbody>
                                            {operatorCache.filterShortcuts(shortcut).map((item, index) => (
                                                <tr key={index} onClick={() => selectHashTag(item)}>
                                                    <td className="shortcut left">{settingsCache.get().shortcutSign + item.shortcut}</td>
                                                    <td className="message left">{item.text}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                )}
                <div className="textarea-content">
                    <textarea
                        value={message}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => onKeyPress(e)}
                        placeholder={
                            translate.t("PlaceForYourMessage") +
                            " " +
                            (!!conversationCache.getVisitor().name ? conversationCache.getVisitor().name : conversationCache.getVisitor().visitorID)
                        }
                        ref={textareaRef}
                        onBlur={() => setLastCaretPosition(textareaRef.current.selectionStart)}
                    ></textarea>
                    {messageFiles.length > 0 && (
                        <div className="files">
                            {messageFiles.map((item, index) => (
                                <div className={"file "} key={index}>
                                    {item.uploading ? (
                                        <LoadingIcon className="loading" />
                                    ) : (
                                        <div className="icon-wrapper">
                                            {getFileIconByName(item.name)}
                                            {isFileImage(item.name) && (
                                                <div className="preview">
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_SERVER_URL +
                                                            "/" +
                                                            ACCOUNT_FILE_URL +
                                                            "/" +
                                                            operatorData.accountID +
                                                            "/" +
                                                            item.name
                                                        }
                                                        alt="message-img"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div title={item.name}>{middleEllipsis(item.name, 10, 10)}</div>
                                    <i className="las la-times delete" onClick={() => removeUploadedFile(item)}></i>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="controls">
                        <div className="control" onClick={() => openFileSelector()}>
                            <i className="las la-paperclip icon"></i>
                            <div className="text">{translate.t("Attachment")}</div>
                        </div>
                        <div className="control" ref={emoteIconsRef}>
                            <div className="emoteicon-control" onClick={() => setEmoteIconPickerOpen(true)}>
                                <i className="las la-smile icon"></i>
                                <div className="text">{translate.t("Emoteicons")}</div>
                            </div>
                            {emoteIconPickerOpen && <EmoteIconPicker onPick={(icon) => emoteIconPicked(icon)} />}
                        </div>
                        <div className="control" onClick={() => shortcutIconClicked()}>
                            <i className="las la-keyboard icon"></i>
                            <div className="text">{translate.t("PredefinedText")}</div>
                        </div>
                        <button disabled={sendDisabled()} onClick={() => sendMessage()} className="btn blue ml-auto">
                            {translate.t("Send")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(RespondPanel);
