import { useQuery } from "@apollo/client";
import React from "react";
import Loading from "../../../../GlobalComponents/Loading";
import { addNotification, copyTextToClipboard } from "../../../../Library/helper";
import translate from "../../../Translations/index";
import { GET_LINKING_TO_WEB_DATA } from "../../Queries/settings";

const LinkingToTheWeb = (props) => {
    const { data, loading, refetch } = useQuery(GET_LINKING_TO_WEB_DATA, {
        fetchPolicy: "network-only",
        variables: {
            chatboxID: props.chatboxID,
        },
        onError(err) {
            console.log(err);
            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });

    const renderScript = `<!-- poptula support chat script -->
    <script type="text/javascript">
    (function(d, s, id)
        {var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return; js = d.createElement(s); js.id = id;
        js.src = "${process.env.REACT_APP_SERVER_URL}/jscripts/poptula-loader.js?c=${props.chatboxID}";        
        fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script','poptula-js'));
    </script>`;

    function selectContents(el) {
        let range = document.createRange();
        range.selectNodeContents(el);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    const copyText = () => {
        try {
            copyTextToClipboard(
                renderScript
                    .split("\n")
                    .map((item) => item.trim())
                    .join("\n")
            );
            addNotification("success", translate.t("ScriptSuccessfullyCopiedToClipboard"), translate.t("Success"));
        } catch (error) {
            addNotification("danger", translate.t("CopyFunctionIsNotSupported"), translate.t("Warning"));
        }
    };
    return (
        <>
            {!loading ? (
                <>
                    <div className="linking-to-web">
                        <div className="activation-code-desc">{translate.t("ActivationCodeInsertDescription")}</div>
                        {data?.chatbox?.conversationCount > 0 ? (
                            <div className="alert green">
                                <i className="las la-info-circle icon black"></i>
                                {translate.t("ActivationCodeInsertedSuccessfully")}
                            </div>
                        ) : (
                            <div className="alert red">
                                <i className="las la-info-circle icon black"></i>
                                {translate.t("ActivationCodeNotInserted")}
                            </div>
                        )}

                        <div className="script-title">{translate.t("ScriptForActivation")}</div>
                        <pre className="script" onClick={(e) => selectContents(e.target)}>
                            {renderScript
                                .split("\n")
                                .map((item) => item.trim())
                                .join("\n")}
                        </pre>
                    </div>
                    <button className="btn blue" onClick={() => copyText()}>
                        {translate.t("CopyToClipboard")}
                    </button>
                </>
            ) : (
                <Loading displayText={false} marginTop="14rem" />
            )}
        </>
    );
};

export default LinkingToTheWeb;
