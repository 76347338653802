import { useQuery } from '@apollo/client';
import React from 'react';
import { NavLink, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Loading from '../../../../GlobalComponents/Loading';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import { addNotification, useAdminRights } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { GET_CHATBOX } from '../../Queries/settings';
import Appearance from './Appearance';
import Introduction from './Introduction';
import LinkingToTheWeb from './LinkingToTheWeb';
import Rating from './Rating';


const ChatboxDetail = (props) => {

    let { path, url }                   = useRouteMatch();

	const operatorData                  = operatorCache.get();
    const {loading:roleCheckLoading}    = useAdminRights(operatorData?.roleID,["1","2"],"/account");
    
    let { chatboxID }       = useParams();
    
    const { data,loading, refetch } = useQuery(GET_CHATBOX,{
        fetchPolicy:"network-only",
        variables:{
            chatboxID
        },
        onCompleted(data){   
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });
    return(
        <>
        
            {!roleCheckLoading && !loading ?
                <>
                    <div className='page'>
                        <div className='settings-title'>
                            <h1>{translate.t("Chatbox")} {data?.chatbox?.name}</h1>
                            
                            <NavLink to={`${url.slice(0,url.indexOf("/chatbox"))}/chatbox`} className="back-to-list">
                                <i className="las la-undo icon"></i>
                                <span className='link'>{translate.t("BackToChatboxList")}</span>
                            </NavLink> 
                        </div>
                        <div className='menu'>
                            <NavLink to={`${url}/appearance`} activeClassName="selected" className="menu-item">
                                {translate.t("Appearance")}
                            </NavLink>
                            <NavLink to={`${url}/introduction`} activeClassName="selected" className="menu-item">
                                {translate.t("Introduction")}
                            </NavLink>
                            <NavLink to={`${url}/rating`} activeClassName="selected" className="menu-item">
                                {translate.t("Rating")}
                            </NavLink>
                            <NavLink to={`${url}/linkingToTheWeb`} activeClassName="selected" className="menu-item">
                                {translate.t("LinkingToTheWeb")}
                            </NavLink>
                        </div>
                        
                        <Switch>                
                            <Route exact path={`${path}`}>
                                <Redirect to={`${url}/appearance`} />
                            </Route>				
                            <Route path={`${url}/appearance`}><Appearance chatboxID={chatboxID} /></Route>
                            <Route path={`${url}/introduction`}><Introduction chatboxID={chatboxID} /></Route>
                            <Route path={`${url}/rating`}><Rating chatboxID={chatboxID} /></Route>
                            <Route path={`${url}/linkingToTheWeb`} ><LinkingToTheWeb chatboxID={chatboxID} /></Route>
                        </Switch>     
                    </div>
                </>
                :
                <Loading displayText={false} />                
            }
        </>
    );

}

export default ChatboxDetail;