import { makeVar } from "@apollo/client";

export const operatorVar = makeVar({
    status: "offline",
    operatorID: null,
    accountID: null,
    name: "",
    surname: "",
    nameInChatbox: "",
    roleID: null,
    email: "",
    img: "",
    shortcut: "",
    color: "",
    shortcuts: [
        { shortcut: "uvitani", text: "Dobrý den. Naše podpora vám pomůže s možným i nemožným." },
        { shortcut: "nemam-cas", text: "Dobrý den, momentálně je naše podpora vytížená na maximum. Prosíme o strpení." },
        { shortcut: "neni-skladem", text: "Omlouváme se, ale tento produkt zrovna nemáme skladem." },
        { shortcut: "reklamace", text: "Váš požadavek předám kolegovi, který řeší reklamace. Prosím, vydržte, hned se s vámi spojí..." },
        { shortcut: "chcete-pomoct", text: "Dobrý den, s čím Vám můžme pomoci?" },
    ],
});

export const operatorCache = {
    get: () => {
        return operatorVar();
    },
    set: (data) => {
        const newData = {
            ...(data.status && { status: data.status }),
            ...(data.operatorID && { operatorID: data.operatorID }),
            ...(data.accountID && { accountID: data.accountID }),
            ...(data.name && { name: data.name }),
            ...(data.surname && { surname: data.surname }),
            ...(data.nameInChatbox && { nameInChatbox: data.nameInChatbox }),
            ...(data.roleID && { roleID: data.roleID }),
            ...(data.email && { email: data.email }),
            ...(data.img && { img: data.img }),
            ...(data.shortcut && { shortcut: data.shortcut }),
            ...(data.shortcuts && { shortcuts: data.shortcuts }),
            ...(data.color && { color: data.color }),
        };
        operatorVar({ ...operatorVar(), ...newData });
    },
    filterShortcuts: (shortcutQuery) => {
        const allShortcuts = operatorVar().shortcuts;
        let shortcuts = [];
        if (shortcutQuery != null) {
            if (shortcutQuery != "") {
                shortcuts = allShortcuts.filter(function (item, index) {
                    return item.shortcut.indexOf(shortcutQuery) != -1;
                });
            } else {
                shortcuts = allShortcuts;
            }
        }

        return shortcuts;
    },
};
