import React,{ Component } from 'react';
import Modal from 'react-modal';
import translate from '../Modules/Translations/index';

Modal.setAppElement('#root');

class ModalNotification extends Component {
	  	
  render() {	
    return (
    	<Modal
		className="modal center"
		closeTimeoutMS={10}
		isOpen={true}
		onRequestClose={() => this.props.callback(false)}
		overlayClassName={`${this.props.overlayClassName ? this.props.overlayClassName : ""}`}
	    >
	        <div className="modal-content">
				<button type="button" className="close" onClick={() => this.props.callback(false)}>
					<i className="las la-times icon"></i>
				</button>
	          <div className="modal-header">
				<h4 className="modal-title">{!!this.props.title ? this.props.title : translate.t("Alert")}</h4>
	          </div>
			  {typeof this.props.content == "string" &&
				<div className="modal-body" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
			  }
			  {typeof this.props.content == "object" &&
				<div className="modal-body">{this.props.content}</div>
			  }
			  {this.props.yesNo ? 
				<div className="modal-footer"> 
					<button type="button" className={`btn ${!!this.props.confirmBtnColor ? this.props.confirmBtnColor : "blue"}`} onClick={() => this.props.callback(true)}>{!!this.props.confirmBtnText ? this.props.confirmBtnText : translate.t("Yes")}</button>
					{this.props.rejectBtnAsLink ?
						<div className='link ml-auto' onClick={() => this.props.callback(false)}>{!!this.props.rejectBtnText ? this.props.rejectBtnText : translate.t("No")}</div>
						:
						<button type="button" className="btn blue ml-auto" onClick={() => this.props.callback(false)}>{!!this.props.rejectBtnText ? this.props.rejectBtnText : translate.t("No")}</button>
					}
				</div>
			  :
				<div className="modal-footer"> 
					<button type="button" className="btn blue" onClick={() => this.props.callback(false)}>{!!this.props.closeBtnText ? this.props.closeBtnText : translate.t("Close")}</button>
				</div>
			  }
	        </div>
	    </Modal>
    );
  }
  
}


export default ModalNotification;
