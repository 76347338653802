
const prefix = 'visitor';
export const EVENTS_VISITOR = {
  DISCONNECTED: `${prefix}.disconnected`,
  CONNECTED: `${prefix}.connected`,
  CONVERSATION_STARTED: `${prefix}.conversation_started`,
  CONVERSATION_CLOSED: `${prefix}.conversation_closed`,
  VISITED_PAGE_ADDED: `${prefix}.visited_page_added`,
  TYPING: `${prefix}.typing`,
  EDIT: `${prefix}.edit`,
}; 
