import { getWebSocket } from "../websocket";
import moment from "moment";
import { EVENTS_MESSAGE } from "../Library/messageEvents";
import { conversationCache } from "../../../Library/Cache/conversationCache";
import { operatorCache } from "../../../Library/Cache/operatorCache";

export default {

  create:(conversationID,operatorData,message,fileIDs) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let messageData = {
          conversationID,
          operatorID:operatorData.operatorID,
          text:message,
          fileIDs,
          fromOperator:1,
          messageTypeID:1,
          createdDate:moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        }
        socket.emit(EVENTS_MESSAGE.CREATED,messageData,(data) => {});  

        resolve(messageData);
          
      } catch (error) {
        reject(error);
      }

    });   
  },

  /**
   * send message to server to set unread messages in conversation as read
   * @param {String} conversationID - conversationID (conversation)
   * @returns {Promise} - Promise
   */
  conversationMessagesRead:(conversationID) => {
    return new Promise(async(resolve,reject) => {
        try {
        const socket = getWebSocket().socket;  

        const operatorData = operatorCache.get();
        let data = {
          conversationID,
          visitorID:null,
          operatorID:operatorData.operatorID
        }

        socket.emit(EVENTS_MESSAGE.MESSAGES_READ,data,(data) => {});  


        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },

}
