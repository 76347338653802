/**
 *  Component for render messages
 */
import React from 'react';
import {  getFileIconByName, middleEllipsis } from '../../../Library/helper';
import { ACCOUNT_FILE_URL } from '../../../Config/urls';
import { showLightbox } from '../../../GlobalComponents/Lightbox/LightboxService';



const FileDisplay = (props) => {
   
    const {file}     = props;

    const showImg = (e,file) => {
        e.preventDefault();
        showLightbox([file]);
    }
    
    return(
        <a href={process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_FILE_URL + "/" + file.accountID + "/" + file.name} className='file-item' target="_blank"> 
            {["jpg","jpeg","png","gif"].includes(file.ico.toLowerCase()) ?
                <img src={process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_FILE_URL + "/" + file.accountID + "/" + file.name} alt="message-img" onClick={(e) => showImg(e,process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_FILE_URL + "/" + file.accountID + "/" + file.name)}/>
                :
                <div className={"file"}>
                    {getFileIconByName(file.ico)}
                    <div className='name'>{middleEllipsis(file.name,10,10) }</div>
                </div>
            }     

        </a>
    );

}

export default FileDisplay;