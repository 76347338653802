/**
 *  Component for render messages
 */
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import { operatorListCache } from '../../../Library/Cache/operatorListCache';
import { getMessageTimeString } from '../../../Library/helper';
import translate from '../../Translations/index';
import useOnClickOutside from 'use-onclickoutside';

const ReadBy = (props) => {
   
    const {message}     = props;

    const visitorData      = conversationCache.getVisitor();
    let operatorData       = operatorCache.get();
    let operators          = [...conversationCache.getOperators()];
    
	const ref 			  = useRef();
	useOnClickOutside(ref, () => showHideReadByContent(false));

    const getReadBy = () => {

        let allOperatorsRead    = true;
        let visitorRead         = false;
        let readDate            = null;

        //check if all operators read message
        for(let operator of operators){
            //if operator is not me
            if(operator.operatorID != operatorData.operatorID){
                operator.read = false;
                for(let readBy of message.readBy){
                    if(!!readBy.operatorID && readBy.operatorID == operator.operatorID){
                        operator.read = true;
                        break;
                    }
                }
                //if there is operator who did not read the message, we can skip another repeats, because it wasnt read by all
                if(!operator.read){
                    allOperatorsRead =  false;
                    break;
                }
            }
        }

        //check if visitor reads message
        for(let readBy of message.readBy){
            if(!!readBy.visitorID && readBy.visitorID == visitorData.visitorID){
                visitorRead = true;
                readDate    = readBy.readDate;
                break;
            }
        }

        if(allOperatorsRead && visitorRead){
            return {type:"all",readDate};
        }else if(visitorRead){
            return {type:"visitor",readDate};
        }
        
        return false;
    }

    const showHideReadByContent = (status) => {
        conversationCache.editMessageItem(message.messageID,{showReadByDetail:typeof status != "undefined" ? status : !message.showReadByDetail});
    }


    const readBy = getReadBy();
    return(
        <>
            {!!readBy &&
                <div ref={ref} className='read-by' onClick={() => showHideReadByContent()}>
                    {translate.t("Read") + " " + getMessageTimeString(readBy.readDate,true)}
                    {readBy.type == "visitor" && <i className="las la-check read-by-icon"></i>}
                    {readBy.type == "all" && <i className="las la-check-double read-by-icon"></i>}
                    {!!message.showReadByDetail &&
                        <div className='read-by-content'>
                            {message.readBy.map((item,index) => {
                                if(!!item.operatorID){
                                    const nameData = operatorListCache.getItemByID(item.operatorID);
                                    return (
                                        <div key={index} className='item'>
                                            <div className='name'>{nameData.name + " " + nameData.surname}</div>
                                            <div className='date-time' title={moment.utc(item.readDate).local().format("L LT")}>{getMessageTimeString(item.readDate,true)}</div>
                                        </div>
                                    )
                                }else{
                                    return (                                        
                                        <div key={index} className='item'>
                                            <div className='name'>{!!visitorData.name ? visitorData.name : visitorData.visitorID}</div>
                                            <div className='date-time' title={moment.utc(item.readDate).local().format("L LT")}>{getMessageTimeString(item.readDate,true)}</div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    }
                
                </div>
            }
        </>
    );

}

export default ReadBy;