import { GET_INITIAL_DATA } from "../GlobalQueries/globalQueries";
import messageEmiters from "../Modules/Websocket/Emiters/messageEmiters";
import { getApolloClient } from "./apolloClient";
import { conversationListCache } from "./Cache/conversationListCache";
import { operatorCache } from "./Cache/operatorCache";
import { operatorListCache } from "./Cache/operatorListCache";

/**
 * Sets initial data to cache files when operator already logged and started dashboard or after login
 * @param {Object} operatorData - Logged operator data 
 * @returns 
 */
export function bootstrapDashboard(){
    return new Promise(async(resolve,reject) => {
        try {

            const client = getApolloClient();

            const operatorData          = operatorCache.get();

            const initialData = await client.query({query: GET_INITIAL_DATA,errorPolicy:"all",fetchPolicy: 'network-only',variables:{accountID:operatorData.accountID}});
            operatorListCache.set(initialData.data.allOperators);

            await conversationListCache.loadActiveConversations();
            resolve();
          
        } catch (error) {
            reject(error);
        }

    });

}

/**
 * If message is of thype to play sound
 * @param {Object} messageData - message data from websocket
 * @returns {Boolean} 
 */
export function isSoundMessage(messageData){
    
    const operatorData = operatorCache.get();
    if([1].includes(messageData.messageTypeID) && messageData.operatorID != operatorData.operatorID){
        return true;
    }
    return false;
}

/**
 * Sets unread message count based on new received message
 * @param {Object} messageData  - message data from websocket
 * 
 */
export function setsUnreadMessageCount(messageData){

    const operatorData = operatorCache.get();
    if([1].includes(messageData.messageTypeID) && messageData.operatorID != operatorData.operatorID){
        const conversationData = conversationListCache.getItem(messageData.conversationID);
        if(!!conversationData){
            conversationListCache.editItem(messageData.conversationID,{unreadMessageCount:conversationData.unreadMessageCount + 1});
        }
    }
}

/**
 * sets unread messages in conversation as read
 * @param {String} conversationID 
 */
export function setConversationMessagesRead(conversationID){
    messageEmiters.conversationMessagesRead(conversationID);
    conversationListCache.editItem(conversationID,{unreadMessageCount:0});   
    
}

