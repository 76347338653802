import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AvatarPicker from '../../../../GlobalComponents/AvatarPicker';
import { addNotification } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { ADD_EDIT_CHATBOX, CHANGE_GENERAL_OPERATOR_PHOTO, GET_APPEARANCE_DATA } from '../../Queries/settings';
import {ReactComponent as Spinner} from '../../../../Public/Images/spinner.svg';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import { ACCOUNT_IMAGE_PATH, OPERATOR_IMAGE_URL } from '../../../../Config/urls';
import LivePreview from './LivePreview';
import Loading from '../../../../GlobalComponents/Loading';


const Appearance = (props) => {
    
    const [maxCharCounts,setMaxCharCounts]    = useState({
        chatboxOnlineTitle:30,
        chatboxOnlineStatusTitle:30,
        textOnlinePlaceholder:30,
        chatboxOfflineTitle:30,
        chatboxOfflineStatusTitle:30,
        textOfflinePlaceholder:30,
    })
    const [systemOperatorPhoto,setSystemOperatorPhoto]    = useState();
    const [formData,setFormData]    = useState({
        chatboxID:"",
        name:"",
        webUrl:"",
        mainColor:"",
        placement:"",
        lang:"",
        systemOperatorName:"",
        chatboxOnlineTitle:"",
        chatboxOnlineStatusTitle:"",
        textOnlinePlaceholder:"",
        chatboxOfflineTitle:"",
        chatboxOfflineStatusTitle:"",
        textOfflinePlaceholder:"",
    })

    const { data,loading, refetch } = useQuery(GET_APPEARANCE_DATA,{
        fetchPolicy:"network-only",
        variables:{
            chatboxID:props.chatboxID
        },
        onCompleted(data){   
            if(!!data.chatbox){                
                setFormData({...formData,
                    chatboxID: data.chatbox.chatboxID,
                    name: data.chatbox.name,
                    webUrl: data.chatbox.webUrl,
                    placement: data.chatbox.placement,
                    lang: data.chatbox.lang,
                    mainColor: data.chatbox.mainColor,
                    systemOperatorName: data.chatbox.systemOperatorName,
                    chatboxOnlineTitle: data.chatbox.chatboxOnlineTitle,
                    chatboxOnlineStatusTitle: data.chatbox.chatboxOnlineStatusTitle,
                    textOnlinePlaceholder: data.chatbox.textOnlinePlaceholder,
                    chatboxOfflineTitle: data.chatbox.chatboxOfflineTitle,
                    chatboxOfflineStatusTitle: data.chatbox.chatboxOfflineStatusTitle,
                    textOfflinePlaceholder: data.chatbox.textOfflinePlaceholder,
                });
                setSystemOperatorPhoto(data.chatbox.systemOperatorPhoto);
            }  
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });

    const [saveChatbox,{loading:saveLoading}] = useMutation(ADD_EDIT_CHATBOX,{
        onCompleted(data){            
			addNotification("success",translate.t("ChatboxSuccessfullySaved"),translate.t("Success"));
            !!props.callback && props.callback(data.addEditChatbox);
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })
    const [saveGeneralOperatorPhoto,{loading:savePhotoLoading}] = useMutation(CHANGE_GENERAL_OPERATOR_PHOTO,{
        onCompleted(data){            
			addNotification("success",translate.t("PhotoSuccessfullySaved"),translate.t("Success"));
            setSystemOperatorPhoto(data.changeGeneralOperatorPhoto.systemOperatorPhoto);
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })
    const getSelectedLang = () => {
        console.log(data?.allEnabledLanguages);
        const selectedLang = data?.allEnabledLanguages?.filter((item,index) => item.lang == formData.lang)[0];
        
        console.log(selectedLang);
        return !!selectedLang ? selectedLang : null;
    }

    const checkMaxCount = (text,paramToCheck) => {
        return text.length <= maxCharCounts[paramToCheck] ? true : false;
    }

    const saveData = () => {
        
        saveChatbox({
            variables:{
                addChatboxData:formData
            }
        })   
        
    }
    const saveSystemOperatorPhoto = (photo) => {
        saveGeneralOperatorPhoto({variables:{chatboxID:formData.chatboxID,photo}})        
    }
    
    return(
        <div className="appearance">
            {!loading ? 
                <>
                    <div className='left-column'>               
                        <div className='group-title'>{translate.t("ChatboxName")}</div>
                        <div className='form-group'>
                            <div className='input-group'>
                                <input type="text" value={formData.name} onChange={(e) => setFormData({...formData,name:e.target.value})} />
                            </div>
                        </div>            
                        <div className='group-title'>{translate.t("Placement")}</div>
                        <div className='form-group'>
                            <div className='input-group'>
                                <div className={`radio-item inline`} onClick={() => setFormData({...formData,placement:"right"})} >									
                                    <div className={'radio-circle ' + (formData.placement == "right" ? "selected" : "")}></div>
                                    <div className='radio-item-text'>{translate.t("ChatboxRight")}</div>
                                </div>
                                <div className={`radio-item inline`} onClick={() => setFormData({...formData,placement:"left"})} >									
                                    <div className={'radio-circle ' + (formData.placement == "left" ? "selected" : "")}></div>
                                    <div className='radio-item-text'>{translate.t("ChatboxLeft")}</div>
                                </div>
                            </div>
                        </div>         
                        <div className='group-title'>{translate.t("WebURL")}</div>
                        <div className='form-group'>
                            <div className='input-group'>
                                <input type="text" value={formData.webUrl} onChange={(e) => setFormData({...formData,webUrl:e.target.value})} />
                            </div>
                        </div>   
                        <div className='group-title'>{translate.t("ChatboxLanguage")}</div>
                        <div className='form-group'>
                            <label>{translate.t("Choose")}</label>
                            <div className='input-group has-addon-left'>
                                <span className='addon left'><i className="las la-language icon"></i></span>
                                <select className="form-select language" value={formData.lang} onChange={(e) => setFormData({...formData,lang:e.target.value})}>
                                    {data?.allEnabledLanguages?.map((item,index) => (
                                        <option key={index} value={item.lang}>{item.langTitle}</option>
                                    ))}
                                </select>            
                            </div>
                        </div>
                        <div className='group-title'>{translate.t("ChatboxColoring")}</div>
                        <div className='form-group'>
                            <label>{translate.t("MainColor")}</label>
                            <div className='input-group has-addon-left'>
                                <span className='addon left'><i style={{color:formData.mainColor}} className="las la-square-full icon"></i></span>
                                <select className="form-select language" value={formData.mainColor} onChange={(e) => setFormData({...formData,mainColor:e.target.value})}>
                                    {data?.chatboxColors?.map((item,index) => (
                                        <option style={{color:item.mainColor}} key={index} value={item.mainColor}>{item.mainColor}</option>
                                    ))}
                                </select> 
                            </div>
                        </div>  
                        <div className='group-title'>{translate.t("SystemOperatorAvatar")}</div>
                        <div className='form-group'>
                            <label>{translate.t("Name")}</label>
                            <div className='input-avatar'>
                                <div className='input-group'>
                                    <input type="text" value={formData.systemOperatorName} onChange={(e) => setFormData({...formData,systemOperatorName:e.target.value})} />
                                </div>
                                <AvatarPicker photo={!!systemOperatorPhoto ? process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_IMAGE_PATH + "/" + operatorCache.get().accountID + "/generalOperator/small_" + systemOperatorPhoto : process.env.REACT_APP_SERVER_URL + "/" + OPERATOR_IMAGE_URL + "/default_operator.jpg"} loading={savePhotoLoading} modalTitle={translate.t("ChangeGeneralSupportAvatarPhoto")} onSelected={(photo => saveSystemOperatorPhoto(photo))}/>
                            </div>
                        </div>         
                        <div className='group-title'>{translate.t("ChatboxOnlineTexts")}</div>
                        <div className='form-group'>
                            <label>{translate.t("ChetboxTitle")}<span className='description right'>{formData.chatboxOnlineTitle.length}/{maxCharCounts.chatboxOnlineTitle}</span></label>
                            <div className='input-group'>
                                <input type="text" value={formData.chatboxOnlineTitle} onChange={(e) => checkMaxCount(e.target.value,"chatboxOnlineTitle") && setFormData({...formData,chatboxOnlineTitle:e.target.value})} />
                            </div>
                        </div>  
                        <div className='form-group'>
                            <label>{translate.t("ChetboxStatus")}<span className='description right'>{formData.chatboxOnlineStatusTitle.length}/{maxCharCounts.chatboxOnlineStatusTitle}</span></label>
                            <div className='input-group'>
                                <input type="text" value={formData.chatboxOnlineStatusTitle} onChange={(e) => checkMaxCount(e.target.value,"chatboxOnlineStatusTitle") && setFormData({...formData,chatboxOnlineStatusTitle:e.target.value})} />
                            </div>
                        </div>  
                        <div className='form-group'>
                            <label>{translate.t("ChetboxPlaceholder")}<span className='description right'>{formData.textOnlinePlaceholder.length}/{maxCharCounts.textOnlinePlaceholder}</span></label>
                            <div className='input-group'>
                                <input type="text" value={formData.textOnlinePlaceholder} onChange={(e) => checkMaxCount(e.target.value,"textOnlinePlaceholder") && setFormData({...formData,textOnlinePlaceholder:e.target.value})} />
                            </div>
                        </div>   
                                
                        <div className='group-title'>{translate.t("ChatboxOfflineTexts")}</div>
                        <div className='form-group'>
                            <label>{translate.t("ChetboxTitle")}<span className='description right'>{formData.chatboxOfflineTitle.length}/{maxCharCounts.chatboxOfflineTitle}</span></label>
                            <div className='input-group'>
                                <input type="text" value={formData.chatboxOfflineTitle} onChange={(e) => checkMaxCount(e.target.value,"chatboxOfflineTitle") && setFormData({...formData,chatboxOfflineTitle:e.target.value})} />
                            </div>
                        </div>  
                        <div className='form-group'>
                            <label>{translate.t("ChetboxStatus")}<span className='description right'>{formData.chatboxOfflineStatusTitle.length}/{maxCharCounts.chatboxOfflineStatusTitle}</span></label>
                            <div className='input-group'>
                                <input type="text" value={formData.chatboxOfflineStatusTitle} onChange={(e) => checkMaxCount(e.target.value,"chatboxOfflineStatusTitle") && setFormData({...formData,chatboxOfflineStatusTitle:e.target.value})} />
                            </div>
                        </div>  
                        <div className='form-group'>
                            <label>{translate.t("ChetboxPlaceholder")}<span className='description right'>{formData.textOfflinePlaceholder.length}/{maxCharCounts.textOfflinePlaceholder}</span></label>
                            <div className='input-group'>
                                <input type="text" value={formData.textOfflinePlaceholder} onChange={(e) => checkMaxCount(e.target.value,"textOfflinePlaceholder") && setFormData({...formData,textOfflinePlaceholder:e.target.value})} />
                            </div>
                        </div>  
                        <button className='btn blue' disabled={saveLoading} onClick={() => saveData()}>{!saveLoading ? translate.t("SaveChanges") : <Spinner />}</button>
                    </div>
                    <div className='right-column'>
                        <LivePreview type="appearance" previewData={formData} />
                    </div>
                </>
                :
                <Loading displayText={false} marginTop="14rem" />
            }
        </div>
    );

}

export default Appearance;