import React,{ Component } from 'react';
import { ACCOUNT_IMAGE_PATH, OPERATOR_IMAGE_URL } from '../Config/urls';
import { operatorCache } from '../Library/Cache/operatorCache';

const AvatarSystem = (props) => {

    const style = {      
      ...(props.width 	      && { width: props.width }),
      ...(props.height 	      && { height: props.height }),
    }
    const operatorData = operatorCache.get();
    return (
      <div className={`avatar ${(!!props.size ? props.size : "")} ${(!!props.className ? props.className : "")}`} style={style}>
        <div className='avatar-content'>
            <div className='colored-avatar'>
              <img src={props.chatboxData.systemOperatorPhoto ? process.env.REACT_APP_SERVER_URL + "/" + ACCOUNT_IMAGE_PATH + "/" + operatorData.accountID + "/generalOperator/small_" + props.chatboxData.systemOperatorPhoto : process.env.REACT_APP_SERVER_URL + "/" + OPERATOR_IMAGE_URL + "/default_operator.jpg"} alt="system_avater" />                
            </div>
        </div>
      </div>
    );
  
}


export default AvatarSystem;

