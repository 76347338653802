import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { addNotification } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { ADD_EDIT_CHATBOX, GET_RATING_DATA } from '../../Queries/settings';
import {ReactComponent as Spinner} from '../../../../Public/Images/spinner.svg';
import Loading from '../../../../GlobalComponents/Loading';
import LivePreview from './LivePreview';

const Rating = (props) => {

    const [maxCharCounts,setMaxCharCounts]    = useState({
        ratingText:120,
    })
    const [formData,setFormData]    = useState({
        chatboxID:"",
        ratingEnabled:0,
        ratingText:""
    })
    const { data,loading, refetch } = useQuery(GET_RATING_DATA,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            chatboxID:props.chatboxID
        },
        onCompleted(data){   
            if(!!data.chatbox){                
                setFormData({...formData,
                    chatboxID: data.chatbox.chatboxID,
                    ratingEnabled: data.chatbox.ratingEnabled,
                    ratingText: data.chatbox.ratingText
                });
            }  
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });
    
    const [saveChatbox,{loading:saveLoading}] = useMutation(ADD_EDIT_CHATBOX,{
        onCompleted(data){            
			addNotification("success",translate.t("ChatboxSuccessfullySaved"),translate.t("Success"));
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })
    
    const checkMaxCount = (text,paramToCheck) => {
        return text.length <= maxCharCounts[paramToCheck] ? true : false;
    }

    const saveData = () => {
        
        saveChatbox({
            variables:{
                addChatboxData:{
                    ...formData,
                    ratingEnabled: formData.ratingEnabled ? 1 : 0
                }
            }
        })   
        
    }

    return(
        <div className="rating">
            {!loading ? 
                <>
                    <div className='left-column'>            
                        <div className='group-title'>{translate.t("DoYouWantToRateVisitor")}</div>
                        <div className='form-group'>
                            <div className='input-group'>
                                <div className={`radio-item inline`} onClick={() => setFormData({...formData,ratingEnabled:1})} >									
                                    <div className={'radio-circle ' + (formData.ratingEnabled == 1 ? "selected" : "")}></div>
                                    <div className='radio-item-text'>{translate.t("YesIWantThemToRateSupport")}</div>
                                </div>
                                <div className={`radio-item inline`} onClick={() => setFormData({...formData,ratingEnabled:0})} >									
                                    <div className={'radio-circle ' + (formData.ratingEnabled == 0 ? "selected" : "")}></div>
                                    <div className='radio-item-text'>{translate.t("NoIDont")}</div>
                                </div>
                            </div>
                        </div>         
                        <div className='form-group'>
                            <label>{translate.t("RatingText")}<span className='description right'>{formData.ratingText.length}/{maxCharCounts.ratingText}</span></label>
                            <div className='input-group'>
                                <textarea type="text" value={formData.ratingText} onChange={(e) => checkMaxCount(e.target.value,"ratingText") && setFormData({...formData,ratingText:e.target.value})} />
                            </div>
                        </div> 
                        <button className='btn blue' disabled={saveLoading} onClick={() => saveData()}>{!saveLoading ? translate.t("SaveChanges") : <Spinner />}</button>
                    </div>
                    <div className='right-column'>
                        <LivePreview type="rating" previewData={{...data.chatbox,...formData}} />
                    </div>
                </>
                :
                <Loading displayText={false} marginTop="14rem" />
            }
        </div>
    );

}

export default Rating;