/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useEffect, useRef, useState } from "react";
import translate from "../../Translations/index";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { GET_CONVERSATION, GET_CONVERSATION_MESSAGES } from "../Queries/conversation";
import Loading from "../../../GlobalComponents/Loading";
import operatorEmiters from "../../Websocket/Emiters/operatorEmiters";
import { conversationCache, conversationVar } from "../../../Library/Cache/conversationCache";
import { operatorVar } from "../../../Library/Cache/operatorCache";
import AvatarVisitor from "../../../GlobalComponents/AvatarVisitor";
import { Link, useParams } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import Message from "./Message";
import { setConversationMessagesRead } from "../../../Library/dashboard";
import { operatorListCache } from "../../../Library/Cache/operatorListCache";
import conversationEmiters from "../../Websocket/Emiters/conversationEmiters";
import AvatarOperator from "../../../GlobalComponents/AvatarOperator";
import RespondPanel from "./RespondPanel";
import UploadFilesDropzone from "../../../GlobalComponents/UploadFilesDropzone";
import { Scrollbars } from "react-custom-scrollbars-2";
import TaskItem from "./TaskItem";
import NoteItem from "./NoteItem";
import ReactTooltip from "react-tooltip";
import { dashboardCache, dashboardVar } from "../../../Library/Cache/dashboardCache";
import { addNotification, getMessageGroupedInfo, getMessagesDateSeparator } from "../../../Library/helper";
import Dropdown from "../../../GlobalComponents/Dropdown";

const Conversation = (props) => {
    let { conversationID } = useParams();
    const operatorData = operatorVar();
    const conversation = useReactiveVar(conversationVar);
    const dashboardData = useReactiveVar(dashboardVar);
    const messagesEndRef = useRef(null);
    const displayedMessageCount = useRef(null);
    const dropzoneRef = useRef(null);
    const respondPanelRef = useRef(null);
    const currentScrollPosition = useRef(null);
    const scrollbarRef = useRef();
    const scrollToBottom = useRef(true);

    const [showAddTask, setShowAddTask] = useState(false);
    const [showAddNote, setShowAddNote] = useState(false);
    const [messageLimit, setMessageLimit] = useState(5);
    const [notesClosed, setNotesClosed] = useState(false);
    const [tasksClosed, setTasksClosed] = useState(false);

    let lastMessageDate = null;

    const prioritySelectionRef = useRef();
    const [prioritySelectionOpen, setPrioritySelectionOpen] = useState(false);
    useOnClickOutside(prioritySelectionRef, () => setPrioritySelectionOpen(false));

    const optionSelectionRef = useRef();
    const [optionSelectionOpen, setOptionSelectionOpen] = useState(false);
    useOnClickOutside(optionSelectionRef, () => setOptionSelectionOpen(false));

    const conversationStatusSelectionRef = useRef();
    const [conversationStatusSelectionOpen, setConversationStatusSelectionOpen] = useState(false);
    useOnClickOutside(conversationStatusSelectionRef, () => {
        setConversationStatusSelectionOpen(false);
    });

    const rightColumnRef = useRef();
    const [showRightPanel, setShowRightPanel] = useState(false);
    useOnClickOutside(rightColumnRef, () => {
        setShowRightPanel(false);
    });

    const [getConversation, { loading }] = useLazyQuery(GET_CONVERSATION, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            displayedMessageCount.current = 0;
            scrollToBottom.current = true;

            const d = JSON.parse(JSON.stringify(data));
            conversationCache.set(d.conversation);
            conversationCache.setVisitor(d.conversation.visitor);
            conversationCache.setChatbox(d.conversation.chatbox);
            conversationCache.setMessages(d.messages.reverse());
            conversationCache.setTasks(d.tasks);
            conversationCache.setNotes(d.notes);
            conversationCache.setVisitedPages(d.conversation.visitor.visitedPages);
            conversationCache.setOperators(d.conversationOperators);
            conversationCache.setVisitorTyping(false);
            conversationCache.clearTypingOperators();

            //if some unread message exists and operator is joined to conversation
            if (d.conversation.unreadMessageCount > 0 && conversationCache.operatorExists(operatorData.operatorID)) {
                setConversationMessagesRead(conversationID);
            }
        },
    });
    const [fetchMoreMessages, { loading: fetchMoreLoading }] = useLazyQuery(GET_CONVERSATION_MESSAGES, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            const d = JSON.parse(JSON.stringify(data));
            conversationCache.setMoreMessages(d.messages.reverse());
        },
    });

    useEffect(() => {
        getConversation({
            variables: {
                conversationID,
                operatorID: operatorData.operatorID,
                filterData: {
                    offset: 0,
                    limit: messageLimit,
                    orderBy: "M.createdDate DESC",
                },
            },
        });

        dashboardCache.set({ mobileMenuTabSelected: 2 });
    }, [conversationID]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [conversation]);

    useEffect(() => {
        function visibilityChanged() {
            if (!document.hidden && conversationCache.operatorExists(operatorData.operatorID)) {
                setConversationMessagesRead(conversationID);
            }
        }

        //event listener for tab change or close browser events
        document.addEventListener("visibilitychange", visibilityChanged);
        return () => document.removeEventListener("visibilitychange", visibilityChanged);
    }, []);

    const setScrollbarPosition = () => {
        if (scrollToBottom.current) {
            if (displayedMessageCount.current != conversation.messages.length && !!messagesEndRef && !!messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView();
            }
        } else {
            if (displayedMessageCount.current != conversation.messages.length) {
                scrollbarRef.current.scrollTop(scrollbarRef.current.getScrollHeight() - currentScrollPosition.current);
            }
        }
        scrollToBottom.current = true;
        displayedMessageCount.current = conversation.messages.length;
    };
    useEffect(setScrollbarPosition, [conversation.messages]);

    const joinConversation = () => {
        operatorEmiters.joinConversation(conversationID, operatorData);
    };

    const leaveConversation = () => {
        operatorEmiters.leaveConversation(conversationID, operatorData.operatorID);
    };
    const changeConversationStatus = (conversationStateID) => {
        if (conversationCache.isMeInOperatorList()) {
            if (conversationStateID == 2) {
                conversationEmiters.startConversation(conversationID, operatorData.operatorID);
            } else if (conversationStateID == 3) {
                conversationEmiters.pauseConversation(conversationID, operatorData.operatorID);
            } else if (conversationStateID == 4) {
                conversationEmiters.closeConversation(conversationID, operatorData.operatorID);
            }
        }
    };
    const onScroll = (event) => {
        if (!fetchMoreLoading) {
            if (event.scrollTop == 0) {
                scrollToBottom.current = false;
                console.log(conversation.messages.length);
                fetchMoreMessages({
                    variables: {
                        conversationID,
                        filterData: {
                            offset: conversation.messages.length,
                            limit: messageLimit,
                            orderBy: "M.createdDate DESC",
                        },
                    },
                });
            }
        }

        currentScrollPosition.current = event.scrollHeight;
    };

    return (
        <>
            {!loading ? (
                <>
                    <div className={`middle-column ${dashboardData.mobileMenuTabSelected == 2 ? "show-mobile" : ""}`}>
                        <div className="header">
                            <div className="avatar-block">
                                <div className="visitor-title">{!!conversation.visitor.name ? conversation.visitor.name : conversation.visitor.visitorID}</div>
                                <Dropdown
                                    className={`conversation-state`}
                                    content={
                                        <div
                                            className={`state-content ${conversation.conversationStateID == 2 ? "active" : ""} ${
                                                conversation.conversationStateID == 3 ? "deffered" : ""
                                            } ${conversation.conversationStateID == 4 ? "solved" : ""} `}
                                        >
                                            {conversation.conversationStateID == 2 && translate.t("ActiveConvState")}
                                            {conversation.conversationStateID == 3 && translate.t("DefferedConvState")}
                                            {conversation.conversationStateID == 4 && translate.t("SolvedConvState")}
                                            <i className="las la-angle-down icon"></i>
                                        </div>
                                    }
                                    arrowLeft="auto"
                                    arrowRight="1.8rem"
                                    dropdownContent={
                                        <>
                                            <div className="title">{translate.t("ConversationState")}</div>
                                            <div className="status-list">
                                                <div
                                                    className={`radio-item inline ${!conversationCache.isMeInOperatorList() && "disabled"}`}
                                                    onClick={() => changeConversationStatus(2)}
                                                    data-tip-disable={conversationCache.isMeInOperatorList()}
                                                    data-tip={translate.t("StateCanEditOnlyJoinedOperator")}
                                                    data-for="top-solid-tooltip"
                                                >
                                                    <div className={"radio-circle " + (conversation.conversationStateID == 2 ? "selected" : "")}></div>
                                                    <div className="radio-item-text">{translate.t("ActiveConvState")}</div>
                                                </div>
                                                <div
                                                    className={`radio-item inline ${!conversationCache.isMeInOperatorList() && "disabled"}`}
                                                    onClick={() => changeConversationStatus(3)}
                                                    data-tip-disable={conversationCache.isMeInOperatorList()}
                                                    data-tip={translate.t("StateCanEditOnlyJoinedOperator")}
                                                    data-for="top-solid-tooltip"
                                                >
                                                    <div className={"radio-circle " + (conversation.conversationStateID == 3 ? "selected" : "")}></div>
                                                    <div className="radio-item-text">{translate.t("DefferedConvState")}</div>
                                                </div>
                                                <div
                                                    className={`radio-item inline ${!conversationCache.isMeInOperatorList() && "disabled"}`}
                                                    onClick={() => changeConversationStatus(4)}
                                                    data-tip-disable={conversationCache.isMeInOperatorList()}
                                                    data-tip={translate.t("StateCanEditOnlyJoinedOperator")}
                                                    data-for="top-solid-tooltip"
                                                >
                                                    <div className={"radio-circle " + (conversation.conversationStateID == 4 ? "selected" : "")}></div>
                                                    <div className="radio-item-text">{translate.t("SolvedConvState")}</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>

                            <div className="actions">
                                {conversationCache.isMeInOperatorList() && (
                                    <>
                                        <div className={`item`}>
                                            <i className="las la-share-square icon"></i>
                                            <div className="text">{translate.t("SendConversation")}</div>
                                        </div>
                                        <div className={`item`} onClick={() => leaveConversation()}>
                                            <i className="las la-door-open icon"></i>
                                            <div className="text">{translate.t("LeaveConversation")}</div>
                                        </div>
                                    </>
                                )}
                                <div className={`item toggle-right-panel`} onClick={() => setShowRightPanel(!showRightPanel)}>
                                    <i className="las la-plus-square icon"></i>
                                    <div className="text">{translate.t("Info")}</div>
                                </div>
                            </div>
                        </div>

                        <div className="message-list">
                            <Scrollbars
                                ref={scrollbarRef}
                                renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
                                renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden", marginBottom: "0px" }} />}
                                onScrollFrame={(event) => onScroll(event)}
                            >
                                <div className="message-list-content">
                                    {conversation &&
                                        conversation.messages &&
                                        conversation.messages.map((item, index) => {
                                            const messagesDateSeparator = getMessagesDateSeparator(lastMessageDate, item.createdDate);
                                            lastMessageDate = item.createdDate;
                                            return (
                                                <Fragment key={index}>
                                                    {messagesDateSeparator}
                                                    <Message message={item} groupedInfo={getMessageGroupedInfo(conversation.messages, index)} />
                                                </Fragment>
                                            );
                                        })}

                                    <div ref={messagesEndRef} />
                                </div>
                            </Scrollbars>
                            <div className="typing-list">
                                {((conversation && conversation.visitorTyping) ||
                                    (conversation && conversation.typingOperators && conversation.typingOperators.length > 0)) && (
                                    <>
                                        {conversation && conversation.visitorTyping && (
                                            <AvatarVisitor showStatus={false} visitorData={conversation.visitor} statusClass="border white-border" />
                                        )}
                                        {conversation &&
                                            conversation.typingOperators &&
                                            conversation.typingOperators.length > 0 &&
                                            conversation.typingOperators.map((operatorID, index) => (
                                                <AvatarOperator
                                                    key={index}
                                                    operatorData={operatorListCache.getItemByID(operatorID)}
                                                    statusClass="border white-border"
                                                />
                                            ))}
                                        <i className="las la-pencil-alt typing-icon"></i>
                                        <div className="typing-text">{translate.t("IsNowTyping")}...</div>
                                    </>
                                )}
                            </div>
                        </div>
                        {!!conversation.operators.filter((item) => item.operatorID == operatorData.operatorID)[0] ? (
                            <RespondPanel
                                conversationID={conversationID}
                                onFileSelectorOpen={() => {
                                    dropzoneRef.current.openFileSelector();
                                }}
                                ref={respondPanelRef}
                            />
                        ) : (
                            <div className="join-info">
                                <div className=" join-info-content">
                                    <p>{translate.t("YouAreNotConnectedToConversationYet")}</p>
                                    <button onClick={() => joinConversation()} className="btn blue">
                                        {translate.t("JoinConversation")}
                                    </button>
                                </div>
                            </div>
                        )}
                        <UploadFilesDropzone ref={dropzoneRef} onDrop={(files) => respondPanelRef.current.uploadFiles(files)} />
                    </div>
                    <div
                        className={`right-column ${showRightPanel ? "show" : ""} ${dashboardData.mobileMenuTabSelected == 3 ? "show-mobile" : ""}`}
                        ref={rightColumnRef}
                    >
                        <div className="close-column" onClick={() => setShowRightPanel(false)}>
                            <i className="icon las la-angle-right"></i>
                            <div className="background"></div>
                        </div>
                        <Scrollbars
                            renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
                            renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden", marginBottom: "0px" }} />}
                        >
                            <div className="right-column-content">
                                <div className="section">
                                    <div className="title">{translate.t("ConversationState")}</div>
                                    <div className="status-list">
                                        <div
                                            className={`radio-item inline ${!conversationCache.isMeInOperatorList() && "disabled"}`}
                                            onClick={() => changeConversationStatus(2)}
                                            data-tip-disable={conversationCache.isMeInOperatorList()}
                                            data-tip={translate.t("StateCanEditOnlyJoinedOperator")}
                                            data-for="top-solid-tooltip"
                                        >
                                            <div className={"radio-circle " + (conversation.conversationStateID == 2 ? "selected" : "")}></div>
                                            <div className="radio-item-text">{translate.t("ActiveConvState")}</div>
                                        </div>
                                        <div
                                            className={`radio-item inline ${!conversationCache.isMeInOperatorList() && "disabled"}`}
                                            onClick={() => changeConversationStatus(3)}
                                            data-tip-disable={conversationCache.isMeInOperatorList()}
                                            data-tip={translate.t("StateCanEditOnlyJoinedOperator")}
                                            data-for="top-solid-tooltip"
                                        >
                                            <div className={"radio-circle " + (conversation.conversationStateID == 3 ? "selected" : "")}></div>
                                            <div className="radio-item-text">{translate.t("DefferedConvState")}</div>
                                        </div>
                                        <div
                                            className={`radio-item inline ${!conversationCache.isMeInOperatorList() && "disabled"}`}
                                            onClick={() => changeConversationStatus(4)}
                                            data-tip-disable={conversationCache.isMeInOperatorList()}
                                            data-tip={translate.t("StateCanEditOnlyJoinedOperator")}
                                            data-for="top-solid-tooltip"
                                        >
                                            <div className={"radio-circle " + (conversation.conversationStateID == 4 ? "selected" : "")}></div>
                                            <div className="radio-item-text">{translate.t("SolvedConvState")}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="section">
                                    <div className="title">{translate.t("ConnectedOperators")}</div>
                                    <div className="operator-list">
                                        {conversation.operators.map((item, index) => (
                                            <div
                                                key={index}
                                                className="item"
                                                data-tip={!!item.nameInChatbox ? item.nameInChatbox : item.name + " " + item.surname}
                                            >
                                                <AvatarOperator operatorData={item} statusClass="border white-border" />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="section">
                                    <div className="title">
                                        {translate.t("Tasks")}
                                        {conversation.tasks.length > 0 && (
                                            <span className="section-status">
                                                ({conversation.tasks.filter((item) => item.isCompleted).length}/{conversation.tasks.length})
                                            </span>
                                        )}
                                        <div className="expandable-control" onClick={() => setTasksClosed(!tasksClosed)}>
                                            {tasksClosed ? <i className="las la-plus-square icon"></i> : <i className="las la-minus-square icon"></i>}
                                        </div>
                                    </div>
                                    {!tasksClosed && (
                                        <>
                                            <div className="task-list">
                                                {conversation.tasks.map((item, index) => (
                                                    <TaskItem
                                                        key={index}
                                                        task={item}
                                                        disabled={!conversationCache.isMeInOperatorList()}
                                                        showJoinRequirementTooltip={true}
                                                    />
                                                ))}
                                                {conversation.tasks.length == 0 && !showAddTask && (
                                                    <div className="no-items">{translate.t("NoTaskAddedYet")}</div>
                                                )}
                                                {showAddTask && (
                                                    <TaskItem
                                                        task={{ text: "" }}
                                                        conversationID={conversationID}
                                                        add={true}
                                                        onStorno={() => setShowAddTask(false)}
                                                        onSaved={() => setShowAddTask(false)}
                                                    />
                                                )}
                                            </div>
                                            {!showAddTask && conversationCache.isMeInOperatorList() && (
                                                <div className={`control-link`} onClick={() => setShowAddTask(true)}>
                                                    {translate.t("AddTask")}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="section">
                                    <div className="title">
                                        {translate.t("Notes")}
                                        {conversation.notes.length > 0 && <span className="section-status">({conversation.notes.length})</span>}
                                        <div className="expandable-control" onClick={() => setNotesClosed(!notesClosed)}>
                                            {notesClosed ? <i className="las la-plus-square icon"></i> : <i className="las la-minus-square icon"></i>}
                                        </div>
                                    </div>
                                    {!notesClosed && (
                                        <>
                                            <div className="note-list">
                                                {conversation.notes.map((item, index) => (
                                                    <NoteItem key={index} note={item} disabled={!conversationCache.isMeInOperatorList()} />
                                                ))}
                                                {conversation.notes.length == 0 && !showAddNote && (
                                                    <div className="no-items">{translate.t("NoNoteAddedYet")}</div>
                                                )}
                                                {showAddNote && (
                                                    <NoteItem
                                                        note={{ text: "" }}
                                                        conversationID={conversationID}
                                                        add={true}
                                                        onStorno={() => setShowAddNote(false)}
                                                        onSaved={() => setShowAddNote(false)}
                                                    />
                                                )}
                                            </div>
                                            {!showAddNote && conversationCache.isMeInOperatorList() && (
                                                <div className={`control-link`} onClick={() => setShowAddNote(true)}>
                                                    {translate.t("AddNote")}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="section">
                                    <div className="title">{translate.t("Contact")}</div>
                                    <div className="contact-item-list">
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-user icon"></i>
                                            </div>
                                            <div className="contact-title">{translate.t("User")}:&nbsp;</div>
                                            <div className="contact-text">
                                                {!!conversation.visitor.name ? conversation.visitor.name : conversation.visitor.visitorID}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-fingerprint icon"></i>
                                            </div>
                                            <span className="contact-title">{translate.t("ID")}</span>:&nbsp;{conversation.visitor.visitorID}
                                        </div>
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-map-marker-alt icon"></i>
                                            </div>
                                            <span className="contact-title">{translate.t("Place")}</span>:&nbsp;{conversation.visitor.countryName},{" "}
                                            {conversation.visitor.city}
                                        </div>
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-globe-europe icon"></i>
                                            </div>
                                            <span className="contact-title">{translate.t("UserIP")}</span>:&nbsp;{conversation.visitor.ip}
                                        </div>
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-desktop icon"></i>
                                            </div>
                                            <span className="contact-title">{translate.t("OperatingSystem")}</span>:&nbsp;{conversation.visitor.osName} (
                                            {conversation.visitor.osVersion})
                                        </div>
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-compass icon"></i>
                                            </div>
                                            <div className="title-block">
                                                <span className="contact-title">{translate.t("Browser")}</span>:&nbsp;{conversation.visitor.browserName} (
                                                {conversation.visitor.browserVersion})
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon-wrapper">
                                                <i className="las la-eye icon"></i>
                                            </div>
                                            {!!conversation.visitedPages[0] && (
                                                <div className="page">
                                                    <div className="title-block">
                                                        <span className="contact-title">{translate.t("URL")}</span>:&nbsp;
                                                        <Link
                                                            className="page-title"
                                                            to={{ pathname: conversation.visitedPages[0].url }}
                                                            title={conversation.visitor.pageTitle}
                                                            target="_blank"
                                                        >
                                                            {conversation.visitor.pageTitle}
                                                        </Link>
                                                    </div>
                                                    <Link
                                                        className="page-url"
                                                        to={{ pathname: conversation.visitedPages[0].url }}
                                                        title={conversation.visitedPages[0].url}
                                                        target="_blank"
                                                    >
                                                        {conversation.visitedPages[0].url}
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </>
            ) : (
                <Loading displayText={false} />
            )}
            <ReactTooltip id="top-solid-tooltip" effect="solid" place="top" />
            <ReactTooltip id="left-solid-tooltip" effect="solid" place="left" arrowColor="transparent" />
            <ReactTooltip effect="solid" place="bottom" />
            <ReactTooltip id="message-time-tooltip" effect="solid" place="left" arrowColor="transparent" />
        </>
    );
};

export default Conversation;
