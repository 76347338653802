import { makeVar } from '@apollo/client';
  
export const conversationFiltersVar = makeVar({
    filters:{
        conversationStateID:2,
        searchQuery:"",
        operatorID:""
    },
    order:{
        by:"default",
        direction:"ASC"
    }
});

export const conversationFiltersCache = {
    getFilters: () => {
        return conversationFiltersVar().filters;
    },
    getOrderBy: () => {
        return conversationFiltersVar().order;
    },
    setFilters: (data) => {
        const newData = { 
            ...(typeof data.conversationStateID != "undefined" 	&& { conversationStateID: data.conversationStateID }),
            ...(typeof data.searchQuery != "undefined" 		    && { searchQuery: data.searchQuery }),
            ...(typeof data.operatorID != "undefined" 		    && { operatorID: data.operatorID }),
        }
        conversationFiltersVar({...conversationFiltersVar(),filters:{...conversationFiltersVar().filters,...newData}});
    },
    setOrderBy: (data) => {
        const newData = { 
            ...(data.by 	    && { by: data.by }),
            ...(data.direction  && { direction: data.direction }),
        }
        conversationFiltersVar({...conversationFiltersVar(),order:{...conversationFiltersVar().order,...newData}});
    },
    setDefaultFilters: () => {        
        conversationFiltersVar({
            filters:{
                conversationStateID:2,
                searchQuery:"",
                operatorID:0
            },
            order:{
                by:"default",
                direction:"ASC"
            }
        });
    },
    
}