import React, { useEffect, useRef, useState } from 'react';
import Checkbox from '../../../GlobalComponents/Checkbox';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import translate from '../../Translations/index';
import taskEmiters from '../../Websocket/Emiters/taskEmiters';
import ReactTooltip from 'react-tooltip';
import { operatorListCache } from '../../../Library/Cache/operatorListCache';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';


const TaskItem = (props) => {
    const {task,add}                            = props;
    const [edit,setEdit]                        = useState(false);
    const [showDeleteModal,setShowDeleteModal]  = useState(false);
    const [inputVal,setInputVal]                = useState(task.text);
    const inputRef                              = useRef();
    
    const editClicked = () => {
        setEdit(true);
    }

    useEffect(() => {
        if(edit || add){
            inputRef.current.focus();
        }
    },[edit,add]);

    const storno = () => {
        setInputVal(task.text);
        setEdit(false);
        
        add && !!props.onStorno && props.onStorno()
    }
    const save = () => {
        if(add){
            taskEmiters.add({
                accountID:operatorCache.get().accountID,
                operatorAdd:operatorCache.get().operatorID,
                conversationID:props.conversationID,
                text:inputVal,
                isCompleted:false
            });
            !!props.onSaved && props.onSaved();
        }else{
            taskEmiters.edit({
                taskID:task.taskID,
                text:inputVal
            });
            //setInputVal(task.text);
            setEdit(false);
        }
    }

    const deleteTask = (status) => {
        if(status){            
            taskEmiters.delete(task.taskID);
        }
        setShowDeleteModal(false);
    }
    const setChecked = (isChecked) => {
        if(!props.disabled){
            conversationCache.editTask(task.taskID,{isCompleted:isChecked});
            taskEmiters.edit({
                taskID:task.taskID,
                isCompleted:isChecked
            });
        }
    }
	const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            if (!e.shiftKey) {         
                save();
            }
        }
	}

    const operatorAdded = operatorListCache.getItemByID(task.operatorAdd);
    return(    
        <div className='task-item'>  
            {!add && !edit &&
                <>         
                    <div onClick={() => setChecked(!task.isCompleted)} data-tip={!!props.disabled ? translate.t("TaskCanEditOnlyJoinedOperator") : (operatorAdded ? translate.t("AddedBy") + ": " + operatorAdded.name + " " + operatorAdded.surname + " (" + moment.utc(task.dateAdd).local().format("L LT") + ")" : "")} data-for="left-solid-tooltip">
                        <Checkbox checked={!!task.isCompleted} label={task.text} disabled={props.disabled} lineThroughWhenChecked={true} />
                    </div>
                    {!props.disabled &&
                        <div className='controls'>
                            <i className="las la-pencil-alt icon edit" onClick={() => editClicked()}></i>
                            <i className="las la-trash-alt icon delete" onClick={() => setShowDeleteModal(true)}></i>
                        </div>
                    }
                </>
            }
            {(!!add || !!edit) &&
                <div className='add-edit-block'>
                    <div className='form-group'>
                        <label>{!!add ? translate.t("NewTask") : translate.t("EditTask")}</label>
                        <div className='input-group'>
                            <TextareaAutosize  
                                minRows={1}  
                                maxRows={4}
                                ref={inputRef} 
                                value={inputVal} 
                                onChange={(e) => setInputVal(e.target.value)}  
                                onKeyPress={(e) => onKeyPress(e)}
                                className="textarea"
                            />
                        </div>
                    </div>
                    <div className='save'>
                        <div className='btn blue small' onClick={() => save()}>{translate.t("Save")}</div>
                        <div className='link' onClick={() => storno()}>{translate.t("Storno")}</div>
                    </div>
                </div>
            }
            {showDeleteModal &&
                <ModalNotification 
                    yesNo={true} 
                    content={translate.t("AreYouSureYouWantToDeleteTask",{taskName:task.text})} 
                    title={translate.t("DeleteTask")} 
                    rejectBtnAsLink={true} 
                    confirmBtnText={translate.t("Delete")} 
                    rejectBtnText={translate.t("CancelAction")} 
                    callback={(status) => deleteTask(status)} 
                />
            }

            <ReactTooltip id='task-tooltip' effect="solid" place="top"/>
       </div>
    );

}

export default TaskItem;