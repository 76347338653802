import { makeVar } from '@apollo/client';
  
export const settingsVar = makeVar({
    shortcutSign:"#", 
});

export const settingsCache = {
    get: () => {
        return settingsVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.shortcutSign 		&& { shortcutSign: data.shortcutSign })
        }
        settingsVar({...settingsVar(),...newData});
    }
}