/**
 *  komponenta pro štítky pro produkty
 */
import React from 'react';
import AvatarVisitor from '../../../GlobalComponents/AvatarVisitor';
import { matchPath, NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import translate from '../../../Modules/Translations/index';
import { getConversationStatusString, getNewMessageCountString } from '../../../Library/helper';
import AvatarOperator from '../../../GlobalComponents/AvatarOperator';
import operatorEmiters from '../../Websocket/Emiters/operatorEmiters';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import moment from 'moment';


function useParams(path) {
    const { pathname } = useLocation();
    const match = matchPath(pathname, { path })
    return match?.params || {}
}

const ConversationListItem = (props) => {
    const {conversationItem} = props;
    let { url }              = useRouteMatch();
    const history            = useHistory();	

    const { conversationID } = useParams('/account/:accountID/dashboard/conversations/:conversationID');
    
    const addMeAndOpenConversation = (e) => {
        e.preventDefault();
        const operatorData = operatorCache.get();
        operatorEmiters.joinConversation(conversationItem.conversationID,operatorData);        
        history.push(`${url}/${conversationItem.conversationID}`);
        
    }
    return(    
        <NavLink to={`${url}/${conversationItem.conversationID}`}  activeClassName="selected" className={"conversation-item"}>
            <div className='header'>
                <AvatarVisitor visitorData={conversationItem.visitor} statusClass={`border ${(conversationItem.conversationID == conversationID ? "light-blue-border" : "white-border")}`} />
                <div className="visitor-title"  title={conversationItem.visitor.name != "" ? conversationItem.visitor.name : conversationItem.visitorID}>{conversationItem.visitor.name != "" ? conversationItem.visitor.name : conversationItem.visitorID}</div>
                <div className='operator-list'>
                    {conversationItem.operators.slice(0, 2).map((item,index) => (
                        <AvatarOperator key={index} showStatus={false} operatorData={item} statusClass="border white-border"/>
                    ))}
                    {conversationItem.operators.length > 2 &&
                        <div className='avatar plus'><div className='avatar-content'>+{conversationItem.operators.length - 2}</div></div>
                    }
                    {conversationItem.operators.length == 0 &&
                        <div className={`add-me-and-open ${conversationItem.conversationStateID == 2 && !!conversationItem.waitingForResponseFrom && moment().unix() % 2 == 0 ? "fade" : ""}`} onClick={(e) => addMeAndOpenConversation(e)}><i className="las la-plus icon"></i></div>
                    }
                </div>                                    
            </div>
            <div className="message">{!!conversationItem.lastMessage && !!conversationItem.lastMessage.text ? conversationItem.lastMessage.text : ""}</div>
            <div className='footer'>
                <div className={'message-count ' + (conversationItem.unreadMessageCount > 0 ? "new" : "")}>{getNewMessageCountString(conversationItem.unreadMessageCount)}</div>
                <div className='conversation-status'>{getConversationStatusString(conversationItem)}</div>
            </div>
        </NavLink >
    );

}

export default ConversationListItem;