import React from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Loading from '../../../GlobalComponents/Loading';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import { useAdminRights } from '../../../Library/helper';
import translate from '../../Translations/index';
import Visitors from './Visitors';
import Operators from './Operators';


const People = (props) => {

    let { path, url }                   = useRouteMatch();

	const operatorData                  = operatorCache.get();
    const {loading:roleCheckLoading}    = useAdminRights(operatorData?.roleID,["1","2"],"/account");
        
    return(
        <>        
            {!roleCheckLoading ?
                <div className="people-page" >
                    <div className='menu'>
                        <NavLink to={`${url}/visitors`} activeClassName="selected" className="menu-item">
                            {translate.t("Visitors")}
                        </NavLink>
                        <NavLink to={`${url}/operators`} activeClassName="selected" className="menu-item">
                            {translate.t("Operators")}
                        </NavLink>
                    </div> 
                        
                    <Switch>                
                        <Route exact path={`${path}`}>
                            <Redirect to={`${url}/visitors`} />
                        </Route>				
                        <Route path={`${url}/visitors`}><Visitors /></Route>
                        <Route path={`${url}/operators`}><Operators /></Route>
                    </Switch> 

                </div>
                :
                <Loading displayText={false} />                
            }
        </>
    );

}

export default People;