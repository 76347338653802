/**
 *  komponenta pro štítky pro produkty
 */
import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import { conversationListCache, conversationListVar } from "../../../Library/Cache/conversationListCache";
import { useRouteMatch } from "react-router-dom";
import translate from "../../../Modules/Translations/index";
import ConversationListItem from "./ConversationListItem";
import { Scrollbars } from "react-custom-scrollbars-2";
import { conversationFiltersCache, conversationFiltersVar } from "../../../Library/Cache/conversationFiltersCache";
import { conversationCache } from "../../../Library/Cache/conversationCache";
import { addNotification } from "../../../Library/helper";
import conversationEmiters from "../../Websocket/Emiters/conversationEmiters";
import moment from "moment";

const ConversationActiveList = (props) => {
    let { url } = useRouteMatch();
    const conversationList = useReactiveVar(conversationListVar);
    const conversationFilters = useReactiveVar(conversationFiltersVar);
    const [loading, setLoading] = useState(true);
    const [timeRender, setTimeRender] = useState(Date.now());

    useEffect(() => {
        conversationListCache.loadActiveConversations().finally(() => {
            setLoading(false);
            setTimeout(() => {
                moveOldConversationsToPostponed();
            }, 1000);
        });

        const interval = setInterval(() => setTimeRender(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        conversationListCache.filter();
        conversationListCache.order();
    }, [conversationFilters.filters, conversationFilters.order]);

    const moveOldConversationsToPostponed = () => {
        const allItems = conversationListCache.get();
        const nowUtC = moment.utc().subtract("24", "hours");

        let moveToPostponeIDs = [];

        for (const item of allItems) {
            if (!!item.lastMessage && nowUtC.unix() > moment.utc(item.lastMessage.createdDate).unix()) {
                moveToPostponeIDs.push(item.conversationID);
            }
        }

        if (moveToPostponeIDs.length > 0) {
            addNotification("success", translate.t("ConversationMovedToPostponed", { count: moveToPostponeIDs.length }), translate.t("Warning"), 0);
            conversationEmiters.moveOldConversationsToPostponed(moveToPostponeIDs);
        }
    };

    return (
        <div className="list-content">
            {conversationListCache.getUnreadNotMyConversationCountSum() > 0 && conversationFilters.filters.operatorID != "" && (
                <div className="new-unvisible-messages-alert">
                    <i className="las la-eye icon"></i>
                    <div className="text">
                        <span className="reset-link" onClick={() => conversationFiltersCache.setDefaultFilters()}>
                            {translate.t("NewActiveConversations")}
                        </span>
                        {translate.t("WhichNeedsYourAttention")}:{" "}
                        <span className="unread-count">{conversationListCache.getUnreadNotMyConversationCountSum()}</span>
                    </div>
                </div>
            )}
            <Scrollbars
                renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
                renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden", marginBottom: "0px" }} />}
            >
                {!loading ? (
                    <>
                        {!!conversationList && !!conversationList.conversations && !!conversationList.conversations.length > 0 ? (
                            <>
                                {conversationList.conversations.map((item, index) => {
                                    return item.show ? <ConversationListItem conversationItem={item} key={index} /> : null;
                                })}
                            </>
                        ) : (
                            <div className="info">
                                <i className="las la-comment-slash icon"></i>
                                {translate.t("YouDontHaveAnyActiveConversations")}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="loading-content">
                        <Loading displayText={false} />
                    </div>
                )}
            </Scrollbars>
        </div>
    );
};

export default ConversationActiveList;
