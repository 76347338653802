/*
    Formulář pro přihlášení se do adminu
*/
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { addNotification, goToInitialPage, validateEmail } from "../../../Library/helper";
import translate from "../../Translations/index";
import { useApolloClient } from "@apollo/client";
import loadingIcon from "../../../Public/Images/loading_blue.svg";
import { REGISTER_CUSTOMER } from "../Queries/registration";
import { Link } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import { operatorCache, operatorVar } from "../../../Library/Cache/operatorCache";
import { ReactComponent as Logo } from "../../../Public/Images/logo.svg";

const Registration = (props) => {
    const client = useApolloClient();

    const operatorData = operatorVar();

    const [registrationData, setRegistrationData] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        if (operatorData && operatorData.operatorID) {
            goToInitialPage(props.history, translate.locale);
        }
    }, []);

    const [registerCustomer, { data, loading: loginLoading, error: loginError }] = useMutation(REGISTER_CUSTOMER, {
        onCompleted(data) {
            localStorage.setItem("token", data.registerCustomer.loginToken);

            operatorCache.set(data.registerCustomer.customer);
            goToInitialPage(props.history, translate.locale);
        },
        onError(err) {
            console.log(err);

            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const checkRegistration = () => {
        if (registrationData.email !== "" && registrationData.password !== "") {
            if (validateEmail(registrationData.email)) {
                registerCustomer({ variables: { customerRegistrationData: registrationData, lang: translate.locale } });
            } else {
                addNotification("danger", translate.t("WrongEmailFormat"), translate.t("Warning"));
            }
        } else {
            addNotification("danger", translate.t("NotFilledRequiredFields"), translate.t("Warning"));
        }
    };

    return (
        <main className="page login-page">
            <h1>{translate.t("CreateFreeAccount")}</h1>
            <div className="panel login-panel">
                <Logo className="logo" />

                <div className="form-group">
                    <label>{translate.t("Email")}</label>
                    <div className="input-group">
                        <input
                            type="email"
                            value={registrationData.email}
                            onChange={(e) => setRegistrationData({ ...registrationData, email: e.target.value })}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>{translate.t("Password")}</label>
                    <div className="input-group">
                        <input
                            type="password"
                            value={registrationData.password}
                            onChange={(e) => setRegistrationData({ ...registrationData, password: e.target.value })}
                        />
                    </div>
                </div>
                <button type="button" disabled={loginLoading} className="btn blue" onClick={() => checkRegistration()}>
                    {translate.t("SighUp")}
                    {loginLoading && <img src={loadingIcon} className="loading" />}
                </button>
                <div className="footer">
                    <Link to={"/login"} className="item">
                        {translate.t("SignInbackLink")}
                    </Link>
                </div>
            </div>
            <ReactNotifications />
        </main>
    );
};

export default Registration;
