import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AvatarPicker from '../../../../GlobalComponents/AvatarPicker';
import { addNotification } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { ADD_EDIT_CHATBOX, GET_INTRODUCTION_DATA } from '../../Queries/settings';
import {ReactComponent as Spinner} from '../../../../Public/Images/spinner.svg';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import { ACCOUNT_IMAGE_PATH } from '../../../../Config/urls';
import LivePreview from './LivePreview';
import Loading from '../../../../GlobalComponents/Loading';
import Checkbox from '../../../../GlobalComponents/Checkbox';


const Introduction = (props) => {
    
    const [maxCharCounts,setMaxCharCounts]    = useState({
        introductionText:120,
    })
    const [formData,setFormData]    = useState({
        chatboxID:"",
        introductionEnabled:0,
        introductionText:"",
        introductionGetName:1,
        introductionNameRequired:1,
        introductionGetEmail:0,
        introductionEmailRequired:0,
        introductionGetPhone:0,
        introductionPhoneRequired:0,
        introductionShowGdpr:0,
        introductionShowGdprCheckbox:0,
        introductionGdprUrl:""
    })

    const { data,loading, refetch } = useQuery(GET_INTRODUCTION_DATA,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            chatboxID:props.chatboxID
        },
        onCompleted(data){   
            if(!!data.chatbox){                
                setFormData({...formData,
                    chatboxID: data.chatbox.chatboxID,
                    introductionEnabled: data.chatbox.introductionEnabled,
                    introductionText: data.chatbox.introductionText,
                    introductionGetName: data.chatbox.introductionGetName,
                    introductionNameRequired: data.chatbox.introductionNameRequired,
                    introductionGetEmail: data.chatbox.introductionGetEmail,
                    introductionEmailRequired: data.chatbox.introductionEmailRequired,
                    introductionGetPhone: data.chatbox.introductionGetPhone,
                    introductionPhoneRequired: data.chatbox.introductionPhoneRequired,
                    introductionShowGdpr: data.chatbox.introductionShowGdpr,
                    introductionShowGdprCheckbox: data.chatbox.introductionShowGdprCheckbox,
                    introductionGdprUrl: data.chatbox.introductionGdprUrl,
                });
            }  
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });

    const [saveChatbox,{loading:saveLoading}] = useMutation(ADD_EDIT_CHATBOX,{
        onCompleted(data){            
			addNotification("success",translate.t("ChatboxSuccessfullySaved"),translate.t("Success"));
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const checkMaxCount = (text,paramToCheck) => {
        return text.length <= maxCharCounts[paramToCheck] ? true : false;
    }

    const saveData = () => {
        
        saveChatbox({
            variables:{
                addChatboxData:{
                    ...formData,
                    introductionEnabled: formData.introductionEnabled ? 1 : 0,
                    introductionGetName: formData.introductionGetName ? 1 : 0,
                    introductionNameRequired: formData.introductionNameRequired ? 1 : 0,
                    introductionGetEmail: formData.introductionGetEmail ? 1 : 0,
                    introductionEmailRequired: formData.introductionEmailRequired ? 1 : 0,
                    introductionGetPhone: formData.introductionGetPhone ? 1 : 0,
                    introductionPhoneRequired: formData.introductionPhoneRequired ? 1 : 0,
                    introductionShowGdpr: formData.introductionShowGdpr ? 1 : 0,
                    introductionShowGdprCheckbox: formData.introductionShowGdprCheckbox ? 1 : 0,
                }
            }
        })   
        
    }
    
    return(
        <div className="introduction">
            {!loading ? 
                <>
                    <div className='left-column'>            
                        <div className='group-title'>{translate.t("FirstContactWithCustomer")}</div>
                        <div className='form-group'>
                            <div className='input-group'>
                                <div className={`radio-item inline`} onClick={() => setFormData({...formData,introductionEnabled:1})} >									
                                    <div className={'radio-circle ' + (formData.introductionEnabled == 1 ? "selected" : "")}></div>
                                    <div className='radio-item-text'>{translate.t("NeedIntroduction")}</div>
                                </div>
                                <div className={`radio-item inline`} onClick={() => setFormData({...formData,introductionEnabled:0})} >									
                                    <div className={'radio-circle ' + (formData.introductionEnabled == 0 ? "selected" : "")}></div>
                                    <div className='radio-item-text'>{translate.t("DontNeedIntroduction")}</div>
                                </div>
                            </div>
                        </div>         
                        <div className='form-group'>
                            <label>{translate.t("InvitationText")}<span className='description right'>{formData.introductionText.length}/{maxCharCounts.introductionText}</span></label>
                            <div className='input-group'>
                                <textarea type="text" value={formData.introductionText} onChange={(e) => checkMaxCount(e.target.value,"introductionText") && setFormData({...formData,introductionText:e.target.value})} />
                            </div>
                        </div> 
                        <table className='full-width introduction-table'>
                            <thead>
                                <tr>
                                    <th className='left'>{translate.t("IntroductionRequiredFileds")}</th>
                                    <th>{translate.t("IntroductionRequired")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td className='left'>
                                            <div className='d-flex ' >
                                                <div onClick={() => setFormData({...formData,introductionGetName:!formData.introductionGetName})}>
                                                    <Checkbox label={translate.t("Name")} checked={formData.introductionGetName} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center' >
                                                <div onClick={() => setFormData({...formData,introductionNameRequired:!formData.introductionNameRequired})}>
                                                    <Checkbox  checked={formData.introductionNameRequired} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='left'>
                                            <div className='d-flex ' >
                                                <div onClick={() => setFormData({...formData,introductionGetEmail:!formData.introductionGetEmail})}>
                                                    <Checkbox label={translate.t("Email")} checked={formData.introductionGetEmail} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center' >
                                                <div onClick={() => setFormData({...formData,introductionEmailRequired:!formData.introductionEmailRequired})}>
                                                    <Checkbox  checked={formData.introductionEmailRequired} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='left'>
                                            <div className='d-flex ' >
                                                <div onClick={() => setFormData({...formData,introductionGetPhone:!formData.introductionGetPhone})}>
                                                    <Checkbox label={translate.t("Phone")} checked={formData.introductionGetPhone} />
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                            </tbody>
                        </table>

                        <div className='required-fields-header'>
                            <div className='group-title'>{translate.t("GDPR")}</div>       
                        </div>                           
                        <div className='form-group'>
                            <div className='gdpr-checkbox' onClick={() => setFormData({...formData,introductionShowGdpr:!formData.introductionShowGdpr})}>                            
                                <Checkbox label={translate.t("ShowGdpr")} checked={formData.introductionShowGdpr} />
                            </div>
                            <div className='gdpr-checkbox' onClick={() => setFormData({...formData,introductionShowGdprCheckbox:!formData.introductionShowGdprCheckbox})}>                            
                                <Checkbox label={translate.t("RewuireGdprConfirmation")} checked={formData.introductionShowGdprCheckbox} />
                            </div>
                        </div>  
                        
                        <div className='form-group'>
                            <label>{translate.t("LinkToGdprConditions")}</label>
                            <div className='input-group'>
                                <input type="text" value={formData.introductionGdprUrl} onChange={(e) => setFormData({...formData,introductionGdprUrl:e.target.value})} />
                            </div>
                        </div>  
                        <button className='btn blue' disabled={saveLoading} onClick={() => saveData()}>{!saveLoading ? translate.t("SaveChanges") : <Spinner />}</button>
                    </div>
                    <div className='right-column'>
                        <LivePreview type="introduction" previewData={{...data.chatbox,...formData}} />
                    </div>
                </>
                :
                <Loading displayText={false} marginTop="14rem" />
            }
        </div>
    );

}

export default Introduction;