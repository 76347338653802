import { makeVar } from "@apollo/client";
import { operatorCache } from "./operatorCache";

export const conversationVar = makeVar({
    conversationID: null,
    conversationStateID: 0,
    isInternalConversation: 0,
    visitor: {
        visitorID: null,
        accountID: null,
        name: null,
        shortcut: null,
        email: null,
        status: null,
        color: "",
        visitCount: null,
        lastVisit: null,
        lastVisitEnd: null,
        city: null,
        countryCode: null,
        countryName: null,
        continentCode: null,
        continentName: null,
        latitude: null,
        longitude: null,
        pageTitle: null,
        url: null,
        referrer: null,
        osName: null,
        osVersion: null,
        browserName: null,
        browserVersion: null,
        isMobile: null,
        isTablet: null,
        ip: null,
    },
    chatbox: {
        chatboxID: null,
        name: "",
        lang: "",
        systemOperatorName: "",
        systemOperatorPhoto: null,
    },
    operators: [],
    messages: [],
    visitedPages: [],
    visitorTyping: false,
    typingOperators: [],
    tasks: [],
    notes: [],
});

export const conversationCache = {
    get: () => {
        return conversationVar();
    },
    set: (data) => {
        const newData = {
            ...(data.conversationID && { conversationID: data.conversationID }),
            ...(data.conversationStateID && { conversationStateID: data.conversationStateID }),
            ...(data.isInternalConversation && { isInternalConversation: data.isInternalConversation }),
        };
        conversationVar({ ...conversationVar(), ...newData });
    },
    getVisitor: () => {
        return conversationVar().visitor;
    },
    setVisitor: (data) => {
        const newData = {
            ...(data.visitorID && { visitorID: data.visitorID }),
            ...(data.accountID && { accountID: data.accountID }),
            ...(data.name && { name: data.name }),
            ...(data.shortcut && { shortcut: data.shortcut }),
            ...(data.email && { email: data.email }),
            ...(data.status && { status: data.status }),
            ...(data.color && { color: data.color }),
            ...(data.visitCount && { visitCount: data.visitCount }),
            ...(data.lastVisit && { lastVisit: data.lastVisit }),
            ...(data.lastVisitEnd && { lastVisitEnd: data.lastVisitEnd }),
            ...(data.city && { city: data.city }),
            ...(data.countryCode && { countryCode: data.countryCode }),
            ...(data.countryName && { countryName: data.countryName }),
            ...(data.continentCode && { continentCode: data.continentCode }),
            ...(data.continentName && { continentName: data.continentName }),
            ...(data.latitude && { latitude: data.latitude }),
            ...(data.longitude && { longitude: data.longitude }),
            ...(data.pageTitle && { pageTitle: data.pageTitle }),
            ...(data.url && { url: data.url }),
            ...(data.referrer && { referrer: data.referrer }),
            ...(data.osName && { osName: data.osName }),
            ...(data.osVersion && { osVersion: data.osVersion }),
            ...(data.browserName && { browserName: data.browserName }),
            ...(data.browserVersion && { browserVersion: data.browserVersion }),
            ...(data.isMobile && { isMobile: data.isMobile }),
            ...(data.isTablet && { isTablet: data.isTablet }),
            ...(data.ip && { ip: data.ip }),
        };
        conversationVar({ ...conversationVar(), visitor: { ...conversationVar().visitor, ...newData } });
    },
    getChatbox: () => {
        return conversationVar().chatbox;
    },
    setChatbox: (data) => {
        const newData = {
            ...(data?.chatboxID && { chatboxID: data.chatboxID }),
            ...(data?.name && { name: data.name }),
            ...(data?.lang && { lang: data.lang }),
            ...(data?.systemOperatorName && { systemOperatorName: data.systemOperatorName }),
            ...(data?.systemOperatorPhoto && { systemOperatorPhoto: data.systemOperatorPhoto }),
        };
        conversationVar({ ...conversationVar(), chatbox: { ...conversationVar().chatbox, ...newData } });
    },
    getMessages: () => {
        return conversationVar().messages;
    },
    getOperators: () => {
        return conversationVar().operators;
    },
    setMessages: (data) => {
        let newData = [];
        for (const item of data) {
            newData.push({
                messageID: item.messageID,
                messageTypeID: item.messageTypeID,
                text: item.text,
                fromOperator: item.fromOperator,
                operatorID: item.operatorID,
                visitorID: item.visitorID,
                createdDate: item.createdDate,
                deliveredDate: item.deliveredDate,
                avatarData: item.avatarData,
                showReadByDetail: false,
                ratingSelected: item.ratingSelected,
                ratedValue: item.ratedValue,
                readBy: item.readBy ? item.readBy : [],
                files: item.files ? item.files : [],
            });
        }
        conversationVar({ ...conversationVar(), messages: newData });
    },
    setMoreMessages: (data) => {
        let newData = [];
        for (const item of data) {
            newData.push({
                messageID: item.messageID,
                messageTypeID: item.messageTypeID,
                text: item.text,
                fromOperator: item.fromOperator,
                operatorID: item.operatorID,
                visitorID: item.visitorID,
                createdDate: item.createdDate,
                deliveredDate: item.deliveredDate,
                avatarData: item.avatarData,
                showReadByDetail: false,
                ratingSelected: item.ratingSelected,
                ratedValue: item.ratedValue,
                readBy: item.readBy ? item.readBy : [],
                files: item.files ? item.files : [],
            });
        }
        conversationVar({ ...conversationVar(), messages: [...newData, ...conversationVar().messages] });
    },
    setTasks: (data) => {
        let newData = [];
        for (const item of data) {
            newData.push({
                taskID: item.taskID,
                isCompleted: item.isCompleted,
                text: item.text,
                operatorAdd: item.operatorAdd,
                dateAdd: item.dateAdd,
            });
        }
        conversationVar({ ...conversationVar(), tasks: newData });
    },
    addTask: (data) => {
        conversationVar({
            ...conversationVar(),
            tasks: [
                ...conversationVar().tasks,
                {
                    taskID: data.taskID,
                    isCompleted: data.isCompleted,
                    text: data.text,
                    operatorAdd: data.operatorAdd,
                    dateAdd: data.dateAdd,
                },
            ],
        });
    },
    editTask: (taskID, data) => {
        let items = [...conversationVar().tasks];
        for (const key in items) {
            if (items[key].taskID == taskID) {
                items[key] = {
                    ...items[key],
                    ...(typeof data.isCompleted != "undefined" && { isCompleted: data.isCompleted }),
                    ...(!!data.text && { text: data.text }),
                };
            }
        }
        conversationVar({ ...conversationVar(), tasks: [...items] });
    },
    removeTask: (taskID) => {
        const allItems = conversationVar().tasks;
        const newItems = allItems.filter(function (value, index) {
            return value.taskID != taskID;
        });

        conversationVar({ ...conversationVar(), tasks: newItems });
    },
    setNotes: (data) => {
        let newData = [];
        for (const item of data) {
            newData.push({
                noteID: item.noteID,
                text: item.text,
                operatorAdd: item.operatorAdd,
                dateAdd: item.dateAdd,
            });
        }
        conversationVar({ ...conversationVar(), notes: newData });
    },
    addNote: (data) => {
        conversationVar({
            ...conversationVar(),
            notes: [
                ...conversationVar().notes,
                {
                    noteID: data.noteID,
                    text: data.text,
                    operatorAdd: data.operatorAdd,
                    dateAdd: data.dateAdd,
                },
            ],
        });
    },
    editNote: (noteID, data) => {
        let items = [...conversationVar().notes];
        for (const key in items) {
            if (items[key].noteID == noteID) {
                items[key] = {
                    ...items[key],
                    ...(!!data.text && { text: data.text }),
                };
            }
        }
        conversationVar({ ...conversationVar(), notes: [...items] });
    },
    removeNote: (noteID) => {
        const allItems = conversationVar().notes;
        const newItems = allItems.filter(function (value, index) {
            return value.noteID != noteID;
        });

        conversationVar({ ...conversationVar(), notes: newItems });
    },
    setOperators: (data) => {
        let newData = [];
        for (const item of data) {
            newData.push({
                operatorID: item.operatorID,
                name: item.name,
                surname: item.surname,
                nameInChatbox: item.nameInChatbox,
                img: item.img,
                color: item.color,
                shortcut: item.shortcut,
                status: item.status,
            });
        }
        conversationVar({ ...conversationVar(), operators: newData });
    },
    editOperator: (operatorID, data) => {
        let items = [...conversationVar().operators];
        for (const key in items) {
            if (items[key].operatorID == operatorID) {
                items[key] = {
                    ...items[key],
                    ...(!!data.name && { name: data.name }),
                    ...(!!data.surname && { surname: data.surname }),
                    ...(!!data.nameInChatbox && { nameInChatbox: data.nameInChatbox }),
                    ...(!!data.img && { img: data.img }),
                    ...(!!data.color && { color: data.color }),
                    ...(!!data.shortcut && { shortcut: data.shortcut }),
                    ...(!!data.status && { status: data.status }),
                };
            }
        }
        conversationVar({ ...conversationVar(), operators: [...items] });
    },
    setVisitedPages: (data) => {
        let newData = [];
        for (const item of data) {
            newData.push({
                url: item.url,
                dateAdd: item.dateAdd,
            });
        }
        conversationVar({ ...conversationVar(), visitedPages: newData });
    },
    addVisitedPage: (data) => {
        conversationVar({
            ...conversationVar(),
            visitedPages: [
                ...conversationVar().visitedPages,
                {
                    url: data.url,
                    pageTitle: data.pageTitle,
                    dateAdd: data.dateAdd,
                },
            ],
        });
    },
    addMessageItem: (data) => {
        conversationVar({
            ...conversationVar(),
            messages: [
                ...conversationVar().messages,
                {
                    messageID: data.messageID,
                    messageTypeID: data.messageTypeID,
                    text: data.text,
                    fromOperator: data.fromOperator,
                    operatorID: data.operatorID,
                    visitorID: data.visitorID,
                    createdDate: data.createdDate,
                    deliveredDate: data.deliveredDate,
                    avatarData: data.avatarData,
                    ratingSelected: data.ratingSelected,
                    ratedValue: data.ratedValue,
                    showReadByDetail: false,
                    readBy: data.readBy ? data.readBy : [],
                    files: data.files ? data.files : [],
                },
            ],
        });
    },
    editMessageItem: (messageID, data) => {
        let items = [...conversationVar().messages];
        for (const key in items) {
            if (items[key].messageID == messageID) {
                items[key] = {
                    ...items[key],
                    ...(typeof data.ratingSelected != "undefined" && { ratingSelected: data.ratingSelected }),
                    ...(typeof data.ratedValue != "undefined" && { ratedValue: data.ratedValue }),
                    ...(typeof data.showReadByDetail != "undefined" && { showReadByDetail: data.showReadByDetail }),
                };
            }
        }
        conversationVar({ ...conversationVar(), messages: items });
    },
    addReadByItems: (readMessageArray) => {
        let messages = [...conversationVar().messages];

        for (let message of messages) {
            for (let readMessage of readMessageArray) {
                if (message.messageID == readMessage.messageID) {
                    message.readBy.push({
                        visitorID: readMessage.visitorID,
                        operatorID: readMessage.operatorID,
                        readDate: readMessage.readDate,
                    });
                }
            }
        }
        conversationVar({ ...conversationVar(), messages });
    },
    getUnreadMessagesCount: () => {
        const messages = conversationVar().messages;
        let unreadMessagesCount = 0;
        /*
        for(const message of messages){

        }
        */
        return unreadMessagesCount;
    },
    addOperatorItemIfNotExist: (data) => {
        const allItems = conversationVar().operators;

        const itemExist = allItems.filter(function (value, index) {
            return value.operatorID == data.operatorID;
        });

        if (itemExist.length == 0) {
            conversationVar({
                ...conversationVar(),
                operators: [
                    ...conversationVar().operators,
                    {
                        operatorID: data.operatorID,
                        name: data.name,
                        surname: data.surname,
                        nameInChatbox: data.nameInChatbox,
                        img: data.img,
                        color: data.color,
                        shortcut: data.shortcut,
                        status: data.status,
                    },
                ],
            });
        }
    },
    removeOperatorItem: (operatorID) => {
        const allItems = conversationVar().operators;
        const newItems = allItems.filter(function (value, index) {
            return value.operatorID != operatorID;
        });

        conversationVar({ ...conversationVar(), operators: newItems });
    },
    operatorExists: (operatorID) => {
        const allItems = conversationVar().operators;

        const itemExist = allItems.filter(function (value, index) {
            return value.operatorID == operatorID;
        });

        return itemExist.length > 0;
    },
    isMeInOperatorList: () => {
        const operatorID = operatorCache.get().operatorID;
        const allItems = conversationVar().operators;

        const itemExist = allItems.filter(function (value, index) {
            return value.operatorID == operatorID;
        });

        return itemExist.length > 0;
    },
    clearTypingOperators: () => {
        conversationVar({ ...conversationVar(), typingOperators: [] });
    },
    addTypingOperator: (operatorID) => {
        const allTypingOperators = conversationVar().typingOperators;

        const itemExist = allTypingOperators.filter(function (value, index) {
            return value == operatorID;
        });

        if (itemExist.length == 0) {
            conversationVar({ ...conversationVar(), typingOperators: [...conversationVar().typingOperators, operatorID] });
        }
    },
    removeTypingOperator: (operatorID) => {
        const allItems = conversationVar().typingOperators;
        const newItems = allItems.filter(function (value, index) {
            return value != operatorID;
        });

        conversationVar({ ...conversationVar(), typingOperators: [...newItems] });
    },
    setVisitorTyping: (isTyping) => {
        conversationVar({ ...conversationVar(), visitorTyping: isTyping });
    },
};
