import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { addNotification, checkAdminRights } from "../../../../Library/helper";
import Loading from "../../../../GlobalComponents/Loading";
import translate from "../../../Translations/index";
import { operatorVar } from "../../../../Library/Cache/operatorCache";
import { useHistory } from "react-router-dom";
import Dropdown from "../../../../GlobalComponents/Dropdown";
import SearchInput from "../../../../GlobalComponents/SearchInput";
import { ADD_EDIT_SHORTCUT, DELETE_SHORTCUT, GET_OPERATOR_SHORTCUTS } from "../../Queries/settings";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import Switcher from "../../../../GlobalComponents/Switcher";
import AddEditShortcut from "./AddEditShortcut";
import ReactPaginate from "react-paginate";
import { ceil } from "mathjs";

const Shortcuts = (props) => {
    const operatorData = operatorVar();
    checkAdminRights(operatorData, ["1", "2", "3"], props);
    const history = useHistory();

    const [shortcutList, setShortcutList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(0);
    const [editID, setEditID] = useState(null);
    const [deleteInfo, setDeleteInfo] = useState({
        show: false,
        title: "",
        deleteID: null,
    });
    const [showAdd, setShowAdd] = useState(false);
    const [filters, setFilters] = useState({
        searchQuery: "",
        orderBy: "id",
        operatorID: "",
        offset: 0,
        limit: 10,
    });

    const { data, loading, refetch } = useQuery(GET_OPERATOR_SHORTCUTS, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            accountID: operatorData.accountID,
            operatorID: operatorData.operatorID,
            filters: filters,
        },
        onCompleted(data) {
            setShortcutList(data.shortcutList);
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const [switchActive] = useMutation(ADD_EDIT_SHORTCUT, {
        onCompleted(data) {
            //find item by data.shortcutID and update active
            let updatedShortcuts = shortcutList.map((item) =>
                item.shortcutID == data.addEditShortcut.shortcutID ? { ...item, active: data.addEditShortcut.active } : item
            );
            setShortcutList(updatedShortcuts);
            addNotification("success", translate.t("ActiveStateSuccessfullyChanged"));
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });
    const [deleteCheckbox] = useMutation(DELETE_SHORTCUT, {
        onCompleted(data) {
            refetch();
            addNotification("success", translate.t("ShortcutSuccessfullyDeleted"));
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const initiateDelete = (deleteID, title) => {
        setDeleteInfo({ show: true, deleteID, title });
    };

    const deleteConfirmation = (status) => {
        if (status) {
            deleteCheckbox({ variables: { shortcutID: deleteInfo.deleteID } });
        }
        setDeleteInfo({ ...deleteInfo, show: false });
    };

    const showAddEdit = (editID) => {
        setEditID(editID);
        setShowAdd(true);
    };

    const changePage = (event) => {
        setFilters({ ...filters, offset: event.selected * filters.limit });
        setSelectedPage(event.selected);
    };
    console.log(data);
    return (
        <div>
            <>
                {!!data?.shortcutListCount || !!filters.operatorID || !!filters.searchQuery ? (
                    <>
                        <div className="controls list-header">
                            <div className="form-group search">
                                <label>{translate.t("SearchVisitor")}</label>
                                <div className="input-group has-addon-left has-addon-right search-input">
                                    <span className="addon left">
                                        <i className="las la-search icon"></i>
                                    </span>
                                    <SearchInput
                                        value={filters.searchQuery}
                                        onChange={(text) => {
                                            setFilters({ ...filters, searchQuery: text });
                                            setSelectedPage(0);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-group filter ml-auto">
                                <label>{translate.t("FilterTitle")}</label>
                                <Dropdown
                                    className="filled"
                                    content={
                                        <>
                                            <i className="las la-filter icon"></i>
                                            {filters.operatorID == "" && translate.t("ShowAll")}
                                            {filters.operatorID == operatorData.operatorID && translate.t("ShowMy")}
                                        </>
                                    }
                                    arrowLeft="auto"
                                    arrowRight="1.1rem"
                                    contentWidth="21rem"
                                    showDropdown={true}
                                    dropdownContent={
                                        <>
                                            <div
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                    setFilters({ ...filters, operatorID: "" });
                                                    setSelectedPage(0);
                                                }}
                                            >
                                                <div className="text">{translate.t("ShowAll")}</div>
                                            </div>
                                            <div
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                    setFilters({ ...filters, operatorID: operatorData.operatorID });
                                                    setSelectedPage(0);
                                                }}
                                            >
                                                <div className="text">{translate.t("ShowMy")}</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </div>
                        {!loading ? (
                            <table className="full-width hoverable">
                                <thead>
                                    <tr>
                                        <th className="left">{translate.t("ShortcutNameTitle")}</th>
                                        <th className="left">{translate.t("ShortcutMessageTitle")}</th>
                                        <th>{translate.t("ForWhoShortcutTitle")}</th>
                                        <th className="right">{translate.t("Active")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!data.shortcutListCount ? (
                                        <>
                                            {shortcutList.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="left">
                                                        <div className="controlls">
                                                            {item.shortcut}
                                                            <div className="hidden-content">
                                                                <i onClick={() => showAddEdit(item.shortcutID)} className="las la-pencil-alt icon"></i>
                                                                <i
                                                                    onClick={() => initiateDelete(item.shortcutID, item.shortcut)}
                                                                    className="las la-trash-alt icon"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="left">{item.text}</td>
                                                    <td>
                                                        {item.operatorID == operatorData.operatorID && translate.t("ForMy")}
                                                        {item.operatorID == "" && translate.t("ForAll")}
                                                    </td>
                                                    <td className="right">
                                                        <Switcher
                                                            className="ml-auto"
                                                            active={item.active}
                                                            onChange={(active) =>
                                                                switchActive({
                                                                    variables: { addEditData: { shortcutID: item.shortcutID, active: active ? 1 : 0 } },
                                                                })
                                                            }
                                                            waitForChange={true}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="4">{translate.t("NoResultsFound")}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <Loading displayText={false} />
                        )}
                        <div className="list-controls">
                            <button className="btn blue" onClick={() => showAddEdit(0)}>
                                {translate.t("AddShortcut")}
                            </button>
                        </div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=""
                            onPageChange={(event) => changePage(event)}
                            pageRangeDisplayed={5}
                            pageCount={data?.shortcutListCount > filters.limit ? ceil(data.shortcutListCount / filters.limit, 0) : 0}
                            previousLabel=""
                            renderOnZeroPageCount={null}
                            containerClassName="list-pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousClassName="d-none"
                            previousLinkClassName="page-link"
                            nextClassName="d-none"
                            nextLinkClassName="page-link"
                            forcePage={selectedPage}
                        />
                    </>
                ) : (
                    <>
                        {!!data && (
                            <div className="no-items">
                                <i className="las la-hashtag icon"></i>
                                <div className="title">{translate.t("ThereAreNoShortcutHere")}</div>
                                <button className="btn blue" onClick={() => showAddEdit(0)}>
                                    {translate.t("CreateShortcut")}
                                </button>
                                <div className="info">{translate.t("AddYourFirstShortcut")}</div>
                            </div>
                        )}
                    </>
                )}
            </>

            {showAdd && <AddEditShortcut shortcutID={editID} callback={(state) => setShowAdd(state)} updated={(data) => refetch()} />}
            {deleteInfo.show && (
                <ModalNotification
                    yesNo={true}
                    title={translate.t("DeleteShortcut")}
                    content={translate.t("AreYouSureYouWantToDeleteShortcut", { shortcutName: deleteInfo.title })}
                    rejectBtnAsLink={true}
                    confirmBtnText={translate.t("Delete")}
                    rejectBtnText={translate.t("CancelAction")}
                    callback={(status) => deleteConfirmation(status)}
                />
            )}
        </div>
    );
};

export default Shortcuts;
