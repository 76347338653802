import { io } from "socket.io-client";
import registerMessageHandlers from "./EventHandlers/messageHandlers";
import registerOperatorHandlers from "./EventHandlers/operatorHandlers";
import registerVisitorHandlers from "./EventHandlers/visitorHandlers";
import registerConversationHandlers from "./EventHandlers/conversationHandlers";
import registerTaskHandlers from "./EventHandlers/taskHandlers";
import registerNoteHandlers from "./EventHandlers/noteHandlers";
import { addNotification } from "../../Library/helper";
import translate from "../Translations/index";
//import jwt from "jsonwebtoken";

let webSocket;

export const startWebSocket = () => {
    webSocket = new Websocket();
};
export const getWebSocket = () => {
    return webSocket;
};

class Websocket {
    constructor() {
        this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
            transports: ["websocket"],
            /**
             * 
             * TO-DO:
            *  Nastavit autentikaci s tokenem až se vyřeší problém s jsonwebtoken zde:
            * https://github.com/facebook/create-react-app/issues/12072#issuecomment-1118808278

            auth: {
                token: this.getToken(),
            },

            */
            //reconnection:false,
        });

        this.socket.on("connect_error", (err) => {
            /**
             * 
             * TO-DO:
            *  Nastavit autentikaci s tokenem až se vyřeší problém s jsonwebtoken zde:
            * https://github.com/facebook/create-react-app/issues/12072#issuecomment-1118808278
            
            
            this.socket.auth.token = this.getToken();

            */
            //unable to connect to socketIO server
            if (err.message == "websocket error") {
                addNotification("danger", translate.t("ConnectionToServerFailedReconnecting"), translate.t("Error"));

                //token expired error
            } else if (!!err.data && err.data.code == 401) {
                //addNotification("danger",translate.t("UnauthorizedAccessMakingNewConnection"),translate.t("Error"));
                setTimeout(() => {
                    console.log(translate.t("UnauthorizedAccessMakingNewConnection"));
                    this.socket.disconnect();
                    this.socket.connect();
                }, 1000);
            }
        });

        this.registerHandlers();
    }
    /**
     * 
     * TO-DO:
     * Nastavit autentikaci s tokenem až se vyřeší problém s jsonwebtoken zde:
     * https://github.com/facebook/create-react-app/issues/12072#issuecomment-1118808278
    
    getToken = () => {
        return jwt.sign({ signedFrom: "dashboard" }, process.env.REACT_APP_JWT_SOCKETIO_SECRET_KEY, { expiresIn: "1m" }); //5m - 5 minutes expiration
    };

    */

    registerHandlers = () => {
        registerVisitorHandlers(this.socket);
        registerOperatorHandlers(this.socket);
        registerMessageHandlers(this.socket);
        registerConversationHandlers(this.socket);
        registerTaskHandlers(this.socket);
        registerNoteHandlers(this.socket);
    };

    close = () => {
        this.socket.close();
    };
}
