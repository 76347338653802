import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import translate from "../../../Translations/index";
import Modal from "react-modal";
import { ADD_EDIT_SHORTCUT, GET_SHORTCUT } from "../../Queries/settings";
import { addNotification, validateIPaddress } from "../../../../Library/helper";
import { operatorCache } from "../../../../Library/Cache/operatorCache";
import Loading from "../../../../GlobalComponents/Loading";
import { ReactComponent as Spinner } from "../../../../Public/Images/spinner.svg";
import Switcher from "../../../../GlobalComponents/Switcher";

const AddEditShortcut = (props) => {
    const operatorData = operatorCache.get();

    const [formData, setFormData] = useState({
        shortcutID: props.shortcutID,
        accountID: operatorData.accountID,
        operatorID: operatorData.operatorID,
        shortcut: "",
        text: "",
        active: 1,
        lang: translate.locale,
    });

    const { loading } = useQuery(GET_SHORTCUT, {
        fetchPolicy: "network-only",
        variables: {
            shortcutID: props.shortcutID,
        },
        onCompleted(data) {
            if (!!data.shortcut) {
                setFormData({
                    ...formData,
                    operatorID: data.shortcut.operatorID,
                    shortcut: data.shortcut.shortcut,
                    text: data.shortcut.text,
                    active: data.shortcut.active,
                });
            }
        },
        onError(err) {
            console.log(err);
            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });

    const [addShortcut, { loading: saveLoading }] = useMutation(ADD_EDIT_SHORTCUT, {
        onCompleted(data) {
            if (!formData.shortcutID) {
                setFormData({
                    ...formData,
                    shortcutID: data.addEditShortcut.shortcutID,
                });
            }
            addNotification("success", translate.t("ShortcutSuccessfullySaved"), translate.t("Success"));
            !!props.updated && props.updated(data.addEditShortcut);
        },
        onError(err) {
            console.log(err);
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const sendAddShortcut = () => {
        if (formData.shortcut != "" && formData.text != "") {
            const sendData = {
                ...formData,
                active: parseInt(formData.active),
            };
            addShortcut({
                variables: {
                    addEditData: sendData,
                },
            });
        } else {
            addNotification("danger", translate.t("YouHaveNotFilledRequiredFields"), translate.t("Warning"));
        }
    };

    return (
        <Modal className="modal center md" closeTimeoutMS={10} isOpen={true} onRequestClose={() => props.callback(false)}>
            <div className="modal-content">
                <button type="button" className="close" onClick={() => props.callback(false)}>
                    <i className="las la-times icon"></i>
                </button>
                <div className="modal-header">
                    <h4 className="modal-title">{!!formData.shortcutID ? translate.t("EditShortcut") : translate.t("AddShortcut")}</h4>
                </div>
                <div className="modal-body">
                    {!loading ? (
                        <div className="form">
                            <div className="form-group">
                                <label>{translate.t("Shortcut")}</label>
                                <div class="input-group has-addon-left has-addon-right search-input">
                                    <span class="addon left">
                                        <i class="las la-hashtag icon"></i>
                                    </span>
                                    <input
                                        type="text"
                                        value={formData.shortcut}
                                        onChange={(e) => setFormData({ ...formData, shortcut: e.target.value })}
                                        placeholder={translate.t("Shortcut")}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{translate.t("Message")}</label>
                                <div class="input-group">
                                    <textarea
                                        className="disable-resize"
                                        rows={5}
                                        type="text"
                                        value={formData.text}
                                        onChange={(e) => setFormData({ ...formData, text: e.target.value })}
                                        placeholder={translate.t("MessageText")}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-0">
                                <label>{translate.t("Active")}</label>
                                <div class="input-group">
                                    <Switcher
                                        active={formData.active}
                                        onChange={(active) =>
                                            setFormData({
                                                ...formData,
                                                active: active ? 1 : 0,
                                            })
                                        }
                                        waitForChange={true}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Loading displayText={false} />
                    )}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn blue" onClick={() => sendAddShortcut()}>
                        {!saveLoading ? !!formData.shortcutID ? translate.t("Edit") : translate.t("Add") : <Spinner />}
                    </button>
                    <div className="link ml-auto" onClick={() => props.callback(false)}>
                        {translate.t("Cancel")}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditShortcut;
