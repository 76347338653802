import gql from 'graphql-tag';

export const GET_VISITORS = gql`
    query Visitors($filterData:VisitorsFilterData){
        visitors(filterData:$filterData){
            visitorID
            accountID
            name
            shortcut
            email
            status
            color
            visitCount
            lastVisit  
            city          
            countryCode
            countryName
            continentCode
            continentName
            latitude
            longitude
            pageTitle
            url
            referrer
            osName
            osVersion
            browserName
            browserVersion
            isMobile
            isTablet
            visitedPages{
                url
                pageTitle
                dateAdd
                dateLeave
            }
            conversation{
                conversationID
                conversationStateID
                operators{                    
                    operatorID
                    name
                    surname
                    nameInChatbox
                    img
                    shortcut
                    color
                }
            }
        }
    }
`;


export const GET_INACTIVE_VISITORS = gql`
    query InactiveVisitors($filterData:VisitorsFilterData){
        visitors(filterData:$filterData){
            visitorID
            accountID
            name
            shortcut
            email
            status
            color
            visitCount
            lastVisit  
            city          
            countryCode
            countryName
            continentCode
            continentName
            latitude
            longitude
            pageTitle
            url
            referrer
            osName
            osVersion
            browserName
            browserVersion
            isMobile
            isTablet
            visitedPages{
                url
                pageTitle
                dateAdd
                dateLeave
            }
            conversation{
                conversationID
                conversationStateID
                operators{                    
                    operatorID
                    name
                    surname
                    nameInChatbox
                    img
                    shortcut
                    color
                }
            }
        }
        visitorsCount(filterData:$filterData)
    }
`;
