import React, {  useEffect, useState } from 'react';
import translate from './index';
import Loading from '../../GlobalComponents/Loading';

const WithTranslation = (props) => {
	const [loading,setLoading] = useState(true);
			
    useEffect(() => {
        setLang();
    }, []);

	const setLang = async () => {
		try {
			
			let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : "";

			if(lang === ""){
				lang = 'cs';
				localStorage.setItem('lang',lang);
			}
			translate.locale = lang;

			setLoading(false);
			
		} catch(error) {
			// error reading value
			//this.setState({loading:false});
			alert('Error: ' + error);
		}
	}
	
	if(loading){
		return (
			<div className="d-flex align-items-center h-100">
				<Loading displayText={false} />
			</div>
		);
	}else{
		return (	
			props.children
		);
	}


  
}

export default WithTranslation;