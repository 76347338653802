/**
 *  komponenta pro štítky pro produkty
 */
import React, { useEffect, useRef, useState } from "react";
import { addNotification, checkAdminRights, useForceUpdate } from "../../../Library/helper";
import Loading from "../../../GlobalComponents/Loading";
import translate from "../../Translations/index";
import ConversationLists from "./ConversationLists";
import Conversation from "./Conversation";
import { operatorVar } from "../../../Library/Cache/operatorCache";
import { matchPath, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import Dropdown from "../../../GlobalComponents/Dropdown";
import { conversationFiltersCache, conversationFiltersVar } from "../../../Library/Cache/conversationFiltersCache";
import { conversationListCache, conversationListVar } from "../../../Library/Cache/conversationListCache";
import SearchInput from "../../../GlobalComponents/SearchInput";
import { dashboardCache, dashboardVar } from "../../../Library/Cache/dashboardCache";
import { conversationVar } from "../../../Library/Cache/conversationCache";
import { Scrollbars } from "react-custom-scrollbars-2";

const ConversationsPage = (props) => {
    //zjistime jestli ma prihlaseny administrator spravna prava

    let { path, url } = useRouteMatch();
    const history = useHistory();
    const operatorData = operatorVar();
    const searchRef = useRef();
    const dashboardData = useReactiveVar(dashboardVar);
    const [addFilterDropdownOpened, setAddFilterDropdownOpened] = useState(false);

    checkAdminRights(operatorData, ["1", "2", "3"], props);

    const [orderByList, setOrderByList] = useState([
        { title: translate.t("OrderByWaitingTime"), value: "default" },
        { title: translate.t("OrderByStatus"), value: "status" },
    ]);
    const [filterList, setFilterList] = useState([{ title: translate.t("ShowOnlyMy"), icon: "la-heart", value: 1, type: "show-only-my" }]);
    const conversationFilters = useReactiveVar(conversationFiltersVar);
    const conversationList = useReactiveVar(conversationListVar);
    const conversation = useReactiveVar(conversationVar);
    const [unreadConversationSum, setUnreadConversationSum] = useState(0);
    const disableFilterScrollbar = useRef(true);
    const forceUpdate = useForceUpdate();

    React.useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 992) {
                if (!disableFilterScrollbar.current) {
                    console.log("scrollbar disabled");
                    disableFilterScrollbar.current = true;
                    forceUpdate();
                }
            } else {
                if (disableFilterScrollbar.current) {
                    console.log("scrollbar enabled");
                    disableFilterScrollbar.current = false;
                    forceUpdate();
                }
            }
        }
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => document.removeEventListener("resize", handleResize);
    });

    useEffect(() => {
        setUnreadConversationSum(conversationListCache.getUnreadConversationCountSum());
    }, [conversationList.conversations]);

    const changeConversationTab = (conversationStateID) => {
        if (conversationStateID != conversationFilters.filters.conversationStateID) {
            conversationFiltersCache.setFilters({ conversationStateID, searchQuery: "" });
            history.push(url);
        }
    };

    const addFilter = (filterType) => {
        if (filterType == "show-only-my") {
            conversationFiltersCache.setFilters({ operatorID: operatorData.operatorID });
        }
    };
    const removeFilter = (filterType) => {
        if (filterType == "show-only-my") {
            conversationFiltersCache.setFilters({ operatorID: "" });
        }
    };

    return (
        <div className="conversation-page">
            <div className={`filter-panel ${dashboardData.mobileMenuTabSelected == 1 ? "show-mobile" : ""}`}>
                <div className="menu">
                    <div
                        className={`menu-item search ${conversationFilters.filters.conversationStateID == null ? "search-open" : ""}`}
                        onClick={() => changeConversationTab(null)}
                    >
                        {conversationFilters.filters.conversationStateID != null ? (
                            <i className="las la-search search"></i>
                        ) : (
                            <div className="input-group has-addon-left has-addon-right search-input">
                                <span className="addon left">
                                    <i className="las la-search search"></i>
                                </span>
                                <span className="addon right" onClick={() => changeConversationTab(2)}>
                                    <i className="las la-times-circle close"></i>
                                </span>
                                <SearchInput
                                    escPressed={() => changeConversationTab(2)}
                                    focusOnShow={true}
                                    placeholder={translate.t("SearchInAllConversations")}
                                    value={conversationFilters.filters.searchQuery}
                                    onChange={(text) => conversationFiltersCache.setFilters({ searchQuery: text })}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={`menu-item ${conversationFilters.filters.conversationStateID == 2 ? "selected" : ""}`}
                        onClick={() => changeConversationTab(2)}
                    >
                        {translate.t("Active")}
                        {unreadConversationSum > 0 && <span className="badge">{unreadConversationSum}</span>}
                    </div>
                    <div
                        className={`menu-item ${conversationFilters.filters.conversationStateID == 3 ? "selected" : ""}`}
                        onClick={() => changeConversationTab(3)}
                    >
                        {translate.t("Deffered")}
                    </div>
                    <div
                        className={`menu-item ${conversationFilters.filters.conversationStateID == 4 ? "selected" : ""}`}
                        onClick={() => changeConversationTab(4)}
                    >
                        {translate.t("Solved")}
                    </div>
                    {false && <div className={`menu-item`}>{translate.t("TeamFilter")}</div>}
                </div>
                <div className={`filters-wrapper ${addFilterDropdownOpened ? "dropdown-opened" : ""}`}>
                    <Scrollbars
                        style={{ overflow: disableFilterScrollbar.current ? "visible" : "hidden" }}
                        renderTrackVertical={(props) => <div {...props} style={{ display: "none" }} />}
                        renderView={(props) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    overflowY: disableFilterScrollbar.current ? "visible" : "hidden",
                                    overflowX: disableFilterScrollbar.current ? "visible" : "scroll",
                                    marginRight: "0px",
                                }}
                            />
                        )}
                    >
                        <div className="filters">
                            <Dropdown
                                className="filled small rounded add-filter"
                                content={<i className="las la-plus" style={{ fontSize: "1.4rem" }}></i>}
                                data={filterList}
                                contentSelector={(item) => (
                                    <div className="add-filter-item">
                                        <i className={`icon las ${item.icon}`}></i>
                                        {item.title}
                                    </div>
                                )}
                                onSelected={(item) => addFilter(item.type)}
                                showRemove={false}
                                showDropdown={false}
                                contentWidth="28rem"
                                arrowLeft="1.9rem"
                                onOpenChanged={(open) => setAddFilterDropdownOpened(open)}
                            />
                            {conversationFilters.filters.operatorID != "" && (
                                <Dropdown
                                    className="filled small rounded"
                                    content={translate.t("ShowOnlyMy")}
                                    contentSelector={(item) => (
                                        <div className="add-filter-item">
                                            <i className={`icon las ${item.icon}`}></i>
                                            {item.title}
                                        </div>
                                    )}
                                    showRemove={true}
                                    showDropdown={true}
                                    onCleared={() => removeFilter("show-only-my")}
                                />
                            )}
                            <Dropdown
                                className="filled small rounded"
                                content={orderByList.filter((item) => item.value == conversationFilters.order.by)[0].title}
                                data={orderByList}
                                contentSelector={(item) => item.title}
                                onSelected={(item) => conversationFiltersCache.setOrderBy({ by: item.value, direction: "ASC" })}
                                showRemove={conversationFilters.order.by != "default"}
                                showDropdown={true}
                                onCleared={() => conversationFiltersCache.setOrderBy({ by: "default", direction: "ASC" })}
                                contentWidth="22rem"
                                onOpenChanged={(open) => setAddFilterDropdownOpened(open)}
                            />
                            <div className="reset" onClick={() => conversationFiltersCache.setDefaultFilters()}>
                                {translate.t("ResetFilters")}
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
            <div className="conversation-columns">
                <div className={`left-column ${dashboardData.mobileMenuTabSelected == 1 ? "show-mobile" : ""}`}>
                    <ConversationLists />
                </div>
                <Switch>
                    <Route exact path={path}>
                        <div className="no-conversation-selected">
                            <div className="info">
                                <i className="las la-hand-pointer icon"></i>
                                {translate.t("SelectConversationToStartChatting")}
                            </div>
                        </div>
                    </Route>
                    <Route path={`${path}/:conversationID`}>
                        <Conversation />
                    </Route>
                </Switch>
            </div>
            <div className="mobile-menu">
                <div
                    className={`item ${dashboardData.mobileMenuTabSelected == 1 ? "selected" : ""}`}
                    onClick={() => dashboardCache.set({ mobileMenuTabSelected: 1 })}
                >
                    <i className="las la-comments icon"></i>
                    {unreadConversationSum > 0 && <span className="badge">{unreadConversationSum}</span>}
                    <div className="text">{translate.t("Conversations")}</div>
                </div>
                {!!conversation.conversationID && (
                    <>
                        <div
                            className={`item ${dashboardData.mobileMenuTabSelected == 2 ? "selected" : ""}`}
                            onClick={() => dashboardCache.set({ mobileMenuTabSelected: 2 })}
                        >
                            <i className="las la-user icon"></i>
                            <div className="text">{!!conversation.visitor.name ? conversation.visitor.name : conversation.visitor.visitorID}</div>
                        </div>
                        <div
                            className={`item ${dashboardData.mobileMenuTabSelected == 3 ? "selected" : ""}`}
                            onClick={() => dashboardCache.set({ mobileMenuTabSelected: 3 })}
                        >
                            <i className="las la-info-circle icon"></i>
                            <div className="text">{translate.t("Information")}</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ConversationsPage;
