import React,{ Component } from 'react';
import { OPERATOR_IMAGE_URL } from '../Config/urls';

const EmoteIconPicker = (props) => {

    const emoteIconPicked = (icon) => {
      !!props.onPick && props.onPick(icon);
    }
    return (
      <div className='emote-icon-picker'>
        <div className='emote-icon' onClick={() => emoteIconPicked("😀")}>😀</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😃")}>😃</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😄")}>😄</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😁")}>😁</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😆")}>😆</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😅")}>😅</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😂")}>😂</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤣")}>🤣</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😊")}>😊</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😇")}>😇</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🙂")}>🙂</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🙃")}>🙃</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😉")}>😉</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😌")}>😌</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😍")}>😍</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥰")}>🥰</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😘")}>😘</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😗")}>😗</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😙")}>😙</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😚")}>😚</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😋")}>😋</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😛")}>😛</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😝")}>😝</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😜")}>😜</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤪")}>🤪</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤨")}>🤨</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🧐")}>🧐</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤓")}>🤓</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😎")}>😎</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤩")}>🤩</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥳")}>🥳</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😏")}>😏</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😒")}>😒</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😞")}>😞</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😔")}>😔</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😟")}>😟</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😕")}>😕</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🙁")}>🙁</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("☹️")}>☹️</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😣")}>😣</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😖")}>😖</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😫")}>😫</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😩")}>😩</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥺")}>🥺</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😢")}>😢</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😭")}>😭</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😤")}>😤</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😠")}>😠</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😡")}>😡</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤬")}>🤬</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤯")}>🤯</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😳")}>😳</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥵")}>🥵</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥶")}>🥶</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😱")}>😱</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😨")}>😨</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😰")}>😰</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😥")}>😥</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😓")}>😓</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤗")}>🤗</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤔")}>🤔</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤭")}>🤭</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤫")}>🤫</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤥")}>🤥</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😶")}>😶</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😐")}>😐</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😑")}>😑</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😬")}>😬</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🙄")}>🙄</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😯")}>😯</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😦")}>😦</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😧")}>😧</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😮")}>😮</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😲")}>😲</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥱")}>🥱</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😴")}>😴</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤤")}>🤤</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😪")}>😪</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😵")}>😵</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤐")}>🤐</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🥴")}>🥴</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤢")}>🤢</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤮")}>🤮</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤧")}>🤧</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😷")}>😷</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤒")}>🤒</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤕")}>🤕</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤑")}>🤑</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤠")}>🤠</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😈")}>😈</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("👿")}>👿</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("👹")}>👹</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("👺")}>👺</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤡")}>🤡</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("💩")}>💩</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("👻")}>👻</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("💀")}>💀</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("☠️")}>☠️</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("👽")}>👽</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("👾")}>👾</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🤖")}>🤖</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🎃")}>🎃</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😺")}>😺</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😸")}>😸</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😹")}>😹</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😻")}>😻</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😼")}>😼</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😽")}>😽</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("🙀")}>🙀</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😿")}>😿</div>
        <div className='emote-icon' onClick={() => emoteIconPicked("😾")}>😾</div>
      </div>
    );
  
}


export default EmoteIconPicker;
