import { getWebSocket } from "../websocket";
import { EVENTS_CONVERSATION } from "../Library/conversationEvents";

export default {
    startConversation: (conversationID, operatorID) => {
        return new Promise(async (resolve, reject) => {
            try {
                const socket = getWebSocket().socket;

                let data = {
                    conversationID,
                    operatorID,
                    visitorID: null,
                };
                socket.emit(EVENTS_CONVERSATION.CONVERSATION_STARTED, data, (data) => {
                    console.log(data);
                });

                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    },
    closeConversation: (conversationID, operatorID) => {
        return new Promise(async (resolve, reject) => {
            try {
                const socket = getWebSocket().socket;

                let data = {
                    conversationID,
                    operatorID,
                    visitorID: null,
                };
                socket.emit(EVENTS_CONVERSATION.CONVERSATION_CLOSED, data, (data) => {
                    console.log(data);
                });

                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    },
    pauseConversation: (conversationID, operatorID) => {
        return new Promise(async (resolve, reject) => {
            try {
                const socket = getWebSocket().socket;

                let data = {
                    conversationID,
                    operatorID,
                    visitorID: null,
                };
                socket.emit(EVENTS_CONVERSATION.CONVERSATION_PAUSED, data, (data) => {
                    console.log(data);
                });

                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    },
    moveOldConversationsToPostponed: (conversationIDs) => {
        return new Promise(async (resolve, reject) => {
            try {
                const socket = getWebSocket().socket;

                let data = { conversationIDs };
                socket.emit(EVENTS_CONVERSATION.CONVERSATION_MOVE_TO_POSTPONED, data, (data) => {
                    console.log(data);
                });
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    },
};
