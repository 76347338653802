import { conversationCache } from '../../../Library/Cache/conversationCache';
import { conversationListCache } from '../../../Library/Cache/conversationListCache';
import { operatorCache } from '../../../Library/Cache/operatorCache';
import { isSoundMessage, setConversationMessagesRead, setsUnreadMessageCount } from '../../../Library/dashboard';
import { playSound } from '../../../Library/helper';
import { EVENTS_MESSAGE } from '../Library/messageEvents';

  const registerMessageHandlers = (socket) => {

      const created = (data) => {   

          const myOperatorID = operatorCache.get().operatorID;

          //if we have opened the same conversation to which the message belongs 
          if(conversationCache.get().conversationID == data.messageData.conversationID){ 

            conversationCache.addMessageItem(data.messageData);    

            //if we are part of the conversation an message is not from us
            if(conversationCache.operatorExists(myOperatorID) && data.messageData.operatorID != myOperatorID && !document.hidden){
              setConversationMessagesRead(data.messageData.conversationID);      
            }

          //if we are part of the conversation or conversation has no operator
          }else if(conversationListCache.operatorExist(data.messageData.conversationID,myOperatorID) || conversationListCache.getOperators(data.messageData.conversationID).length == 0){          
            setsUnreadMessageCount(data.messageData);
          }

          //play sound if we are part of the conversation or conversation has no operator
          if(conversationListCache.operatorExist(data.messageData.conversationID,myOperatorID) || conversationListCache.getOperators(data.messageData.conversationID).length == 0){
            if(isSoundMessage(data.messageData)){
              if(conversationCache.get().conversationID == data.messageData.conversationID && !document.hidden){ 
                playSound("incoming-message-visible",0.5);
              }else{
                playSound("incoming-message");
              }
            }
          }
        
      }
    
  
      /**
       * Function called when somebody read message(s)
       * @param {Object} data - emmited data 
       */
      const messagesRead = (data) => {  
        if(conversationCache.get().conversationID == data.conversationID){ 
          console.log("addding")
          conversationCache.addReadByItems(data.allReadMessages);
        }        
      } 

      /**
       * Function called when somebody read message(s)
       * @param {Object} data - emmited data 
       */
       const messageUpdated = (data) => {  
        if(conversationCache.get().conversationID == data.messageData.conversationID){ 
          conversationCache.editMessageItem(data.messageData.messageID,data.messageData);    
        }                 
      } 

      socket.on(EVENTS_MESSAGE.CREATED, created);
      socket.on(EVENTS_MESSAGE.MESSAGES_READ, messagesRead);
      socket.on(EVENTS_MESSAGE.MESSAGE_UPDATED, messageUpdated);
  }

export default registerMessageHandlers;