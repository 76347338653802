import { makeVar } from '@apollo/client';
import { GET_CONVERSATIONS } from '../../Modules/Conversation/Queries/conversation';
import { getApolloClient } from '../apolloClient';
import { conversationFiltersCache } from './conversationFiltersCache';
import { operatorCache } from './operatorCache';
  
export const conversationSearchListVar = makeVar({
    conversations:[],
    offset:0,
    limit:10
});

export const conversationSearchListCache = {
    loadConversations: () => {        
        return new Promise(async (resolve,rejected) => {
            try{
                const client        = getApolloClient();
                const operatorData  = operatorCache.get();
                const filters       = conversationFiltersCache.getFilters();
                const order         = conversationFiltersCache.getOrderBy();
                
                const data          = await client.query({
                    query: GET_CONVERSATIONS,
                    errorPolicy:"all",
                    fetchPolicy:"network-only",
                    notifyOnNetworkStatusChange: true,
                    variables:{
                        accountID:operatorData.accountID,
                        filters,               
                        offset:0,
                        limit:conversationSearchListVar().limit,
                        operatorID:operatorData.operatorID,
                        order
                    }
                });       
                conversationSearchListCache.setConversations(JSON.parse(JSON.stringify(data.data.conversations)));
                conversationSearchListCache.setOffset(0);

                resolve(data);

			} catch (error) {
				return rejected(error);
			}			
		});
    },
    fetchMore: () => {        
        return new Promise(async (resolve,rejected) => {
            try{
                const client        = getApolloClient();
                const operatorData  = operatorCache.get();
                const filters       = conversationFiltersCache.getFilters();
                const data          = await client.query({
                    query: GET_CONVERSATIONS,
                    errorPolicy:"all",
                    fetchPolicy:"network-only",
                    notifyOnNetworkStatusChange: true,
                    variables:{
                        accountID:operatorData.accountID,
                        filters,               
                        offset:conversationSearchListVar().offset + conversationSearchListVar().limit,
                        limit:conversationSearchListVar().limit,
                        operatorID:operatorData.operatorID
                    }
                });       
                conversationSearchListCache.setConversations([...conversationSearchListCache.get(),...JSON.parse(JSON.stringify(data.data.conversations))]);
                conversationSearchListCache.setOffset(conversationSearchListVar().offset + conversationSearchListVar().limit);

                resolve(data);

			} catch (error) {
				return rejected(error);
			}			
		});
    },
    get: () => {
        return conversationSearchListVar().conversations;
    },
    getItem: (conversationID) => {        
        const allItems  = conversationSearchListVar().conversations;

        for(let item of allItems){
            if(item.conversationID == conversationID){
                return item;
            }
        }
        return null;
    },
    setConversations: (data) => {
        let newData = [];
        for(const item of data){
            newData.push({
                conversationID:item.conversationID,
                visitorID:item.visitorID,
                isInternalConversation:item.isInternalConversation,
                conversationStateID:item.conversationStateID,
                visitor:{
                    name:item.visitor.name,
                    shortcut:item.visitor.shortcut,
                    status:item.visitor.status,
                    color:item.visitor.color,
                },                
                operators:item.operators,
                unreadMessageCount:item.unreadMessageCount ? item.unreadMessageCount : 0,
                lastMessage:item.lastMessage,
            })
        }
        conversationSearchListVar({...conversationSearchListVar(), conversations:newData});
    },
    setOffset: (offset) => {        
        conversationSearchListVar({...conversationSearchListVar(), offset});
    },
}