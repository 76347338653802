/**
 * soubor se seznamem veškerých graphql dotazů pro login
 */
import gql from 'graphql-tag';



export const LOGIN_OPERATOR = gql`
  mutation operatorLogin($email: String!,$password: String!) {
    operatorLogin(email: $email,password: $password){
        loginToken
        operator{
            operatorID
            name
            surname
            email 
            img
            shortcut
            nameInChatbox
            color
            initialStatus
            accountList{
              accountID
              status
              name
              surname
              company
              roleID
              lastSelected
            }
        }
    }
  } 
`;



