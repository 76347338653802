import React from 'react';
import { checkAdminRights } from '../../../Library/helper';
import translate from '../../Translations/index';
import { operatorVar } from '../../../Library/Cache/operatorCache';
import VisitorsActive from './VisitorsActive';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import VisitorsInactive from './VisitorsInactive';
import { useReactiveVar } from '@apollo/client';
import { visitorListVar } from '../../../Library/Cache/visitorListCache';


const Visitors = (props) => {
    
    let { path, url }          = useRouteMatch();
	const operatorData         = operatorVar();
    checkAdminRights(operatorData,["1","2","3"],props);

    const allVisitors          = useReactiveVar(visitorListVar);

    return(
        <div className='page'>
            <div className='people-title'>
                <h1>{translate.t("Visitors")}</h1>
                <div className='visitor-list-count'>{allVisitors?.length} {translate.t("online")}</div>
            </div>
            <div className='menu'>
                <NavLink to={`${url}/visitors-active`} activeClassName="selected" className="menu-item">
                    {translate.t("LinkTitleActive")}
                </NavLink>
                <NavLink to={`${url}/visitors-inactive`} activeClassName="selected" className="menu-item">
                    {translate.t("LinkTitleInactive")}
                </NavLink>
            </div>               
            <Switch>                
                <Route exact path={`${path}`}>
                    <Redirect to={`${url}/visitors-active`} />
                </Route>				
                <Route path={`${url}/visitors-active`}><VisitorsActive /></Route>
                <Route path={`${url}/visitors-inactive`}><VisitorsInactive /></Route>
            </Switch> 
        </div>
    );

}

export default Visitors;