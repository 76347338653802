import React,{ useState } from 'react';
import { useMutation, useQuery} from '@apollo/client';
import translate from '../../../Translations/index';
import Modal from 'react-modal';
import { ADD_EDIT_BLOCKED_IP, GET_BLOCKED_IP } from '../../Queries/settings';
import { addNotification, validateIPaddress } from '../../../../Library/helper';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import Loading from '../../../../GlobalComponents/Loading';
import {ReactComponent as Spinner} from '../../../../Public/Images/spinner.svg';


const AddIp = (props) => {
    const operatorData = operatorCache.get();

    const [formData,setFormData] = useState({   
        blockedIpID:props.blockedIpID,
        accountID: operatorData.accountID,       
        ip:"",
        lang:translate.locale
    });
    
    const {loading} = useQuery(GET_BLOCKED_IP,{
        fetchPolicy:"network-only",
        variables:{
            blockedIpID:props.blockedIpID
        },
        onCompleted(data){   
            if(!!data.blockedIp){                
                setFormData({...formData,
                    ip: data.blockedIp.ip
                });
            }  
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });

    const [addIp,{loading:saveLoading}] = useMutation(ADD_EDIT_BLOCKED_IP,{
        onCompleted(data){            
            setFormData({   
                accountID: operatorData.accountID,       
                ip:"",
                lang:translate.locale
            });
			 addNotification("success",translate.t("IpAddressSuccessfullySaved"),translate.t("Success"));
            !!props.callback && props.callback(data.addEditBlockedIp);
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const addOrder = () => {
        if(formData.ip != ""){
            if(validateIPaddress(formData.ip)){
                addIp({
                    variables:{
                        addEditIpData:formData
                    }
                })
            }else{
                addNotification("danger",translate.t("WrongIpAddressFormat"),translate.t("Warning"));
            }            
        }else{
			addNotification("danger",translate.t("YouHaveNotFilledIpAddress"),translate.t("Warning"));
        }
        
    }

    
    
    return(

        <Modal
            className="modal center md"
            closeTimeoutMS={10}
            isOpen={true}
            onRequestClose={() => props.callback(false)}
	    >
                <div className="modal-content">
                    <button type="button" className="close" onClick={() => props.callback(false)}>
                        <i className="las la-times icon"></i>
                    </button>
                <div className="modal-header">
                    <h4 className="modal-title">{!!formData.blockedIpID ? translate.t("EditIpAddress") : translate.t("AddIpAddress")}</h4>
                </div>
                <div className="modal-body">
                    {!loading ?
                        <div className='form-group'>
                            <label>{translate.t("IP")}</label>
                            <div className='input-group'>
                                <input type="text" value={formData.ip} onChange={(e) => setFormData({...formData,ip:e.target.value})} placeholder="235.150.12.45" />
                            </div>
                        </div>
                        :
                        <Loading displayText={false} />
                    }
                </div>
                    <div className="modal-footer"> 
                        <button type="button" className="btn blue" onClick={() => addOrder()}>{!saveLoading ? (!!formData.blockedIpID ? translate.t("Edit") :  translate.t("Add")) : <Spinner />}</button>
                        <div className='link ml-auto' onClick={() => props.callback(false)}>{translate.t("Cancel")}</div>                            
                    </div>
                </div>
	    </Modal>
    )

}

export default AddIp;