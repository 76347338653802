/**
 * soubor se seznamem veškerých graphql dotazů pro login
 */
import gql from 'graphql-tag';



export const REGISTER_CUSTOMER = gql`
  mutation registerOperator($customerRegistrationData: CustomerRegistrationData,$lang: String) {    
    registerOperator(customerRegistrationData:$customerRegistrationData,lang:$lang){
      loginToken
      customer{
        operatorID
        name
        surname
        email      
        img
        shortcut
        color          
      }
    }
  } 
`;



