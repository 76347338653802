/*
    Formulář pro přihlášení se do adminu
*/
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { LOGIN_OPERATOR } from "../Queries/login";
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation } from "@apollo/client";
import { addNotification, goToInitialPage } from "../../../Library/helper";
import useOnClickOutside from "use-onclickoutside";
import translate from "../../Translations/index";
import loadingIcon from "../../../Public/Images/loading_blue.svg";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import { operatorCache, operatorVar } from "../../../Library/Cache/operatorCache";
import { ReactComponent as Logo } from "../../../Public/Images/logo.svg";
import { OPERATOR_CONTEXT } from "../../../GlobalQueries/globalQueries";
import { getApolloClient } from "../../../Library/apolloClient";

const Login = (props) => {
    const client = getApolloClient();

    const operatorData = operatorVar();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("online");

    const statusSelectionRef = useRef();
    const [statusSelectionOpen, setStatusSelectionOpen] = useState(false);
    useOnClickOutside(statusSelectionRef, () => setStatusSelectionOpen(false));

    useEffect(() => {
        const oData = client.readQuery({ query: OPERATOR_CONTEXT });
        if (!!oData.operatorContext) {
            goToInitialPage(props.history);
        }
    }, []);

    const [login, { data, loading: loginLoading, error: loginError }] = useMutation(LOGIN_OPERATOR, {
        onCompleted(data) {
            if (!data.operatorLogin.operator) {
                addNotification("danger", translate.t("WrongCredentials"), translate.t("Warning"));
            } else {
                async function doAsync() {
                    localStorage.setItem("token", data.operatorLogin.loginToken);

                    operatorCache.set({ ...data.operatorLogin.operator, status: data.operatorLogin.operator.initialStatus });

                    operatorCache.set({ status });
                    client.writeQuery({
                        query: OPERATOR_CONTEXT,
                        data: {
                            operatorContext: {
                                operatorID: data.operatorLogin.operator.operatorID,
                                name: data.operatorLogin.operator.name,
                                surname: data.operatorLogin.operator.surname,
                                nameInChatbox: data.operatorLogin.operator.nameInChatbox,
                                email: data.operatorLogin.operator.email,
                                img: data.operatorLogin.operator.img,
                                shortcut: data.operatorLogin.operator.shortcut,
                                color: data.operatorLogin.operator.color,
                                initialStatus: data.operatorLogin.operator.initialStatus,
                                __typename: "Operator",
                            },
                        },
                    });

                    goToInitialPage(props.history);
                }
                doAsync();
            }
        },
        onError(err) {
            console.log(err);

            addNotification("danger", err.toString(), translate.t("Error"));
        },
    });

    const checkLogin = () => {
        if (password !== "" && email !== "") {
            login({ variables: { email, password } });
        } else {
            addNotification("danger", translate.t("NotFilledEmailOrPwd"), translate.t("Warning"));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            checkLogin();
        }
    };

    return (
        <main className="page login-page">
            <h1>
                <span className="blue">{translate.t("SignInTitleBlue")}</span> {translate.t("SignInTitleBlack")}
            </h1>
            <div className="panel login-panel">
                <Logo className="logo" />
                <div className="form-group">
                    <label>{translate.t("Email")}</label>
                    <div className="input-group">
                        <input type="email" value={email} onKeyPress={handleKeyPress} onChange={(event) => setEmail(event.target.value)} />
                    </div>
                </div>
                <div className="form-group">
                    <label>{translate.t("Password")}</label>
                    <div className="input-group">
                        <input type="password" value={password} onKeyPress={handleKeyPress} onChange={(event) => setPassword(event.target.value)} />
                    </div>
                </div>
                <div className="form-group">
                    <label>{translate.t("Status")}</label>
                    <div className="dropdown account filled" onClick={() => setStatusSelectionOpen(!statusSelectionOpen)} ref={statusSelectionRef}>
                        <div className="text">{status}</div>
                        <div className={"dropdown-content " + (!!statusSelectionOpen ? "show" : "")}>
                            <div className="dropdown-item" onClick={() => setStatus("online")}>
                                <div className="text">online</div>
                            </div>
                            <div className="dropdown-item" onClick={() => setStatus("paused")}>
                                <div className="text">paused</div>
                            </div>
                            <div className="dropdown-item" onClick={() => setStatus("offline")}>
                                <div className="text">offline</div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" disabled={loginLoading} className="btn blue" onClick={() => checkLogin()}>
                    {translate.t("SignInText")}
                    {loginLoading && <img src={loadingIcon} className="loading" />}
                </button>
                <div className="footer">
                    <Link to={"/reset-password"} className="item">
                        {translate.t("ForgotYourPwd")}
                    </Link>
                    <Link to={"/registration"} className="item right">
                        {translate.t("NewAccount")}
                    </Link>
                </div>
            </div>

            <ReactNotifications />
        </main>
    );
};

export default Login;
