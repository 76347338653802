import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Loading from '../../../../GlobalComponents/Loading';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import { addNotification } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { DELETE_BLOCKED_IP, GET_BLOCKED_IPS } from '../../Queries/settings';
import AddIp from './AddIp';


const Security = (props) => {

	const operatorData                  = operatorCache.get();

    const [showAdd,setShowAdd]          = useState(false);
    const [editID,setEditID]            = useState(null);
    const [deleteInfo,setdDeleteInfo]   = useState({
        show:false,
        ip:"",
        deleteID:null
    });
    
    
    const { data,loading, refetch } = useQuery(GET_BLOCKED_IPS,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            accountID:operatorData.accountID
        },
        onCompleted(data){   
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });
    
    const [deleteIpAddress] = useMutation(DELETE_BLOCKED_IP,{
        onCompleted(data){			
            refetch();
            addNotification("success",translate.t("IpSuccessfullyDeleted"),translate.t("SuccessfullyDeleted"));

        },onError(err){            
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const initiateDelete = (e,deleteID,ip) => {         
        e.stopPropagation();
        setdDeleteInfo({show:true,deleteID,ip});
    }

    const deleteConfirmation = (status) => {
        if(status){
            deleteIpAddress({variables:{blockedIpID:deleteInfo.deleteID}});
        }
        setdDeleteInfo({...deleteInfo,show:false});
    }
    const ipAdded = (addedIp) => {
        if(!!addedIp){
            refetch();
        }
        setShowAdd(false);
    }

    const showAddEdit = (editID) => {
        setEditID(editID);
        setShowAdd(true);
    }
    
    return(
        <>
        
            {!loading ?
                <>
                    <div className='account security'>
                        {data?.blockedIps?.length > 0 ?
                            <>
                                <div className='add-blockedIP-ip-info'>{translate.t("AddBlockedIPInfoText")}</div>
                                <table className='full-width hoverable'>
                                    <thead>
                                        <tr>
                                            <th className='left ip'>{translate.t("IpAddress")}</th>
                                            <th className='left'>{translate.t("Created")}</th>
                                            <th className='right'>{translate.t("BlockedCount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.blockedIps?.map((item,index) => (
                                            <tr key={index} className="cursor-pointer" onClick={() => showAddEdit(item.blockedIpID)}>
                                                <td className='left'>
                                                    <div className='controlls'>
                                                        {item.ip}
                                                        <div className='hidden-content'>
                                                            <i className="las la-pencil-alt icon"></i>
                                                            <i onClick={(e) => initiateDelete(e,item.blockedIpID,item.ip)} className="las la-trash-alt icon"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='left'>{moment.utc(item.dateAdd).local().format("D.M.YYYY")}</td>
                                                <td className='right'>{item.blockedCount}x</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='list-controls'>
                                    <button className='btn blue' onClick={() => showAddEdit(null)} >{translate.t("InsertBlockedIp")}</button>
                                </div>
                            </>
                            :
                            <div className='no-chatbox'>
                                <i className="las la-globe-europe icon"></i>
                                <div className='title'>{translate.t('ThereIsNoBlockedIpYet')}</div>
                                <button className='btn blue' onClick={() => showAddEdit(null)}>{translate.t("InsertBlockedIp")}</button>
                                <div className='info'>{translate.t('InsertBlockedIpAddressInfo')}</div>
                                
                            </div>
                        }     
                    </div>
                    {showAdd &&<AddIp callback={(addedIp) => ipAdded(addedIp)} blockedIpID={editID} />}
                    {deleteInfo.show && <ModalNotification yesNo={true} title={translate.t("DeleteIpAddress")} content={translate.t('AreYouSureYouWantToDeleteIpAddress',{ip:deleteInfo.ip})} rejectBtnAsLink={true} confirmBtnText={translate.t("Delete")} rejectBtnText={translate.t("CancelAction")} callback={(status) => deleteConfirmation(status)} />}


                </>
                :
                <Loading displayText={false} />                
            }
        </>
    );

}

export default Security;