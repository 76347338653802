import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { addNotification } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { EDIT_ACCOUNT_DATA, GET_ACCOUNT_GENERAL_DATA } from '../../Queries/settings';
import {ReactComponent as Spinner} from '../../../../Public/Images/spinner.svg';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import Loading from '../../../../GlobalComponents/Loading';
import moment from 'moment';


const General = (props) => {
    
    const [maxCharCounts,setMaxCharCounts]    = useState({
        introductionText:120,
    })
    const [formData,setFormData]    = useState({
        timezone:"",
    })

    const { data,loading, refetch } = useQuery(GET_ACCOUNT_GENERAL_DATA,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            accountID: operatorCache.get().accountID
        },
        onCompleted(data){   
            if(!!data.account){        
                setFormData({...formData,
                    timezone: data.account.timezone
                });
            }  
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });

    const [saveAccount,{loading:saveLoading}] = useMutation(EDIT_ACCOUNT_DATA,{
        onCompleted(data){            
			addNotification("success",translate.t("SuccessfullySaved"),translate.t("Success"));
        },onError(err){
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const saveData = () => {
        
        saveAccount({
            variables:{
                editAccountData:{
                    ...formData,
                    accountID: operatorCache.get().accountID
                }
            }
        })   
        
    }
         
    return(
        <div className="account general">
            {!loading ? 
                <>
                    <div className='content'> 
                        
                        <div className='form-group'>
                            <label>{translate.t("TimeZone")}</label>
                            <div className='input-group'>
                                <select className="form-select language" value={formData.timezone} onChange={(e) => setFormData({...formData,timezone:e.target.value})}>
                                    <option value="">{translate.t("SelectTimezone")}</option>
                                    {data?.timezones?.map((item,index) => (
                                        <option key={index} value={item.timezone}>UTC{item.dstOffset >= 0 ? "+" : "-"}{moment('00:00:00', 'hh:mm:ss').add(Math.abs(item.dstOffset),"hours").format("HH:mm")} {item.timezone}</option>
                                    ))}
                                </select> 
                            </div>
                        </div>  
                        <button className='btn blue' disabled={saveLoading} onClick={() => saveData()}>{!saveLoading ? translate.t("SaveChanges") : <Spinner />}</button>
                    </div>
                </>
                :
                <Loading displayText={false} marginTop="14rem" />
            }
        </div>
    );

}

export default General;