/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
  mutation changeOperatorPassword($oldPassword: String!,$newPassword: String!,$lang:String) {
    changeOperatorPassword(oldPassword: $oldPassword,newPassword: $newPassword, lang:$lang)
  } 
`;
export const RESET_PASSWORD = gql`
  mutation resetOperatorPassword($email: String!,$lang:String) {
    resetOperatorPassword(email: $email, lang:$lang)
  } 
`;

export const RESET_OPERATOR_PASSWORD_HASH_DATA = gql`
    query ResetOperatorPasswordHashData($resetPasswordHash: String) {    
        resetOperatorPasswordHashData(resetPasswordHash:$resetPasswordHash){
            operatorID
            email   
        }
    }
`;

export const CHANGE_OPERATOR_PASSWORD_BY_RESET_HASH = gql`
  mutation ChangeOperatorPasswordByResetHash($resetPasswordHash: String,$newPassword: String,$lang: String) {
    changeOperatorPasswordByResetHash(resetPasswordHash: $resetPasswordHash,newPassword: $newPassword,lang:$lang)    
  }
`;