import { getApolloClient } from '../../../Library/apolloClient';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { conversationListCache } from '../../../Library/Cache/conversationListCache';
import { dashboardCache } from '../../../Library/Cache/dashboardCache';
import { visitorListCache } from '../../../Library/Cache/visitorListCache';
import { EVENTS_CONVERSATION } from '../Library/conversationEvents';
import { EVENTS_TASK } from '../Library/taskEvents';
import { EVENTS_VISITOR } from '../Library/visitorEvents';

  const taskHandlers = (socket) => {

    const client = getApolloClient();


    /**
     * Task added
     * @param {Object} data - websocket data 
     */
     const taskAdded = (data) => {    
      const conversationData = conversationCache.get();  
      if(conversationData.conversationID == data.task.conversationID){
        conversationCache.addTask(data.task);
      }
    } 

    /**
     * Task edited
     * @param {Object} data - websocket data 
     */
     const taskEdited = (data) => {    
      const conversationData = conversationCache.get();  
      if(conversationData.conversationID == data.task.conversationID){
        conversationCache.editTask(data.task.taskID,data.task);
      }
    } 

    /**
     * Task deleted
     * @param {Object} data - websocket data 
     */
     const taskDeleted = (data) => {    
      const conversationData = conversationCache.get();  
      if(conversationData.conversationID == data.task.conversationID){
        conversationCache.removeTask(data.task.taskID);
      }
    } 
  
    /**
     * Join websocket events with functions 
     */
    socket.on(EVENTS_TASK.ADDED,taskAdded);
    socket.on(EVENTS_TASK.EDITED,taskEdited);
    socket.on(EVENTS_TASK.DELETED,taskDeleted);
    

  }


export default taskHandlers;